import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fi from '../locales/fi.json';
import sv from '../locales/sv.json';
import { TLanguage } from '../types';

const debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const resources = { fi: { translation: fi }, sv_SE: { translation: sv } };

export const locales: TLanguage[] = Object.keys(resources) as TLanguage[];

const localStorageLanguage: TLanguage = (localStorage.getItem('language') as TLanguage) || '';
const index = locales.indexOf(localStorageLanguage);

export const language: TLanguage = index > -1 ? localStorageLanguage : locales[0];
export const translateText = (jsonPath: string, interpolation?: { [key: string]: any }): string =>
  interpolation ? i18n.t(jsonPath, interpolation) : i18n.t(jsonPath);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language, //fallback language
    debug,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

document.documentElement.setAttribute('lang', language);
i18n.on('languageChanged', (lang) => {
  document.documentElement.setAttribute('lang', lang);
});

export default i18n;

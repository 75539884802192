import React, { FC, ReactElement, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import './utils/i18n';
import history from './utils/History';
import { useStores } from './hooks';
import { observer } from 'mobx-react-lite';

import Theme from './style';
import NotificationProvider from './components/Notifications/Snackbars/NotificationProvider';
import { Landing, Exam, Admin, Preview } from './pages';
import ResultsPage from './components/AdminPage/ResultsPage';
import { Typography } from '@material-ui/core';

const App: FC = observer((): ReactElement => {
  const { userStore } = useStores();

  const resultsDiv = document.getElementById('results');

  useEffect(() => {
    userStore.validateUser();
  }, []);

  const PublicRoute = ({ children, ...rest }: any) => (
    <Route
      {...rest}
      render={({ location }: any) => {
        if (userStore.isAdmin) {
          return <Redirect to={{ pathname: '/admin', state: { from: location } }} />;
        }
        return children;
      }}
    />
  );

  const PrivateRoute = ({ children, ...rest }: any) => (
    <Route
      {...rest}
      render={({ location }: any) => {
        if (userStore.isAdmin) {
          return <Redirect to={{ pathname: '/admin', state: { from: location } }} />;
        } else if (userStore.isLoggedIn) {
          return children;
        } else {
          return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
        }
      }}
    />
  );

  const Adminroute = ({ children, ...rest }: any) => {
    if (!userStore.isLoggedIn) {
      window.location.href = '/admin/auth';
      return null;
    }

    return (
      <Route
        {...rest}
        render={({ location }: any) =>
          userStore.isAdmin ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  return (
    <Theme>
      <NotificationProvider>
        <Router history={history}>
          <Switch>
            {!userStore.validated && (
              <Route>
                <Typography>Loading...</Typography>
              </Route>
            )}
            <PublicRoute exact path="/login">
              <Landing />
            </PublicRoute>
            <PrivateRoute exact path="/">
              <Exam />
            </PrivateRoute>
            <Adminroute path="/admin">
              <Admin />
            </Adminroute>
            <Adminroute path="/preview">
              <Preview />
            </Adminroute>
            <Adminroute path="/results">
              {resultsDiv ? ReactDOM.createPortal(<ResultsPage />, resultsDiv) : null}
            </Adminroute>
            <PrivateRoute>
              <Typography>No such page</Typography>
            </PrivateRoute>
          </Switch>
        </Router>
      </NotificationProvider>
    </Theme>
  );
});

export default App;


import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../hooks';
import Timer from '../Timer';

const ExamTimer: FC = observer((): ReactElement => {
  const { examStore } = useStores();
  const { days, hours, minutes, seconds, percentage } = examStore.timeRemaining;
  return <Timer days={days} hours={hours} minutes={minutes} seconds={seconds} percentage={percentage} />;
});

export const ExamTimerSmall: FC = observer((): ReactElement => {
  const { examStore } = useStores();
  const { days, hours, minutes, seconds, percentage } = examStore.timeRemaining;
  return <Timer days={days} hours={hours} minutes={minutes} seconds={seconds} percentage={percentage} isSmall={true}/>;
});

export default ExamTimer;
import React, { FC, ReactElement, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { FilledButton } from '../Buttons';
import { useStores } from '../../hooks';
import Modal from '../Modal';
import { ExamStatus } from './Status';

const InstructionsContainer = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.dark.main};
    margin: 0 auto;
    width: 60%;
	`}
`;

const TasksContainer = styled.div`
  margin: 0 4rem;
  width: 20rem;
  margin: 0 auto;
`;

const EndingContainer = styled(Grid)`
  text-align: center;
  margin: 0 auto;
`;

const ReturnDivider = styled(Divider)`
  ${({ theme }) => `
    background-color:  ${theme.palette.gray.lighter};
    margin: 0 auto;
  `}
`;

const TasksTitleContainer = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.dark.musta};
    margin-bottom: 1rem;
    font-weight: bold;
    white-space: nowrap;
  `}
`;

const TaskTitle = styled(Typography)`
  ${({ theme }) => `
		color: ${theme.palette.dark.neo};
    font-size: 0.9375rem;
    font-weight: bold;
	`}
`;
const AnswersGrid = styled(Grid)`
  padding: 1rem 0;
`;

const AnswerText = styled(Typography)`
  ${({ theme }) => `
		opacity: 0.87;
    color: ${theme.palette.gray.dark};
		font-size: 0.8125rem;
	`}
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  width: 21.875rem;
`;

const GridContainer = styled(Grid)`
  ${({ theme }) => `
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &.Mui-focusVisible {
      outline: 2px solid ${theme.palette.primary.main} !important;
    }
  `}
`;

interface AnswerProps {
  answered: boolean;
}

const Answer: FC<AnswerProps> = ({ answered = false }: AnswerProps): ReactElement => {
  const { t } = useTranslation();
  const answeredText = t('pages.exam.end.answered') as string;
  const unanswredText = t('pages.exam.end.unanswered') as string;
  return <AnswerText>{answered ? answeredText.toUpperCase() : unanswredText.toUpperCase()}</AnswerText>;
};

const ExamReturn: FC = observer(
  (): ReactElement => {
    const { examStore, userStore } = useStores();
    const { t } = useTranslation();
    const [tooEarlyModalOpen, setTooEarlyModalOpen] = useState<boolean>(false);
    const finishExamText = t('common.buttons.finish.label') as string;
    const finishExamDescription = t('common.buttons.finish.description') as string;
    const instructions1 = t('pages.exam.end.instructionsText1') as string;
    const instructions2 = t('pages.exam.end.instructionsText2') as string;
    const summaryTitle = t('pages.exam.end.taskSummaryTitle') as string;
    const taskTitle = t('pages.exam.common.title') as string;
    const modalTooEarlyTitle = t('pages.exam.common.modal.tooEarly.title') as string;
    const modalTooEarlyText = t('pages.exam.common.modal.tooEarly.text') as string;
    const modalTooEarlyButtonText = t('pages.exam.common.modal.tooEarly.button') as string;

    const endExam = () => {
      if (!userStore.isAdmin && examStore.tooEarlyToSubmit) {
        setTooEarlyModalOpen(true);
      } else {
        examStore.endExam();
      }
    };

    return (
      <Box>
        <Modal
          title={modalTooEarlyTitle}
          text={modalTooEarlyText}
          buttonText={modalTooEarlyButtonText}
          open={tooEarlyModalOpen}
          onClose={() => setTooEarlyModalOpen(false)}
        />
        <EndingContainer container direction="column" spacing={5}>
          <Grid item>
            <InstructionsContainer>
              <Typography tabIndex={0}> {instructions1}</Typography>
              <br />
              <Typography tabIndex={0}> {instructions2}</Typography>
            </InstructionsContainer>
          </Grid>
          <Grid item>
            <TasksContainer>
              <TasksTitleContainer tabIndex={0}>{summaryTitle}</TasksTitleContainer>
              <ReturnDivider />
              <AnswersGrid container spacing={1}>
                {examStore.taskStatus.map((task, i) => (
                  <GridContainer
                    container
                    item
                    direction="column"
                    alignItems="center"
                    spacing={0}
                    key={i}
                    onClick={() => examStore.setCurrentPage(task.id)}
                    onKeyDown={(event) => {
                      //event for keyboard naviagtion
                      if (event.keyCode === 13 || event.keyCode === 32) {
                        examStore.setCurrentPage(task.id);
                      }
                    }}
                    tabIndex={0}
                  >
                    <Grid direction="row" container item justify="center" alignItems="center" spacing={1}>
                      <Grid item>
                        <TaskTitle>{taskTitle + ' ' + (i + 1)}</TaskTitle>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Answer answered={task.answered} />
                    </Grid>
                  </GridContainer>
                ))}
              </AnswersGrid>
              <ReturnDivider />
            </TasksContainer>
          </Grid>
          <Grid item>
            <ButtonContainer>
              <FilledButton
                elevation={true}
                text={finishExamText}
                description={finishExamDescription}
                lightBackground={true}
                handleClick={() => endExam()}
              />
            </ButtonContainer>
          </Grid>
        </EndingContainer>
      </Box>
    );
  }
);

export default ExamReturn;

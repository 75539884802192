import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';
import { Banner } from './Banner';
import { useStores } from '../../../hooks';
import { IBanner } from '../../../stores/NotificationStore/types';
import { ReturnAltIcon } from '../../Icons';

const Body = styled.div`
  ${({ theme }) => `
		background-color:${theme.palette.light.main};
		display: flex !important;
		flex-wrap: wrap;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
	`}
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 1rem;
`;

const MainText = styled(Typography)`
  font-size: 0.955rem;
`;

interface Props {
  message: IBanner['text'];
  onClose: () => void;
}

const TimeoutBanner: FC<Props> = ({ message, onClose }: Props): ReactElement => {
  const { examStore } = useStores();
  const { t } = useTranslation();
  const timeoutText = message;
  const understoodText = t('pages.exam.common.banners.examEndsInMinutes.understoodButton') as string;
  const toReturnText = t('pages.exam.common.banners.examEndsInMinutes.toReturnButton') as string;

  const handleToReturn = () => {
    onClose();
    examStore.setReturnPage();
  };

  return (
    <Banner>
      <Body>
        <Item>
          <IconContainer>
            <ReturnAltIcon />
          </IconContainer>
          <MainText tabIndex={-1} role="alert">
            {timeoutText}
          </MainText>
        </Item>
        <Item>
          <Button onClick={() => onClose()} tabIndex={-1} color="primary">
            {understoodText}
          </Button>
          <Button onClick={() => handleToReturn()} tabIndex={-1} color="primary">
            {toReturnText}
          </Button>
        </Item>
      </Body>
    </Banner>
  );
};

export default TimeoutBanner;

import React, { FC, ReactElement, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useStores } from '../../hooks';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ExamInfoRow from './ExamInfoRow';
import { IExam, IExamLocation } from '../../types';
import { getDateString, getDuration, getTimeSpan } from '../../utils/Time';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  disabledLink: {
    color: theme.palette.action.disabled,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  examName: { marginTop: '15px', marginBottom: '30px' },
  divider: { marginTop: '15px', marginBottom: '15px' },
}));

const ExamInfo: FC = observer((): ReactElement => {
  const classes = useStyles();
  const { adminStore } = useStores();
  const { t } = useTranslation();
  const exam: IExam | undefined = adminStore.chosenExam;
  const getLocation = (location: IExamLocation): string => {
    const { name, address, postal, city } = location;
    return !name && !address && !postal && !city ? '' : `${name} (${address}, ${postal} ${city})`;
  };
  const [showDialog, setShowDialog] = useState(false);
  const examHasEnded = exam ? exam.examHasEnded : false;

  const downloadScoringCSV = () => {
    axios({
      method: 'get',
      url: `/api/admin/exams/${exam?.id}/scoring`,
      responseType: 'stream',
    }).then((response) => {
      //Weird hack to get the encoding right for the excel
      //https://github.com/eligrey/FileSaver.js/issues/28#issue-15333709
      const BOM = '\uFEFF';
      const data = BOM + response.data;
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = (exam?.name || 'scoring') + '.csv';
      link.click();
      setTimeout(() => URL.revokeObjectURL(link.href), 0);
    });
  };

  return (
    <Box>
      <Typography variant="h2" className={classes.examName}>
        {exam?.name}
      </Typography>
      <Card variant="outlined">
        <CardContent>
          <Grid container>
            <Grid item xs={7}>
              <Typography variant="h4">{exam?.location.city}</Typography>
              <Typography color="textSecondary">{exam && getDateString(exam.examStart)}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Button disabled={!examHasEnded} onClick={() => downloadScoringCSV()}>
                <GetAppIcon className={!examHasEnded ? classes.disabledLink : classes.link} />
                <Typography className={!examHasEnded ? classes.disabledLink : classes.link}>
                  {t('pages.admin.examInfo.downloadExcel')}
                </Typography>
              </Button>
            </Grid>
            <Grid container item xs={3}>
              <Button
                disabled={!exam}
                onClick={(): Window | null => window.open('/preview?survey=' + exam?.surveyId, '_blank')}
              >
                <OpenInNewIcon className={classes.link} />
                <Typography className={classes.link}>{t('pages.admin.examInfo.preview')}</Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider orientation="horizontal" className={classes.divider} />
            </Grid>
            <Grid item xs={9}>
              <Table>
                <TableBody>
                  <ExamInfoRow
                    {...{
                      label: 'pages.admin.examInfo.timeSpan',
                      value: exam ? getTimeSpan(exam.examStart, exam.examEnd) : '',
                    }}
                  />
                  <ExamInfoRow
                    {...{
                      label: 'pages.admin.examInfo.duration',
                      value: exam ? getDuration(exam.examStart, exam.examEnd) : '',
                    }}
                  />
                  <ExamInfoRow
                    {...{
                      label: 'pages.admin.examInfo.location',
                      value: exam ? getLocation(exam.location) : '',
                    }}
                  />
                  <ExamInfoRow
                    {...{
                      label: 'pages.admin.examInfo.examPassword',
                      value: exam ? exam.password : '',
                    }}
                  />
                  <ExamInfoRow
                    {...{
                      label: 'pages.admin.examInfo.examinees',
                      value: exam ? `${adminStore.amountOfExaminees}/${exam.maxRegistrations}` : '',
                    }}
                  />
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <Divider orientation="horizontal" className={classes.divider} />
            </Grid>
            <Grid container item xs={12} justify="center">
              <Button disabled={!exam} onClick={() => adminStore.fetchAmountsOfAnswers()}>
                <RefreshIcon className={classes.link} />
                <Typography className={classes.link}>{t('pages.admin.examInfo.updateAnswers')}</Typography>
              </Button>
              <Button disabled={examHasEnded} onClick={() => setShowDialog(true)}>
                <ToggleOnIcon className={examHasEnded ? classes.disabledLink : classes.link} />
                <Typography className={examHasEnded ? classes.disabledLink : classes.link}>
                  {t('pages.admin.examInfo.activateAll')}
                </Typography>
              </Button>
              {showDialog && (
                <Dialog open={showDialog} onClose={(): void => setShowDialog(false)}>
                  <DialogTitle>{t('pages.admin.examInfo.activateAll')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>{t('pages.admin.examInfo.activateAllDialogText')}</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={(): void => setShowDialog(false)} color="primary">
                      {t('pages.admin.examinee.dialog.cancel')}
                    </Button>
                    <Button
                      onClick={(): void => {
                        adminStore.activateAllExaminees();
                        setShowDialog(false);
                      }}
                      color="primary"
                    >
                      {t('pages.admin.examinee.dialog.confirm')}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
});

export default ExamInfo;

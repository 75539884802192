import React, { FC, ReactElement, MouseEvent, useState } from 'react';
import styled from 'styled-components/macro';

import uniqueId from 'lodash.uniqueid';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import { AccountIcon, ArrowDownIcon, LogoutDarkIcon } from '../../Icons';
import { useStores } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

const Wrapper = styled(Paper)`
  ${({ theme }) => `
		padding: 0.5rem 1rem;
		color: ${theme.palette.dark.main};
	`}
`;

const MenuRow = styled(MenuItem)`
  ${({ theme }) => `
    &.Mui-focusVisible  {
      outline: 2px solid ${theme.palette.primary.main};
    }
  `}
`;

const MenuText = styled(Typography)`
  ${({ theme }) => `
		margin-right: 1rem;
		color: ${theme.palette.dark.main};
	`}
`;

const AccountButton = styled(IconButton)`
  ${({ theme }) => `
    padding: 0;
    &.Mui-focusVisible  {
      outline: 2px solid ${theme.palette.primary.main};
    }
  `}
`;

const LogoutButton = () => {
  const { userStore } = useStores();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = uniqueId('user-menu_') as string;
  const openLogoutMenu = t('common.drawer.openLogoutMenu') as string;
  const logOutText = t('common.drawer.logoutText') as string;
  const logoutDescription = t('common.drawer.logoutDescription') as string;
  return (
    <Box>
      <AccountButton
        aria-controls={id}
        aria-haspopup="true"
        aria-label={openLogoutMenu}
        onClick={handleClick}
        tabIndex={0}
      >
        <ArrowDownIcon />
      </AccountButton>
      <Menu id={id} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuRow aria-label={logoutDescription} onClick={userStore.logout}>
          <MenuText variant="inherit" noWrap>
            {logOutText}
          </MenuText>
          <LogoutDarkIcon />
        </MenuRow>
      </Menu>
    </Box>
  );
};

const AccountDetails: FC = (): ReactElement => {
  const { userStore } = useStores();
  const { t } = useTranslation();
  const firstName = userStore.user ? userStore.user.firstName : 'N/A';
  const lastName = userStore.user ? userStore.user.lastName : 'N/A';
  const userInfoDescription = t('common.drawer.userInfoDescription', { name: `${firstName} ${lastName}` }) as string;

  return (
    <Wrapper elevation={0} aria-label={userInfoDescription} tabIndex={0}>
      <Grid container spacing={0} direction="column" alignItems="stretch">
        <Grid item xs={'auto'}>
          <AccountIcon />
        </Grid>
        <Grid item xs={'auto'}>
          <Typography noWrap>{firstName}</Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Grid container spacing={1} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item xs={'auto'}>
              <Typography noWrap>{lastName}</Typography>
            </Grid>
            <Grid item xs={'auto'}>
              <LogoutButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default AccountDetails;

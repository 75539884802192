import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import FormHelperText from '@material-ui/core/FormHelperText';

const TextLengthContainer = styled(FormHelperText)`
  ${({ theme }) => `
    color: ${theme.palette.gray.main};
    text-align: right;
    font-size: 0.8rem;
  `}
`;

interface Props {
  current: number;
  max: number;
}

const TextCount: FC<Props> = ({ current, max }: Props): ReactElement => {
  const { t } = useTranslation();
  const charactersLabel = t('pages.exam.common.characters');
  return <TextLengthContainer tabIndex={0}>{`${current} / ${max} ${charactersLabel}`}</TextLengthContainer>;
};

export default TextCount;

export const kauppakamariColors = {
  lightBlue: 'rgb(165, 201, 231)',
  royalBlue: 'rgb(0, 38, 99)',
  royalBlueGradient: 'linear-gradient(to bottom, rgb(0,25,66) 0%, rgb(0, 43, 112) 100%)',
  royalBlueLight: 'rgb(51, 82, 130)',
  vaaleanPunainen: 'rgb(242, 183, 191)',
  vaaleanPunainenLight: 'rgb(250, 229, 232)',
  vaaleanPunainenDark: 'rgba(187, 149, 239, 0.4)',
  vaaleanPunainenDisabled: 'rgb(243, 192, 199)',
  vaaleanPunainenDisabled2: 'rgb(251, 229, 232)',
  vaaleanPunainenDisabled3: 'rgb(253, 244, 246)',
  vaaleanPunainenHover: 'rgba(213, 161, 169)',
  vaaleanPunainenActive: 'rgb(194, 147, 153)',
  okra: 'rgb(106, 74, 21)',
  valkoinen: 'rgb(255, 255, 255)',
  neoValkoinen: 'rgb(251, 252, 254)',
  valkoinenDark: 'rgb(232, 226, 213)',
  valkoinenDarker: 'rgba(255, 255, 255, 0.87)',
  beigeLight: 'rgba(232, 226, 213, 0.2)',
  beigePale: 'rgba(0, 0, 0, 0.12)',
  vihreä: 'rgb(1, 145, 122)',
  liila: 'rgb(187, 149, 239)',
  punainen: 'rgb(181, 55, 23)',
  musta: 'rgba(0, 0, 0, 0.87)',
  neoMusta: 'rgb(40, 40, 40)',
  mustaLight: 'rgba(0, 0, 0, 0.74)',
  mustaLighter: 'rgba(0, 0, 0, 0.6)',
  harmaa: '  rgb(117, 117, 117)',
  harmaa2: 'rgb(129, 132, 135)',
  harmaa3: 'rgb(131, 132, 134)',
  harmaa4: 'rgb(103, 104, 105)',
  harmaaLight: 'rgba(102, 102, 102, 0.6)',
  harmaaLighter: 'rgb(166, 168, 170)',
  harmaaLighter2: 'rgb(205, 205, 205)',
  harmaaLighter3: 'rgb(218, 218, 218)',
  harmaaLightest: 'rgb(225, 225, 225)',
  tummanHarmaa: 'rgb(49, 51, 53)',
  harmaaBlue: 'rgb(162, 165, 169)',
};

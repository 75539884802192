import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useStores } from '../../hooks';
import { ExamLayout } from '../Layout';
import { BackButton } from '../Buttons';
import { withTheme } from '@material-ui/core/styles';

const ExamBackground = styled.span`
  ${({ theme }) => `
		background-color: ${theme.palette.background.primary};
		color: ${theme.palette.dark.main};
    width: inherit;
    height: inherit;
	`}
`;

interface PagePaperProps {
  hasBackgroundImage?: boolean;
}

const PagePaper = styled(Paper)<PagePaperProps>`
  ${({ hasBackgroundImage }) => `
    padding: 2rem 4rem;
    width: 100%;
    height: 100%;
    margin: 2rem auto;
    max-width: 57rem;
    ${hasBackgroundImage ? 'background-image: url("./tutkintosovellus-tavarantarkastus.png");' : ''}
    background-repeat: no-repeat;
    background-position: top -16rem right -16rem;
	`}
`;

const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
`;

interface Props {
  children?: ReactNode;
  theme?: any;
}

export const ExamContainer: FC<Props> = observer(
  ({ children, theme }: Props): ReactElement => {
    const { examStore } = useStores();
    const { t } = useTranslation();
    const toTheBeginningText = t('pages.exam.footer.navigation.buttons.back.label') as string;
    const toTheBeginningDesription = t('pages.landing.common.buttons.beginning.description') as string;
    const taskPage =
      examStore.selectedPage === examStore.returnPageId || examStore.selectedPage === examStore.instructionsPageId
        ? false
        : true;
    const matchesSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    return (
      <ExamLayout>
        <ExamBackground>
          <Container maxWidth="lg">
            <PagePaper elevation={3} square={taskPage} hasBackgroundImage={!taskPage && !matchesSmall}>
              {!taskPage && (
                <ButtonWrapper>
                  <BackButton
                    uppercase={true}
                    color={theme.palette.primary.main}
                    callback={examStore.setFirstPage}
                    text={toTheBeginningText}
                    description={toTheBeginningDesription}
                    lightBackground={true}
                  />
                </ButtonWrapper>
              )}
              {children}
            </PagePaper>
          </Container>
        </ExamBackground>
      </ExamLayout>
    );
  }
);

export default withTheme(ExamContainer);

import React, { FC, ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStores } from '../hooks';
import {
  LandingContainer,
  LoadingError,
  LandingLoading,
  LandingSuccess,
  LandingSubmit,
  LandingDisclaimer,
} from '../components/Landing';
import { ExamContainer, ExamPages } from '../components/Exam';

const Preview: FC = observer(
  (): ReactElement => {
    const { uiStore, examStore } = useStores();
    const { t } = useTranslation();
    const LoadingTitle = t('pages.landing.loading.initialText') as string;
    const LoadingSuccessText = t('pages.landing.loading.successText') as string;
    const LoadingErrorTitle = t('pages.landing.loadingError.title') as string;
    const ExamFinishTitle = t('pages.landing.finish.title') as string;
    const DisclaimerTitle = examStore.disclaimer.title;

    useEffect(() => {
      examStore.startPreview();
    }, []);

    switch (uiStore.currentState) {
    case 'loading':
      return (
        <LandingContainer heading={LoadingTitle}>
          <LandingLoading />
        </LandingContainer>
      );
    default:
    case 'loading-error':
      return (
        <LandingContainer heading={LoadingErrorTitle}>
          <LoadingError onReload={() => examStore.startPreview()} />
        </LandingContainer>
      );
    case 'loading-success':
      return (
        <LandingContainer heading={LoadingSuccessText}>
          <LandingSuccess />
        </LandingContainer>
      );
    case 'exam-info':
      return (
        <LandingContainer heading={DisclaimerTitle}>
          <LandingDisclaimer onStart={() => uiStore.setViewState('loading-success')} />
        </LandingContainer>
      );
    case 'started':
      return (
        <ExamContainer>
          <ExamPages />
        </ExamContainer>
      );
    case 'finish':
      return (
        <LandingContainer noBack={true} heading={ExamFinishTitle}>
          <LandingSubmit />
        </LandingContainer>
      );
    }
  }
);

export default Preview;

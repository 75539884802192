import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import LinearTimer from './LinearTimer';
import { TimeIconSmall } from './Icons';

const TimerWrapper = styled(Grid)`
  ${({ theme }) => `
    color: ${theme.palette.light.main};
    display: flex;
    flex-wrap: nowrap;
    & svg {
      margin-top: 0.2rem;
    }
	`}
`;

const Text = styled(Typography)`
  margin-bottom: 0.5rem;
  font-size: inherit;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

interface Props {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  percentage: number;
  isSmall?: boolean;
}
const Timer: FC<Props> = observer(
  ({ days, hours, minutes, seconds, percentage, isSmall = false }: Props): ReactElement => {
    const { t } = useTranslation();
    const daysText = `${days} ${t('pages.exam.footer.timer.daysText')} `;
    const hoursText = `${hours} ${t('pages.exam.footer.timer.hoursText')} `;
    const minutesText = `${minutes} ${t('pages.exam.footer.timer.minutesText')}`;
    const secondsText = `${seconds} ${t('pages.exam.footer.timer.secondsText')}`;
    const time = `${!isSmall ? t('pages.exam.footer.timer.timeRemainingText') + ':' : ''} ${days > 0 ? daysText : ''} ${
      days > 0 || hours > 0 ? hoursText : ''
    } ${hours > 0 || minutes > 0 ? minutesText : ''} ${secondsText}`;

    return (
      <TimerWrapper>
        {!isSmall && <TimeIconSmall />}
        <TextWrapper>
          <Text tabIndex={0}>{time}</Text>
          <LinearTimer value={percentage} />
        </TextWrapper>
      </TimerWrapper>
    );
  }
);

export default Timer;

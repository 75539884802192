import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import BottomNavigation from '@material-ui/core/BottomNavigation';

const Wrapper = styled(BottomNavigation)`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
    padding: 0.3rem 1rem;
    position: sticky;
    bottom: 0;
    overflow: hidden;
    height: auto;
    min-height: 2.89rem;
    min-width: 2 0rem;
    z-index: 1;
  `}
`;

interface Props {
  children?: ReactNode;
}

const Container: FC<Props> = ({ children }: Props): ReactElement => <Wrapper showLabels>{children}</Wrapper>;

export default Container;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Box, Typography } from '@material-ui/core';

import { useStores } from '../../hooks';
import { SuccessSmallIcon, ExamErrorSmallIcon, LoadingSmallIcon } from '../Icons';

const SavingText = styled(Typography)`
  margin-left: 0.3rem;
  font-size: inherit;
`;

const ExamSaving: FC = observer((): ReactElement => {
  const { examStore } = useStores();
  const state: string = examStore.saveState;
  const { t } = useTranslation();
  const ExamSavedText = t('pages.exam.footer.examSavedText');
  const ExamSavingtext = t('pages.exam.footer.examSaveLoadingText');
  const ExamErrorText = t('pages.exam.footer.examSaveErrorText');

  return (
    <Box>
      {state === 'saving' && (
        <Box display="flex" alignItems="center">
          <LoadingSmallIcon />
          <SavingText>{ExamSavingtext}</SavingText>
        </Box>
      )}
      {(state === 'error' || state === 'noConnection') && (
        <Box display="flex" alignItems="center">
          <ExamErrorSmallIcon />
          <SavingText>{ExamErrorText}</SavingText>
        </Box>
      )}
      {state === 'success' && (
        <Box display="flex" alignItems="center">
          <SuccessSmallIcon />
          <SavingText>{ExamSavedText}</SavingText>
        </Box>
      )}
    </Box>
  );
});

export const ExamSavingSmall: FC = observer((): ReactElement => {
  const { examStore } = useStores();
  const state: string = examStore.saveState;
  return (
    <Box>
      {state === 'saving' && <LoadingSmallIcon />}
      {(state === 'error' || state === 'noConnection') && <ExamErrorSmallIcon />}
      {state == 'success' && <SuccessSmallIcon />}
    </Box>
  );
});

export default ExamSaving;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { useStores } from '../../hooks';
import { TQuestionTypes, ICurrentPage } from '../../stores/ExamStore/Model';
import { SingleChoice, MultiChoice, MultiText, Text } from './Questions';

const Description = styled(Typography)`
  user-select: none;
  margin-bottom: 1rem;
`;

interface SelectedQuestionProps {
  page: ICurrentPage;
  heading: string;
  question: TQuestionTypes;
  position: number | null;
}

const SelectedQuestion: FC<SelectedQuestionProps> = ({ page, heading, question, position }: SelectedQuestionProps) => {
  switch (question.type) {
  case 'single-choice':
    return <SingleChoice page={page.id} question={question.id} heading={heading} choices={question.choices} />;
  case 'multi-choice':
    return <MultiChoice page={page.id} question={question.id} heading={heading} choices={question.choices} />;
  case 'multi-text':
    return (
      <MultiText
        position={position}
        page={page.id}
        question={question.id}
        heading={heading}
        rows={question.rows}
        maxLength={question.maxLength}
      />
    );
  case 'text':
    return (
      <Text
        page={page.id}
        question={question.id}
        heading={heading}
        answer={question.answer}
        maxLength={question.maxLength}
      />
    );
  default:
    return <Typography>Unidentified exam type. This is an error.</Typography>;
  }
};

const ExamPage: FC = observer(
  (): ReactElement => {
    const { examStore } = useStores();
    const page = examStore.currentPage;

    if (!page) {
      return <Typography>Loading</Typography>;
    }
    const description = page.description;
    const questions: TQuestionTypes[] = page.questions || [];

    return (
      <Grid container direction="column" alignItems="stretch" spacing={5}>
        {description && (
          <Grid item>
            <Description dangerouslySetInnerHTML={{ __html: description }} tabIndex={0} />
          </Grid>
        )}

        {questions.map((question, index: number) => {
          //only display subquestion number if there are multiple subquestions on one page
          const position = questions.length > 1 ? index + 1 : null;
          const heading = position ? `${position}. ${question.heading}` : question.heading;
          return (
            <Grid key={page.id + '-' + index} item>
              <SelectedQuestion page={page} heading={heading} question={question} position={position} />
            </Grid>
          );
        })}
      </Grid>
    );
  }
);

export default ExamPage;

import { observable, computed, action, makeObservable } from 'mobx';
import { ITimes, second } from './types';

export interface ITimer {
  milliseconds: number;
  display: ITimes;
  totalMilliSeconds: number;
  setMilliseconds: (milliseconds: number) => void;
  reset: () => void;
}

export class Timer implements ITimer {
  milliseconds: number;
  constructor(initialMilliseconds = 0) {
    this.milliseconds = initialMilliseconds;
    makeObservable(this, {
      milliseconds: observable,
      totalMilliSeconds: computed,
      display: computed,
      setMilliseconds: action.bound,
      reset: action.bound
    });
  }

  get totalMilliSeconds(): number {
    return this.milliseconds;
  }

  get display(): ITimes {
    const sec = Math.floor(this.totalMilliSeconds / second);
    const seconds = sec < 0 ? 0 : sec;
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    return {
      seconds: seconds % 60,
      minutes: minutes % 60,
      hours: hours % 24,
      days: days
    };
  }

  public reset(): void {
    this.milliseconds = 0;
  }

  public setMilliseconds(milliseconds: number): void {
    this.milliseconds = milliseconds;
  }
}
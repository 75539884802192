import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';

interface ButtonProps {
  width?: string;
}

export const OutlineButton = styled(Button)<ButtonProps>`
  ${({ theme, width }) => `
		border-color: ${theme.palette.secondary.main};
    color: ${theme.palette.secondary.main};
    width: ${width};
		&:hover {
			background-color: rgba(0, 0, 0, 0.12);
    }
    &:active {
			background-color: rgba(0, 0, 0, 0.2);
		}
    &.Mui-focusVisible {
      background-color: ${theme.palette.primary.dark};
      border-color: ${theme.palette.primary.dark};
      color: ${theme.palette.light.main};
      outline: 2px solid ${theme.palette.light.main};
    }
		&:disabled {
			color: ${theme.palette.secondary.main} !important;
			background-color: rgba(255, 255, 255, 0.12)
		}
	`}
`;
interface Props {
  handleClick: () => void;
  text: string;
  description?: string;
  disabled?: boolean;
  elevation?: boolean;
  width?: string;
}

export const OutlinedButton: FC<Props> = ({
  handleClick,
  text,
  description = '',
  disabled = false,
  elevation = false,
  width = '100%',
}: Props): ReactElement => (
  <OutlineButton
    size="large"
    aria-label={description}
    disabled={disabled}
    variant="outlined"
    color="secondary"
    width={width}
    disableElevation={!elevation}
    onClick={() => handleClick()}
  >
    {text}
  </OutlineButton>
);

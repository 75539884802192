import React, { FC, ReactElement, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Box, Button, Divider, List, ListItem, ListSubheader } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useStores } from '../../hooks';
import { IExam } from '../../types';
import ExamMenuItemText from '../AdminPage/ExamMenuItemText';
import Separator from './Separator';

const Drawer = styled(Box)`
  word-wrap: break-word;
  overflow: hidden;
`;

const SubHeader = styled(ListSubheader)`
  display: 'flex',
  alignItems: 'center',
`;

interface StyledListItemProps {
  isSelectedExam: boolean;
}
const StyledListItem = styled(ListItem)<StyledListItemProps>`
  ${({ theme, isSelectedExam }) => `
    background-color: ${isSelectedExam ? theme.palette.primary.light : 'inherit'} !important;
		color: ${isSelectedExam ? theme.palette.primary.dark : theme.palette.gray.main};
    &:focus {
      background-color: ${theme.palette.primary.lighter};
    }
	`}
`;

interface IExams {
  upcomingExams: IExam[];
  oldExams: IExam[];
  chosenExamId: string;
  chosenExamIsOld: boolean;
}
export const AdminDrawer: FC = observer((): ReactElement => {
  const { adminStore } = useStores();
  const { t } = useTranslation();
  const [showOld, setShowOld] = React.useState(false);
  const { upcomingExams, oldExams, chosenExamId, chosenExamIsOld }: IExams = adminStore;
  const UpcomingExamsTitle = t('pages.admin.examMenu.upcomingExams');
  const PastExamsTitle = t('pages.admin.examMenu.pastExams');
  useEffect(() => setShowOld(chosenExamIsOld), [chosenExamIsOld]);

  return (
    <Drawer>
      <Separator />
      <List>
        <ListSubheader>{UpcomingExamsTitle}</ListSubheader>
        {upcomingExams.map((exam) => (
          <StyledListItem
            button
            key={exam.id}
            isSelectedExam={exam.id === chosenExamId}
            onClick={(): void => adminStore.setChosenExam(exam.id)}
          >
            <ExamMenuItemText {...exam} />
          </StyledListItem>
        ))}
      </List>
      <Divider />
      <List>
        <SubHeader>
          {PastExamsTitle}
          <Button disabled={!oldExams.length} onClick={(): void => setShowOld(!showOld)}>
            {showOld ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </SubHeader>
        {showOld &&
          oldExams.map((exam) => (
            <StyledListItem
              button
              key={exam.id}
              isSelectedExam={exam.id === chosenExamId}
              onClick={(): void => adminStore.setChosenExam(exam.id)}
            >
              <ExamMenuItemText {...exam} />
            </StyledListItem>
          ))}
      </List>
    </Drawer>
  );
});

export default AdminDrawer;

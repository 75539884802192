import { makeObservable, observable, action, computed } from 'mobx';
import RootStore from '../RootStore';
import { TSnackBarVariant, TBannerVariant, ISnackbar, IBanner } from './types';
import uniqueId from 'lodash.uniqueid';

interface IMessageStore {
  banners: IBanner[];
  snackbars: ISnackbar[];
}

export class NotificationStore implements IMessageStore {
  private rootStore: RootStore;
  banners: IBanner[] = [];
  snackbars: ISnackbar[] = [];
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      banners: observable,
      snackbars: observable,
      nextBanner: computed,
      addSnackbar: action.bound,
      removeSnackbar: action.bound,
      addBanner: action.bound,
      removeBanner: action.bound,
    });
  }

  public addSnackbar(type: TSnackBarVariant, text: string): void {
    const key = uniqueId(`snackbar-${type}-`);
    this.snackbars = [...this.snackbars, { key, type, text }];
  }

  public removeSnackbar(key: string): void {
    this.snackbars = this.snackbars.filter((snackbar) => snackbar.key !== key);
  }

  public addBanner(type: TBannerVariant, text: string): void {
    const key = uniqueId(`banner-${type}-`);
    this.banners = [...this.banners, { key, type, text }];
  }

  public removeBanner(key: string): void {
    this.banners = this.banners.filter((banner) => banner.key !== key);
  }

  public get nextBanner(): IBanner {
    return this.banners[0];
  }
}

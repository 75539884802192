import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import IconButton from '@material-ui/core/IconButton';

interface ButtonProps {
  lightBackground?: boolean;
}

const NavButton = styled(IconButton)<ButtonProps>`
  ${({ theme, lightBackground }) => `
		padding: 0;
    &.Mui-focusVisible {
      outline: 2px solid ${lightBackground ? theme.palette.primary.main : theme.palette.light.main};
    }
		&:hover {
			opacity: 0.8;
		}
	`}
`;

interface Props {
  children?: ReactNode;
  handleClick: () => void;
  description?: string;
  lightBackground?: boolean;
}
export const NavigationButton: FC<Props> = ({
  children,
  handleClick,
  description,
  lightBackground,
}: Props): ReactElement => {
  return (
    <NavButton aria-label={description} lightBackground={lightBackground} onClick={() => handleClick()}>
      {children}
    </NavButton>
  );
};

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { useStores } from '../../hooks';
import { FilledButton, OutlinedButton } from '../Buttons';
import { FadeTransition } from '../Transitions';

const DetailsWrapper = styled(Grid)`
		padding 0 0 1rem 0;
    text-align: center;
    width: 100%;
`;

const LandingSubmit: FC = observer(
  (): ReactElement => {
    const { uiStore, examStore } = useStores();
    const { t } = useTranslation();
    const instructions1 = t('pages.landing.finish.instructions1Text') as string;
    const instructions2 = t('pages.landing.finish.instructions2Text') as string;
    const finishExamText = t('common.buttons.finish.label') as string;
    const finishExamDescription = t('common.buttons.finish.description') as string;
    const returnButtonText = t('pages.landing.finish.buttons.return.label') as string;
    const returnButtonDescription = t('pages.landing.finish.buttons.return.description') as string;

    return (
      <FadeTransition>
        <Grid container spacing={10} alignItems="stretch">
          <DetailsWrapper item>
            <Typography tabIndex={0}>{instructions1}</Typography>
            {examStore.examUnfinished && (
              <Box>
                <br />
                <Typography tabIndex={0}>{instructions2}</Typography>
              </Box>
            )}
          </DetailsWrapper>
          <Grid container item spacing={3} direction="column" alignItems="center">
            <Grid item xs={'auto'}>
              <FilledButton
                width="21.875rem"
                text={finishExamText}
                description={finishExamDescription}
                handleClick={examStore.submitExam}
              />
            </Grid>
            <Grid item xs={'auto'}>
              <OutlinedButton
                width="21.875rem"
                text={returnButtonText}
                description={returnButtonDescription}
                handleClick={() => uiStore.setViewState('started')}
              />
            </Grid>
          </Grid>
        </Grid>
      </FadeTransition>
    );
  }
);

export default LandingSubmit;

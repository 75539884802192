import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStores } from '../hooks';
import { LandingContainer, LandingLoading, LandingLogin } from '../components/Landing';

const Landing: FC = observer(
  (): ReactElement => {
    const { t } = useTranslation();
    const LandingText = t('pages.landing.initial.title') as string;
    const AuthenticatingText = t('pages.landing.loading.authenticatingText') as string;
    const { uiStore } = useStores();

    return (
      <LandingContainer heading={uiStore.currentState === 'authenticating' ? AuthenticatingText : LandingText}>
        {uiStore.currentState === 'authenticating' ? <LandingLoading /> : <LandingLogin />}
      </LandingContainer>
    );
  }
);

export default Landing;

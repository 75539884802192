import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Modal from '../Modal';
import { FadeTransition } from '../Transitions';
import { useStores } from '../../hooks';
import ExamInstructions from './Instructions';
import ExamReturn from './Return';
import ExamPage from './Page';
import { ExamStatus } from './Status';

const InnerPage = styled(Box)`
  ${({ theme }) => `
		color: ${theme.palette.dark.dark};
	`}
`;

interface PageTitleProps {
  component: string;
}

const PageTitle = styled(Typography)<PageTitleProps>`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 1rem;
`;
const PageTitleWithStatus = styled(Grid)`
  margin-bottom: 1rem;
`;

interface PageContainerProps {
  title?: string;
  children?: ReactNode;
}

const PageContainer: FC<PageContainerProps> = observer(({ children, title }: PageContainerProps): ReactElement => {
  const { t } = useTranslation();
  const noConnectionTitle = t('pages.exam.errors.noConnection.title') as string;
  const noConnectionText = t('pages.exam.errors.noConnection.text') as string;
  const noConnectionButton = t('pages.exam.errors.noConnection.button') as string;

  const { examStore } = useStores();
  const [modalOpen, setModalOpen] = useState<boolean>(examStore.saveState === 'noConnection');

  useEffect(() => {
    setModalOpen(examStore.saveState === 'noConnection');
  }, [examStore.saveState]);

  return (
    <FadeTransition>
      {title && (
        <Box id="examTitle" tabIndex={0}>
          {!examStore.currentPage && (
            <PageTitle variant="h5" component="h1" tabIndex={-1}>
              {title}
            </PageTitle>
          )}
          {examStore.currentPage && (
            <PageTitleWithStatus container spacing={2} alignItems="center" tabIndex={-1} wrap="nowrap">
              <Grid item>
                <Typography variant="h5" component="h1" dangerouslySetInnerHTML={{ __html: title }} />
              </Grid>
            </PageTitleWithStatus>
          )}
        </Box>
      )}
      <Modal
        title={noConnectionTitle}
        text={noConnectionText}
        buttonText={noConnectionButton}
        open={modalOpen}
        disableClose={true}
        onClose={() => setModalOpen(false)}
      />
      <InnerPage>{children}</InnerPage>
    </FadeTransition>
  );
});

const ExamPages: FC = observer((): ReactElement => {
  const { examStore } = useStores();
  const { t } = useTranslation();

  const startPageTitle = examStore.instructions.title;
  const endPageTitle = t('pages.exam.end.title') as string;

  if (examStore.selectedPage === examStore.instructionsPageId) {
    return (
      <PageContainer title={startPageTitle}>
        <ExamInstructions />
      </PageContainer>
    );
  } else if (examStore.selectedPage === examStore.returnPageId) {
    return (
      <PageContainer title={endPageTitle}>
        <ExamReturn />
      </PageContainer>
    );
  } else {
    return (
      <PageContainer title={examStore.currentPage ? examStore.currentPage.title : 'N/A'}>
        <ExamPage />
      </PageContainer>
    );
  }
});

export default ExamPages;

import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks';

import TimeoutBanner from './TimeoutBanner';

export const Banners: FC = observer((): ReactElement | null => {
  const { notificationStore } = useStores();
  const nextBanner = notificationStore.nextBanner;

  if (nextBanner) {
    //Switch if other types are added later
    switch (nextBanner.type) {
    default:
    case 'timeout':
      return (
        <TimeoutBanner
          message={nextBanner.text}
          key={nextBanner.key}
          onClose={() => notificationStore.removeBanner(nextBanner.key)}
        />
      );
    }
  } else {
    return null;
  }
});

export default Banners;

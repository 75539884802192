import axios from 'axios';
import { IExamQuery, IInstructionsData, IExamData, IResponseData } from '../types';
import { IPage, TQuestionTypes, TAnswer } from '../stores/ExamStore/Model';
import { deserializeInstruction, deserializePages } from './Deserialize';

interface IExamService {
  fetchInsturctions: (query: IExamQuery) => Promise<IInstructionsData>;
  fetchExam: (query: IExamQuery) => Promise<IExamData>;
  answer: (pageId: IPage['id'], questionId: TQuestionTypes['id'], answer: TAnswer) => Promise<void>;
  submit: () => Promise<void>;
}

class ExamService implements IExamService {
  public async fetchInsturctions(query: IExamQuery): Promise<IInstructionsData> {
    const response = await axios.get('/api/exam/info', { params: { lang: query.lang } });
    const { title } = response.data;
    const disclaimer = deserializeInstruction(response.data.disclaimer);
    const instructions = deserializeInstruction(response.data.instructions);
    return { title, disclaimer, instructions };
  }

  async fetchExam(query: IExamQuery): Promise<IExamData> {
    const response = await axios.get('/api/exam', {
      params: { lang: query.lang },
    });
    const { title, pages }: IResponseData = response.data;
    const parsedPages: IPage[] = deserializePages(pages);
    return { title, pages: parsedPages };
  }

  public async answer(pageId: IPage['id'], questionId: TQuestionTypes['id'], answer: TAnswer): Promise<void> {
    await axios.put('/api/exam/answer', { pageId, questionId, answer });
  }

  public async submit(): Promise<void> {
    await axios.post('/api/exam/submit');
  }
}

export const ExamServiceInstance = new ExamService();

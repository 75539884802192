import React, { FC, ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';

import { makeStyles, withTheme } from '@material-ui/core/styles';

interface Props {
  children?: ReactNode;
  theme?: any;
}

const NotificationProvider: FC<Props> = ({ children, theme }: Props) => {
  //Change default colors here since wasn't able to get this done from the theme
  const snackBarStyles = makeStyles(() => ({
    // variantSuccess: { backgroundColor: 'purple !important' },
    variantError: { backgroundColor: theme.palette.invalid.main + '!important', color: 'black !important' },
    //variantWarning: { backgroundColor: 'green !important' },
    variantInfo: { backgroundColor: theme.palette.dark.neo + '!important',
      minHeight:'5rem', fontSize:'1.3rem'},
  }));
  return (
    <SnackbarProvider maxSnack={1} preventDuplicate hideIconVariant classes={snackBarStyles()}>
      {children}
    </SnackbarProvider>
  );
};

export default withTheme(NotificationProvider);

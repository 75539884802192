import React, { FC, ReactElement, ReactNode } from 'react';

import styled from 'styled-components/macro';
import { Drawer as _drawer } from '@material-ui/core';

interface DrawerProps {
  width?: string | number;
}

const DrawerWrapper = styled(_drawer)<DrawerProps>`
  width: ${({ width }) => `${width}`};
  flex-shrink: 0 !important;
`;

const StyledPaper = styled.div<DrawerProps>`
  width: ${({ width }) => `${width} `};
  height: 100%;
`;

interface Props {
  variant?: 'permanent' | 'persistent' | 'temporary';
  anchor?: 'left' | 'top' | 'right' | 'bottom';
  open?: boolean;
  width?: string | number;
  children?: ReactNode; //Drawer content
}

const Drawer: FC<Props> = ({
  width = '0',
  open = false,
  variant = 'persistent',
  anchor = 'left',
  children,
}: Props): ReactElement => {
  return (
    <DrawerWrapper
      width={width}
      variant={variant}
      anchor={anchor}
      open={open}
      elevation={24}
      PaperProps={{ elevation: 5 }}
    >
      <StyledPaper width={width}>{children}</StyledPaper>
    </DrawerWrapper>
  );
};

export { default as ExamDrawer } from './Exam';
export { default as AdminDrawer } from './Admin';
export default Drawer;

import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../hooks';

import { InputField } from './components';

interface Props {
  password: string;
  setPassword: (value: string) => void;
}

const ExamPassword: FC<Props> = observer(({ password, setPassword }: Props): ReactElement => {
  const { uiStore } = useStores();
  const { t } = useTranslation();
  const error = uiStore.currentState === 'authenticating-error';

  const errorText = t('pages.landing.login.inputs.examPassword.errorText') as string;
  const description = t('pages.landing.login.inputs.examPassword.description') as string;
  const label = t('pages.landing.login.inputs.examPassword.label') as string;

  return (
    <InputField
      onChange={setPassword}
      type={'text'}
      label={label}
      description={description}
      value={password}
      error={error}
      errorText={errorText}
    />
  );
});

export default ExamPassword;
import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { ListItemText, makeStyles, Typography } from '@material-ui/core';
import { IExam } from '../../types';
import { getDateString } from '../../utils/Time';

const useStyles = makeStyles({
  bold: {
    fontWeight: 600,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

const ExamMenuItemText: FC<IExam> = observer(
  (exam: IExam): ReactElement => {
    const classes = useStyles();
    return (
      <ListItemText>
        <Typography className={classes.uppercase}>{exam.name}</Typography>
        <Typography className={classes.bold}>{getDateString(exam.examStart)}</Typography>
        <Typography className={classes.bold}>{exam.location.city}</Typography>
      </ListItemText>
    );
  }
);

export default ExamMenuItemText;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';

import { FadeTransition } from '../Transitions';
import { SuccessIcon } from '../../components/Icons';


const IconContainer = styled.div`
  text-align: center;
`;
const LandingSuccess: FC = (): ReactElement => {
  return <FadeTransition>
    <IconContainer>
      <SuccessIcon />
    </IconContainer>
  </FadeTransition>;
};

export default LandingSuccess;
import React, { FC, ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Divider, Grid } from '@material-ui/core';
import { useStores } from '../hooks';
import ExamInfo from '../components/AdminPage/ExamInfo';
import ExamineeTable from '../components/AdminPage/ExamineeTable';
import { AdminLayout } from '../components/Layout';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const BackdropContainer = styled(Backdrop)`
  z-index: 100000;
  color: '#fff';
`;

const AdminContainer = styled.div`
  height: 100vh;
  padding: 1rem 3rem;
`;

const DividerItem = styled(Grid)`
  margin: 10px 0;
`;

const Admin: FC = observer(
  (): ReactElement => {
    const { adminStore } = useStores();

    useEffect(() => {
      const init = async (): Promise<void> => {
        adminStore.init();
      };
      init();
      return (): void => adminStore.clearAdminData();
    }, []);

    useEffect(() => {
      const interval = setInterval(() => {
        adminStore.fetchExaminees();
      }, 1000);
      return (): void => clearInterval(interval);
    }, []);

    const isLoading: boolean = adminStore.isLoading;
    return (
      <React.Fragment>
        <BackdropContainer open={isLoading}>
          <CircularProgress color="inherit" />
        </BackdropContainer>
        <AdminLayout>
          <AdminContainer>
            <Grid container>
              <Grid item xs={10}>
                <ExamInfo />
              </Grid>
              <DividerItem item xs={12}>
                <Divider hidden={true} />
              </DividerItem>
              <Grid item xs={12}>
                <ExamineeTable />
              </Grid>
            </Grid>
          </AdminContainer>
        </AdminLayout>
      </React.Fragment>
    );
  }
);

export default Admin;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { useStores } from '../../hooks';
import Container from './Container';
import { LogoutLightIcon } from '../Icons';
import Language from '../Language';

const Wrapper = styled(Grid)`
  ${({ theme }) => `
    font-size: ${theme.typography.header.fontSize};
    min-width: 21rem;
	`}
`;

const HeaderButtonText = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.light.main};
    margin-left: 0.5rem;
    text-transform: none;
	`}
`;

const AdminHeader: FC = observer(
  (): ReactElement => {
    const { userStore, uiStore } = useStores();
    const { t } = useTranslation();
    const selectedLanguage = uiStore.selectedLanguage;
    const languages = uiStore.languages;
    const AdminHeaderTitle = t('pages.admin.header.title');
    const LogOutText = t('pages.admin.header.logout');

    return (
      <Container drawer={true}>
        <Wrapper container justify="space-between" alignItems={'center'} wrap={'nowrap'}>
          <Grid container item justify="flex-start">
            <Typography noWrap>{AdminHeaderTitle}</Typography>
          </Grid>
          <Grid container item spacing={1} justify="flex-end" alignItems={'center'} wrap={'nowrap'}>
            <Grid item>
              <Language languages={languages} selected={selectedLanguage} onChange={uiStore.changeLanguage} />
            </Grid>
            <Grid item>
              <Button onClick={userStore.logout}>
                <LogoutLightIcon />
                <HeaderButtonText>{LogOutText}</HeaderButtonText>
              </Button>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    );
  }
);

export default AdminHeader;

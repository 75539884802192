import { Link } from '@material-ui/core';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const SeparatorContainer = styled.div`
  height: 1.25rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.12);
`;

const SkipToContentLink = styled(Link)`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.light};
    color: ${theme.palette.primary.dark};
    display:inline-block;
    text-align: center;
    width:100%;
    height:100%;
    :not(:focus) {
      left: 100%;
      position: absolute;
      transform: translateY(-100%);
    }
  `}
`;

const Separator: FC = (): ReactElement => {
  const { t } = useTranslation();
  const skipToContentText = t('common.drawer.skipToContent') as string;
  return (
    <SeparatorContainer>
      <SkipToContentLink href="#examHeader">{skipToContentText}</SkipToContentLink>
    </SeparatorContainer>
  );
};

export default Separator;

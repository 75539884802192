import React, { ChangeEvent, FC, ReactElement, useState, useEffect, Fragment } from 'react';
import { IExaminee } from '../../types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useStores } from '../../hooks';
import { observer } from 'mobx-react-lite';
import WarningIcon from '@material-ui/icons/Warning';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getTimeString } from '../../utils/Time';
import styled from 'styled-components/macro';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Typography,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  disabledLink: {
    color: theme.palette.action.disabled,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
}));

const StyledCell = styled(TableCell)`
  width: '200px';
  white-space: nowrap;
`;

const Examinee: FC<IExaminee> = observer((examinee: IExaminee): ReactElement => {
  const classes = useStyles();
  const { adminStore, uiStore } = useStores();
  const { t } = useTranslation();
  const selectedLang = uiStore.selectedLanguage;
  const {
    id,
    modifiedAt,
    email,
    password,
    checkedIn,
    examStatus,
    extraTimeDuration,
    isOvertime,
    examHasEnded,
    answeredQuestions,
  } = examinee;
  const fullName = `${examinee.lastName} ${examinee.firstName}`;
  const [showPassword, setShowPassword] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [extraMinutes, setExtraMinutes] = useState('');
  const [extraHours, setExtraHours] = useState('');
  const exam: any = adminStore.chosenExam;

  useEffect(() => {
    if (extraTimeDuration) {
      const hours = Math.floor(extraTimeDuration / 60 / 60);
      const minutes = Math.floor(extraTimeDuration / 60 - hours * 60);

      setExtraHours(hours.toString());
      setExtraMinutes(minutes.toString());
    }
  }, []);

  const handleCheckedInChange = (event: ChangeEvent<HTMLInputElement>): void => {
    adminStore.updateExaminee(id, 'checkedIn', event.target.checked);
  };

  const handleExtraTimeSubmit = (extraHours: string, extraMinutes: string): void => {
    setShowDialog(false);

    if (!extraTimeDuration) {
      //When extraTimeDuration is not already set, convert the string values to int. They are string
      //in the first place because inputs should always be considered strings in react
      const newExtraHours = parseInt(extraHours) || 0;
      const newExtraMinutes = parseInt(extraMinutes) || 0;

      //convert to seconds
      const newExtraTimeSeconds = newExtraHours * 60 * 60 + newExtraMinutes * 60;

      adminStore.updateExaminee(id, 'extraTimeDuration', newExtraTimeSeconds).then(() => {
        setExtraHours(extraHours);
        setExtraMinutes(extraMinutes);
      });
    } else {
      //Update the store to have extraTimeDuration as null
      adminStore.updateExaminee(id, 'extraTimeDuration', null).then(() => {
        setExtraHours('');
        setExtraMinutes('');
      });
    }
  };

  const handleExamStatusChange = (event: ChangeEvent<any>): void => {
    adminStore.updateExaminee(id, 'examStatus', event.target.value);
  };

  const handleExtraMinuteChange = (minutes: string): void => {
    let minutesNumber = parseInt(minutes);
    let hoursNumber = parseInt(extraHours);

    //Calculate hours when minutes >= 60
    if (minutesNumber >= 60) {
      const overflowHours = minutesNumber / 60;
      const fullHours = Math.trunc(overflowHours);

      hoursNumber = (parseInt(extraHours) || 0) + fullHours;
      minutesNumber = parseInt(extraMinutes) % 60;
    }

    setExtraHours(hoursNumber.toString());
    setExtraMinutes(minutesNumber.toString());
  };

  const handleExtraHourChange = (hours: string): void => {
    setExtraHours(hours);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography>{fullName}</Typography>
          <Typography color="textSecondary">{email}</Typography>
        </TableCell>
        <StyledCell>
          {showPassword ? (
            <Typography>
              {password}
              <IconButton onClick={(): void => setShowPassword(false)}>
                <VisibilityOffIcon />
              </IconButton>
            </Typography>
          ) : (
            <Typography>
              {t('pages.admin.examinee.passwordDots')}
              <IconButton onClick={(): void => setShowPassword(true)}>
                <VisibilityIcon />
              </IconButton>
            </Typography>
          )}
        </StyledCell>
        <StyledCell>
          <Switch
            color="primary"
            checked={checkedIn}
            onChange={handleCheckedInChange}
            disabled={examStatus !== 'not-started' || examHasEnded}
          />
        </StyledCell>
        <StyledCell>
          {showDialog && (
            <Dialog open={showDialog} onClose={(): void => setShowDialog(false)}>
              <DialogTitle>{t('pages.admin.examinee.dialog.title')}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {extraTimeDuration
                    ? t('pages.admin.examinee.dialog.removeExtraTime', {
                      name: fullName,
                    })
                    : t('pages.admin.examinee.dialog.giveExtraTime', {
                      hours: parseInt(extraHours) || '0',
                      minutes: parseInt(extraMinutes) || '0',
                      name: fullName,
                    })}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={(): void => setShowDialog(false)} color="primary">
                  {t('pages.admin.examinee.dialog.cancel')}
                </Button>
                <Button
                  onClick={(): void => {
                    handleExtraTimeSubmit(extraHours, extraMinutes);
                  }}
                  color="primary"
                >
                  {t('pages.admin.examinee.dialog.confirm')}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <TextField
            label={t('pages.admin.examinee.hours')}
            id="standard-size-small"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={extraHours}
            onChange={(e): void => handleExtraHourChange(e.target.value)}
            style={{ maxWidth: '50px' }}
            disabled={examStatus === 'submitted' || isOvertime || examHasEnded}
          />{' '}
          <TextField
            label={t('pages.admin.examinee.minutes')}
            id="standard-number"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={extraMinutes}
            onChange={(e): void => handleExtraMinuteChange(e.target.value)}
            style={{ maxWidth: '50px' }}
            disabled={examStatus === 'submitted' || isOvertime || examHasEnded}
          />{' '}
          <IconButton
            color="primary"
            aria-label={t('pages.admin.examinee.giveExtraTime')}
            size="medium"
            style={{ margin: '0 0.5rem 0 0' }}
            onClick={(): void => setShowDialog(true)}
            disabled={examStatus === 'submitted' || isOvertime || examHasEnded}
          >
            {!extraTimeDuration ? (
              <Fragment>
                {' '}
                <Typography
                  className={
                    examStatus === 'submitted' || isOvertime || examHasEnded ? classes.disabledLink : classes.link
                  }
                >
                  {t('common.buttons.save.label')}
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                {' '}
                <Typography
                  className={
                    examStatus === 'submitted' || isOvertime || examHasEnded ? classes.disabledLink : classes.link
                  }
                >
                  {t('common.buttons.remove.label')}
                </Typography>
              </Fragment>
            )}
          </IconButton>
        </StyledCell>
        <StyledCell>
          <Typography>{answeredQuestions}</Typography>
        </StyledCell>
        <StyledCell>
          <Typography>{modifiedAt && examStatus !== 'not-started' ? getTimeString(modifiedAt) : '-'}</Typography>
        </StyledCell>
        <StyledCell>
          <FormControl variant="outlined">
            <Select
              value={examStatus}
              disabled={!checkedIn || examStatus === 'not-started' || examHasEnded}
              onChange={handleExamStatusChange}
            >
              <MenuItem value={'not-started'} disabled={true}>
                <Typography>
                  <WarningIcon></WarningIcon> {t('pages.admin.examinee.examStatus.notStarted')}
                </Typography>
              </MenuItem>
              <MenuItem value={'ongoing'}>
                <AccessTimeIcon></AccessTimeIcon> {t('pages.admin.examinee.examStatus.ongoing')}
              </MenuItem>
              <MenuItem value={'submitted'}>
                <SaveAltIcon></SaveAltIcon> {t('pages.admin.examinee.examStatus.submitted')}
              </MenuItem>
              <MenuItem value={'ended'} disabled={true}>
                <CheckCircleIcon></CheckCircleIcon> {t('pages.admin.examinee.examStatus.ended')}
              </MenuItem>
            </Select>
          </FormControl>
        </StyledCell>
        <StyledCell>
          <Button
            onClick={() => window.open('/results?examineeId=' + id + '&examId=' + exam.id + '&lang=' + selectedLang)}
            variant="contained"
          >
            {t('pages.admin.examinee.dialog.open')}
          </Button>
        </StyledCell>
      </TableRow>
    </>
  );
});

export default Examinee;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LanguageIcon from '@material-ui/icons/Language';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const ExamErrorSmall = styled(ErrorIcon)`
  ${({ theme }) => `
        height: ${theme.typography.footer.fontSize} !important;
        width: ${theme.typography.footer.fontSize} !important;
    `}
`;
export const ExamErrorSmallIcon: FC = (): ReactElement => <ExamErrorSmall aria-hidden={true} />;

const Error = styled(ErrorIcon)`
  ${({ theme }) => `
        color: ${theme.palette.light.dark};
        height: 10rem !important;
        width: 10rem !important;
    `}
`;
export const ExamErrorIcon: FC = (): ReactElement => <Error aria-hidden={true} />;

const SuccessSmall = styled(CheckCircleIcon)`
  ${({ theme }) => `
        height: ${theme.typography.footer.fontSize} !important;
        width: ${theme.typography.footer.fontSize} !important;
    `}
`;
export const SuccessSmallIcon: FC = (): ReactElement => <SuccessSmall aria-hidden={true} />;

const Success = styled(CheckCircleIcon)`
  ${({ theme }) => `
        color: ${theme.palette.valid.main};
        height: 10rem !important;
        width: 10rem !important;
    `}
`;
export const SuccessIcon: FC = (): ReactElement => <Success aria-hidden={true} />;

const LoadingSmall = styled(CircularProgress)`
  ${({ theme }) => `
        color: ${theme.palette.light.main};
        height: ${theme.typography.footer.fontSize} !important;
        width: ${theme.typography.footer.fontSize} !important;
    `}
`;
export const LoadingSmallIcon: FC = (): ReactElement => (
  <LoadingSmall aria-hidden={true} variant="indeterminate" disableShrink size={40} thickness={4} />
);

const Loading = styled(CircularProgress)`
  ${({ theme }) => `
        color: ${theme.palette.light.main};
        height: 10rem !important;
        width: 10rem !important;
    `}
`;
export const LoadingIcon: FC = (): ReactElement => (
  <Loading aria-hidden={true} variant="indeterminate" disableShrink size={40} thickness={4} />
);

const Account = styled(AccountCircleOutlinedIcon)`
  ${({ theme }) => `
        color: ${theme.palette.dark.light};
        height: 2rem !important;
        width: 2rem !important;
    `}
`;
export const AccountIcon: FC = (): ReactElement => <Account aria-hidden={true} />;

const AccounFilledSmall = styled(AccountCircleIcon)`
  ${({ theme }) => `
        color: ${theme.palette.light.main};
        height: 2.5rem;
        width: 2.5rem;
    `}
`;
export const AccounFilledSmallIcon: FC = (): ReactElement => <AccounFilledSmall aria-hidden={true} />;

export const LanguagesIcon: FC = (): ReactElement => <LanguageIcon aria-hidden={true} />;

const HelpFilledSmall = styled(HelpIcon)`
  ${({ theme }) => `
        color: ${theme.palette.light.main};
        height: 2.5rem;
        width: 2.5rem;
    `}
`;
const HelpOutlinedSelected = styled(HelpOutlineIcon)`
  ${({ theme }) => `
        color: ${theme.palette.primary.main};
    `}
`;
export const HelpFilledSmallIcon: FC = (): ReactElement => <HelpFilledSmall aria-hidden={true} />;
export const HelpOutLinedIcon: FC = (): ReactElement => <HelpOutlineIcon aria-hidden={true} />;
export const HelpOutLinedIconSelected: FC = (): ReactElement => <HelpOutlinedSelected aria-hidden={true} />;

export const ArrowDownIcon: FC = (): ReactElement => <ArrowDropDownIcon />;

export const ArrowBackIcon: FC = (): ReactElement => <ChevronLeftIcon />;

const ArrowBackSmall = styled(ChevronLeftIcon)`
  ${({ theme }) => `
        color: ${theme.palette.light.main};
        heiggt:1rem;
        width: 1rem;
    `}
`;
export const ArrowBackSmallIcon: FC = (): ReactElement => <ArrowBackSmall />;

const ArrowForward = styled(ChevronRightIcon)`
  ${({ theme }) => `
        color: ${theme.palette.light.main};
        heiggt:1rem;
        width: 1rem;
    `}
`;
export const ArrowForwardIcon: FC = (): ReactElement => <ArrowForward />;
const LogoutDark = styled(ExitToAppIcon)`
  ${({ theme }) => `
        color: ${theme.palette.dark.light};
    `}
`;
const LogoutLight = styled(ExitToAppIcon)`
  ${({ theme }) => `
        color: ${theme.palette.light.main};
    `}
`;
export const LogoutDarkIcon: FC = (): ReactElement => <LogoutDark aria-hidden={true} />;
export const LogoutLightIcon: FC = (): ReactElement => <LogoutLight aria-hidden={true} />;
const TimeSmall = styled(AccessTimeIcon)`
  ${({ theme }) => `
        color: ${theme.palette.light.main};
        height: ${theme.typography.footer.fontSize} !important;
        width: ${theme.typography.footer.fontSize} !important;
    `}
`;
export const TimeIconSmall: FC = (): ReactElement => <TimeSmall aria-hidden={true} />;

const StatusSmallIcon = styled(FiberManualRecordIcon)`
  heigt: 1rem;
  width: 1rem;
`;
const FinishedStatusSmall = styled(StatusSmallIcon)`
  ${({ theme }) => `
        color: ${theme.palette.tertiary.main};
    `}
`;
export const FinishedStatusSmallIcon: FC = (): ReactElement => <FinishedStatusSmall aria-hidden={true} />;

const OutlinedStatusSmallIcon = styled(FiberManualRecordOutlinedIcon)`
  heigt: 1rem;
  width: 1rem;
`;

const UnfinishedStatusSmall = styled(OutlinedStatusSmallIcon)`
  ${({ theme }) => `
        color: ${theme.palette.tertiary.main};
    `}
`;
export const UnfinishedStatusSmallIcon: FC = (): ReactElement => <UnfinishedStatusSmall aria-hidden={true} />;

const ReturnSmall = styled(SaveAltRoundedIcon)`
  ${({ theme }) => `
        color: ${theme.palette.primary.main};
    `}
`;

export const ReturnSmallIcon: FC = (): ReactElement => <ReturnSmall aria-hidden={true} />;

export const ReturnIcon: FC = (): ReactElement => <SaveAltRoundedIcon aria-hidden={true} />;

const ReturnAlt = styled(SaveAltIcon)`
  ${({ theme }) => `
        color: ${theme.palette.dark.light};
        height: 2.3rem;
        width: 2.3rem;
`}
`;

export const ReturnAltIcon: FC = (): ReactElement => <ReturnAlt aria-hidden={true} />;

const ReturnAltSmall = styled(SaveAltIcon)`
  ${({ theme }) => `
        color: ${theme.palette.light.main};
        height: 1.13rem !important;
        width: 1.13rem !important;
`}
`;

export const ReturnAltSmallIcon: FC = (): ReactElement => <ReturnAltSmall aria-hidden={true} />;

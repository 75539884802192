import React, { FC, ReactElement, ReactNode, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

import { useStores } from '../../hooks';
import { Box } from '@material-ui/core';

const ContainerWrapper = styled(AppBar)`
  ${({ theme }) => `
    background-color:${theme.palette.primary.main};
    color: ${theme.palette.light.main};
    font-size: ${theme.typography.header.fontSize};
    padding: 0 1rem;
  `}
`;

const DrawerButton = styled(IconButton)`
  ${({ theme }) => `
    margin:-right: 2rem;
    &.Mui-focusVisible {
        color: ${theme.palette.primary.dark};
        background: ${theme.palette.light.main};
    }
  `}
`;

const ContentContainer = styled.div`
  width: 100%;
`;

const DrawerIconButton: FC = (): ReactElement => {
  const { uiStore } = useStores();
  const { t } = useTranslation();
  const [open, setOpen] = useState(uiStore.isDrawerOpen);
  const description = open
    ? (t('common.drawer.closeDrawerDescription') as string)
    : (t('common.drawer.openDrawerDescription') as string);
  const toggleDrawer = () => {
    const newState = !open;
    setOpen(newState);
    uiStore.toggleDrawer();
  };
  return (
    <Box>
      <DrawerButton
        color="inherit"
        aria-label={description}
        role="button"
        aria-pressed={open}
        onClick={toggleDrawer}
        edge="start"
        size="medium"
      >
        <MenuIcon />
      </DrawerButton>
    </Box>
  );
};

interface Props {
  children?: ReactNode;
  drawer?: boolean;
}

// To create a new header, make a content component in this folder (e.g. Exam.tsx) and wrap it with this component.
// The Container component implements drawer and material-ui Toolbar functionality
const Header: FC<Props> = ({ children, drawer }: Props): ReactElement => (
  <ContainerWrapper position="static">
    <Toolbar variant={'dense'} disableGutters={true}>
      {drawer && <DrawerIconButton />}
      <ContentContainer>{children}</ContentContainer>
    </Toolbar>
  </ContainerWrapper>
);

export default Header;
//export { default as ExamHeader } from './Exam';

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks';
import Drawer from '../Drawer';
import { Snackbars, Banners } from '../Notifications';

interface LayoutContainerProps {
  open: boolean;
  width: string | number;
}

const LayoutContainer = styled.div<LayoutContainerProps>`
  ${({ theme, open, width }) => `
		margin-left: ${open ? width : '0'};
		min-height: inherit;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'header'
      'main'
      'footer';
	  transition: ${
  open
    ? theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
    : theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })
};
	`}
`;

interface Props {
  drawer?: ReactElement;
  header?: ReactElement;
  body?: ReactElement;
  footer?: ReactElement;
}

const Container: FC<Props> = observer(
  ({ drawer, header, body, footer }: Props): ReactElement => {
    const { uiStore } = useStores();
    const width = uiStore.drawerWidth || 0;
    const open = drawer ? uiStore.isDrawerOpen : false;
    return (
      <>
        {drawer && (
          <Drawer width={width} open={open}>
            {drawer}
          </Drawer>
        )}
        <LayoutContainer open={open} width={width}>
          {header}
          <Banners />
          <Snackbars />
          {body}
          {footer}
        </LayoutContainer>
      </>
    );
  }
);

export default Container;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  ExamPageCount,
  ExamNavigation,
  ExamNavigationSmall,
  ExamSaving,
  ExamSavingSmall,
  ExamTimer,
  ExamTimerSmall,
} from '../Exam';
import Container from './Container';

const Row = styled.div`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
    font-size: ${theme.typography.footer.fontSize};
    color: ${theme.palette.light.main};
  `}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: inherit;
  align-items: center;
  color: inherit;
`;

const ExamFooter: FC = (): ReactElement => {
  const matchesSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  if (matchesSmall) {
    return (
      <Container>
        <Row>
          <ExamTimerSmall />
          <ExamSavingSmall />
          <ExamNavigationSmall />
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <Row>
        <ExamTimer />
        <Column>
          <ExamSaving />
          <ExamPageCount />
        </Column>
        <ExamNavigation />
      </Row>
    </Container>
  );
};

export default ExamFooter;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { useStores } from '../../../hooks';
import { ReturnSmallIcon, HelpOutLinedIconSelected } from '../../Icons';
import AccountDetails from './AccountDetails';
import Separator from '../Separator';
import { ExamStatus } from '../../Exam';
import { LogoLight } from '../../Images';

const DrawerDivider = styled(Divider)`
  ${({ theme }) => `
    background-color:  ${theme.palette.background.secondary};
  `}
`;
const DrawerGrid = styled(Grid)`
  height: 100%;
  overflow: hidden;
`;

const PagesList = styled(List)`
  padding-top: 0;
`;

const LogoContainer = styled(Grid)`
  text-align: center;
  margin: 1rem 0;
`;

interface PagesListItemProps {
  selected: boolean;
}
const PagesListItem = styled(ListItem)<PagesListItemProps>`
  ${({ theme, selected }) => `
		background-color: ${selected ? theme.palette.primary.light : 'inherit'} !important;
		color: ${selected ? theme.palette.primary.dark : theme.palette.dark.light};
    &.Mui-focusVisible  {
      background-color: ${selected ? theme.palette.primary.light : theme.palette.light.main} !important;
      outline: 2px solid ${theme.palette.primary.main};
    }
	`}
`;

const CopyrightNotice = styled(ListItem)`
  ${({ theme }) => `
    color: ${theme.palette.dark.light};
    &.Mui-focusVisible  {
      background-color: ${theme.palette.light.main} !important;
      outline: 2px solid ${theme.palette.primary.main};
    }
    pointer-events: none;
  `}
`;

export const ExamDrawer: FC = observer(
  (): ReactElement => {
    const { examStore } = useStores();
    const { t } = useTranslation();
    const returnTitle = t('common.return.label') as string;
    const instructionsTitle = t('common.instructions.label') as string;
    const currentYear: number = new Date().getFullYear();
    const copyrightNoticeText = t('common.drawer.copyrightNotice', { currentYear }) as string;

    return (
      <DrawerGrid container direction="column" justify="space-between" wrap="nowrap">
        <Grid item>
          <PagesList>
            <Separator />
            <AccountDetails />
            <DrawerDivider />
            {examStore.taskStatus.map((task, i) => {
              const text = t('pages.exam.common.title') as string;
              const title = text + ' ' + (i + 1);
              return (
                <PagesListItem
                  button
                  key={title + '-' + i}
                  selected={task.id === examStore.selectedPage}
                  onClick={() => examStore.setCurrentPage(task.id)}
                >
                  <ListItemText primary={title} />
                </PagesListItem>
              );
            })}
            <DrawerDivider />
            <PagesListItem
              button
              selected={examStore.returnPageId === examStore.selectedPage}
              onClick={() => examStore.setReturnPage()}
            >
              <ListItemIcon>
                <ReturnSmallIcon />
              </ListItemIcon>
              <ListItemText primary={returnTitle} />
            </PagesListItem>
            <PagesListItem
              button
              selected={examStore.instructionsPageId === examStore.selectedPage}
              onClick={() => examStore.setInstructionsPage()}
            >
              <ListItemIcon>
                <HelpOutLinedIconSelected />
              </ListItemIcon>
              <ListItemText primary={instructionsTitle} />
            </PagesListItem>
            <CopyrightNotice button tabIndex={-1}>
              <ListItemText primary={copyrightNoticeText} />
            </CopyrightNotice>
          </PagesList>
        </Grid>
        <LogoContainer item>
          <LogoLight />
        </LogoContainer>
      </DrawerGrid>
    );
  }
);

export default ExamDrawer;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { LanguagesIcon } from './Icons';
import { TLanguage } from '../types';

const LanguagesContainer = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.light.main};
      display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 0.3rem;
    }
    &.Mui-focusVisible {
      outline: 2px solid ${theme.palette.light.main};
    }
  `}
`;

const LanguageButton = styled.button`
  ${({ theme }) => `
    margin: 0 0.5rem 0 0;
    padding: 0;
    color: inherit;
    border: none;
    background-color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: ${theme.typography.header.fontSize};
    &:hover {
      opacity: 0.8;
    }
    &.Mui-focusVisible {
      outline: 2px solid ${theme.palette.light.main};
    }
  `}
`;

const LanguageButtonSelected = styled(LanguageButton)`
  ${({ theme }) => `
    font-weight: bold;
    text-decoration: underline;
    &.Mui-focusVisible {
      outline: 2px solid ${theme.palette.light.main};
    }
  `}
`;

interface Props {
  languages: TLanguage[];
  onChange: (language: TLanguage) => void;
  selected: TLanguage;
}

const Language: FC<Props> = ({ languages = [], onChange, selected }: Props): ReactElement => {
  const { t } = useTranslation();
  const index = languages.indexOf(selected);
  const selectedLanguageIndex = index > -1 ? index : 0;
  const selectDescription = t('common.language.selectDescription');
  const isSelectedDescription = t('common.language.isSelectedDescription');
  const selectedLanguage = (lang: string): string =>
    lang === 'fi' ? t('common.language.finnish') : t('common.language.swedish');

  return (
    <LanguagesContainer>
      <LanguagesIcon />
      {languages.map((language: TLanguage, i: number) =>
        i === selectedLanguageIndex ? (
          <LanguageButtonSelected
            role="button"
            aria-live="polite"
            aria-label={selectedLanguage(language) + ' ' + isSelectedDescription}
            aria-pressed={true}
            key={'language-' + i}
            onClick={() => onChange(language)}
          >
            {language === 'fi' ? 'FI' : 'SV'}
          </LanguageButtonSelected>
        ) : (
          <LanguageButton
            role="button"
            aria-label={selectDescription + ': ' + selectedLanguage(language)}
            aria-pressed={false}
            key={'language-' + i}
            onClick={() => onChange(language)}
          >
            {language === 'fi' ? 'FI' : 'SV'}
          </LanguageButton>
        )
      )}
    </LanguagesContainer>
  );
};

export default Language;

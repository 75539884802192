import React, { FC, ReactElement, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { MuiThemeProvider, StylesProvider, createMuiTheme } from '@material-ui/core/styles';
import { defaultTheme, GlobalStyle } from './Themes';

const theme = createMuiTheme(defaultTheme);

interface Props {
  children?: ReactNode;
}

const Theme: FC<Props> = ({ children }: Props): ReactElement => (
  <>
    {/* Apply some basic global styles to body and html (Unless they are moved to a theme) */}
    <GlobalStyle />
    {/* Theme override Material-UI's styles */}
    <StylesProvider injectFirst>
      {/* Apply the theme to Material-UI components */}
      <MuiThemeProvider theme={theme}>
        {/* Apply the theme to styled components */}
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </>
);

export default Theme;

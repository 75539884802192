import { makeObservable, observable, action, computed, reaction } from 'mobx';
import RootStore from '../RootStore';
import { BackendInstance } from '../../services/Backend';
import { IUser } from './types';
import { ViewState } from '../UIStore/Model';
import { translateText } from '../../utils/i18n';
import { validateCode } from '../../utils/Validate';

//TODO: make sure that this resembles the backend
const validCodeRegex = RegExp(/^[a-zA-Z0-9]{5}/);

interface IUserStore {
  user: IUser | undefined;
  isLoggedIn: boolean;
  isAdmin: boolean;
  logout: () => Promise<void>;
  previewLogout: () => void;
  validateUser: () => Promise<void>;
  getUser: () => Promise<void>;
  handleNotCheckedIn: () => void;
}

export class UserStore implements IUserStore {
  private rootStore: RootStore;
  user: IUser | undefined = undefined;
  validated = false;
  isLoggedIn = false;
  isAdmin = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      user: observable,
      validated: observable,
      isLoggedIn: observable,
      isAdmin: observable,
      examStarted: computed,
      logout: action.bound,
      previewLogout: action.bound,
      validateUser: action.bound,
      getUser: action.bound,
      handleNotCheckedIn: action.bound,
      syncTime: action.bound,
    });

    reaction(
      () => this.user,
      (user) => {
        if (user && user.end) {
          this.rootStore.uiStore.setViewState('finished');
        }
      }
    );
  }
  public async syncTime(): Promise<void> {
    const user = await BackendInstance.getUser();
    if (!this.user) {
      this.setViewState('authenticating-error');
      return;
    }
    this.user.start = user.start;
    this.user.deadline = user.deadline;
  }

  public get examStarted(): boolean {
    return this.user && this.user.start && this.user.deadline ? true : false;
  }

  private setViewState(state: ViewState) {
    this.rootStore.uiStore.setViewState(state);
  }

  public async login(password: string, examPassword: string): Promise<void> {
    try {
      this.setViewState('authenticating');
      const isPasswordValid = validateCode(password, validCodeRegex);
      const isExamPasswordValid = validateCode(examPassword, validCodeRegex);
      if (!isExamPasswordValid || !isPasswordValid) {
        throw new Error('Login error');
      } else {
        await BackendInstance.login(password, examPassword);
        await this.getUser();
        console.log('Successfully logged in user');
        setTimeout(() => {
          this.rootStore.uiStore.navigateToExam();
        }, 1000);
      }
    } catch (error) {
      console.log('Failed to login user ');
      if (error.response?.status === 401 && error.response?.data?.message === 'Examinee is not checked in') {
        this.handleNotCheckedIn();
      }
      this.setViewState('authenticating-error');
    }
  }

  public async validateUser(): Promise<void> {
    try {
      this.setViewState('authenticating');
      await this.getUser();

      if (this.isAdmin) {
        return;
      }

      console.log('User is logged in. Loading exam.');
      setTimeout(() => {
        this.rootStore.uiStore.navigateToExam();
      }, 1000);
    } catch (error) {
      console.log('User is not logged in');
      this.logout();
    } finally {
      this.validated = true;
    }
  }

  public async getUser(): Promise<void> {
    const user = await BackendInstance.getUser();

    this.user = user;
    this.isAdmin = user.role === 'admin';
    this.isLoggedIn = true;
  }

  public async logout(): Promise<void> {
    try {
      await BackendInstance.logout();
      this.rootStore.resetExam();
      console.log('User logged out successfully. Clearing user data');
    } catch (error) {
      console.log('Error: Could not log out user. Clearing user data');
    }
    const { notificationStore } = this.rootStore;
    const nextBanner = notificationStore.nextBanner;
    if (nextBanner) {
      notificationStore.removeBanner(nextBanner.key);
    }
    this.user = undefined;
    this.isLoggedIn = false;
    this.isAdmin = false;
    this.setViewState('initial');
  }

  public previewLogout(): void {
    this.rootStore.resetExam();
    window.close();
  }

  public handleNotCheckedIn(): void {
    const text = translateText('pages.landing.login.snackbars.notCheckedIn.text');
    this.rootStore.notificationStore.addSnackbar('info', text);
    this.rootStore.userStore.logout();
  }
}

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { HelpOutLinedIcon } from './Icons';

const InstructionsContainer = styled.div`
	color: ${({ theme }) => `${theme.palette.light.main};`}
    display: flex;
	flex-direction: row;
	align-items: center;
	svg {
		margin-right: 0.3rem;
	}
`;

const InstructionsButton = styled.button`
	${({ theme }) => `
		padding: 0;
		color: inherit;
		border: none;
		background-color: inherit;
		cursor: pointer;
		font-size: ${theme.typography.header.fontSize};
		&:hover {
			opacity: 0.8;
		}
	`}
`;

interface Props {
	onClick: ()=> void;
}
const Instructions: FC<Props> = ({ onClick = () => '' }: Props): ReactElement => {
  const { t } = useTranslation();
  const label = t('common.instructions.label');

  return <InstructionsContainer>
    <HelpOutLinedIcon />
    <InstructionsButton onClick={() => onClick()}>{label}</InstructionsButton>
  </InstructionsContainer>;
};

export default Instructions;
/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash.uniqueid';
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from '../../../hooks';

const useStyles = makeStyles({
  svg: {
    userSelect: 'none',
  },
});

// Logo for dark background.
const LogoDark: FC = (): ReactElement => {
  const classes = useStyles();
  const { uiStore } = useStores();
  const { t } = useTranslation();
  const selectedLanguage = uiStore.selectedLanguage;
  const subtitle = t('images.logo.subtitle') as string;
  const titleId = uniqueId('logo-dark-title-');
  const descriptionId = uniqueId('logo-dark-description-');

  return selectedLanguage == 'sv_SE' ? (
    <svg
      role="img"
      aria-hidden={true}
      aria-labelledby={`${titleId} ${descriptionId}`}
      width="150%"
      height="150%"
      viewBox="0 0 263.98 77.6"
      className={classes.svg}
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id=".kamari/Who-Are-We" transform="translate(-24.000000, -24.000000)" fill="#FBFCFE">
          <g id="Group" transform="translate(24.000000, 24.000000)">
            <text
              id={subtitle}
              fontFamily="HackNerdFontCompleteM-Regular, Hack Nerd Font Mono"
              fontSize="14"
              fontWeight="400"
              // eslint-disable-next-line react/no-unknown-property
              line-spacing="16"
              letterSpacing="0.333333338"
            >
              <tspan x="63" y="77.6">
                {subtitle.toLocaleUpperCase()}
              </tspan>
            </text>
            <defs>
              <style>{'fill:#fff'}</style>
            </defs>
            <path d="M93 32.51a5.11 5.11 0 01-1.41.58 7.62 7.62 0 01-2 .22 5.87 5.87 0 01-2.31-.45 5 5 0 01-1.85-1.35 6.41 6.41 0 01-1.24-2.25 10 10 0 01-.45-3.15 10.57 10.57 0 01.47-3.37 6.22 6.22 0 011.27-2.23 4.7 4.7 0 011.78-1.22 5.79 5.79 0 012-.38 7.82 7.82 0 013.42.6l.5-1.85a8.17 8.17 0 00-1.62-.44 16 16 0 00-2.45-.16 8 8 0 00-2.75.5A6.82 6.82 0 0084 19.15 8.06 8.06 0 0082.24 22a11.6 11.6 0 00-.66 4.16 12.28 12.28 0 00.6 4.06A8 8 0 0083.8 33a6.49 6.49 0 002.4 1.63 8 8 0 003 .54 12.69 12.69 0 002.45-.22 4.52 4.52 0 001.9-.8z" />
            <path d="M96.45 34.86L106.1 34.86 106.1 33.01 98.53 33.01 98.53 26.79 105.35 26.79 105.35 24.94 98.53 24.94 98.53 19.21 105.98 19.21 105.98 17.36 96.45 17.36 96.45 34.86z" />
            <path d="M137.63 17.36L124.65 17.36 124.65 19.21 130.1 19.21 130.1 34.86 132.18 34.86 132.18 19.21 137.63 19.21 137.63 17.36z" />
            <path d="M181.26 33.01L172.99 33.01 172.99 17.36 170.91 17.36 170.91 34.86 181.26 34.86 181.26 33.01z" />
            <path d="M178.69 24.74H184.74V26.59H178.69z" />
            <path d="M120.86 35.14L121.96 35.14 121.96 17.36 120.01 17.36 120.01 28.76 120 30.29 118.86 28.76 110.48 17.09 109.38 17.09 109.38 34.86 111.33 34.86 111.33 23.41 111.34 21.6 112.53 23.41 120.86 35.14z" />
            <path d="M148.32 22.06a3.1 3.1 0 01-1.13 2.65 4.57 4.57 0 01-2.85.85h-2v-6.45a2.36 2.36 0 01.46-.07l.62-.05h1.35a3.55 3.55 0 012.57.88 2.91 2.91 0 01.93 2.22m1.07 3.25a4.84 4.84 0 001.18-3.34 4.8 4.8 0 00-.48-2.25 4 4 0 00-1.26-1.5 5 5 0 00-1.83-.83 9.64 9.64 0 00-2.18-.25c-.69 0-1.44 0-2.27.1a21.75 21.75 0 00-2.18.28v17.31h2.07v-7.92l2.45.37 4.83 7.55h2.42l-2.65-4.22-2.37-3.8a5.28 5.28 0 002.32-1.53M163.73 28.21h-5.13l1.92-5.27.62-1.83.63 1.78zm4.61 6.65l-6.65-17.77h-.95l-6.63 17.77h2.08l1.74-4.86h6.42l1.79 4.85zM139.09 54.67h3.71l-1.87-5.52zm4.46 2.73h-5.33l-1.31 3.84h-3.4l6.25-17.47h2.5l6.24 17.47h-3.57zM194.3 54.67h3.7l-1.87-5.52zm4.46 2.73h-5.32l-1.32 3.84h-3.39L195 43.77h2.5l6.25 17.47h-3.57z" />
            <path d="M129.08 61.24L133.26 61.24 126.84 52.15 132.5 43.9 128.63 43.9 123.78 51.57 123.06 51.58 123.06 43.9 119.66 43.9 119.66 61.24 123.06 61.24 123.06 53.83 123.95 53.83 129.08 61.24z" />
            <path d="M163.32 61.24L166.71 61.24 166.71 43.9 163.64 43.9 158.2 53.15 152.96 43.9 149.87 43.9 149.87 61.24 153.09 61.24 153.09 50.55 157.62 57.51 158.78 57.52 163.32 50.55 163.32 61.24z" />
            <path d="M183.96 61.24L187.35 61.24 187.35 43.9 184.28 43.9 178.84 53.15 173.6 43.9 170.5 43.9 170.5 61.24 173.72 61.24 173.73 50.55 178.25 57.51 179.42 57.52 183.95 50.55 183.96 61.24z" />
            <path d="M212.82 47.3a2.26 2.26 0 01.69 1.75 2.52 2.52 0 01-.84 2.13 3.63 3.63 0 01-2.26.64H209v-5a4.18 4.18 0 01.78-.09h1a3 3 0 012 .63m-7.24 13.94H209v-7.17l1.78.32 3.94 6.79h3.91l-4.42-7.48a4.53 4.53 0 002.31-1.64 5.86 5.86 0 00.68-3.19 6.34 6.34 0 00-.66-2.79 4 4 0 00-1.49-1.5 6.65 6.65 0 00-2.1-.77 12.73 12.73 0 00-2.39-.22h-1.14c-.42 0-.84 0-1.28.08s-.87.08-1.31.13-.85.12-1.23.19zM39.14 49.15L41 54.67h-3.71zm4 12.09h3.57l-6.25-17.47H38l-6.28 17.47h3.39l1.32-3.84h5.32z" />
            <path d="M26.54 61.4L29.97 61.4 29.97 43.9 26.54 43.9 26.54 51.02 20.14 51.02 20.14 43.9 16.72 43.9 16.72 61.4 20.14 61.4 20.14 54.07 26.54 54.07 26.54 61.4z" />
            <path d="M59.5 61.52L62 61.52 62 43.9 58.75 43.9 58.75 54.55 57.7 53.1 51.07 43.77 48.57 43.77 48.57 61.4 51.82 61.4 51.82 50.63 52.92 52.27 59.5 61.52z" />
            <path d="M244.76 61.52L247.26 61.52 247.26 43.9 244.01 43.9 244.01 54.55 242.96 53.1 236.33 43.77 233.83 43.77 233.83 61.4 237.08 61.4 237.08 50.63 238.18 52.27 244.76 61.52z" />
            <path d="M75.26 52.5a11.63 11.63 0 01-.26 2.29 5.6 5.6 0 01-.78 2 4.12 4.12 0 01-1.53 1.36 5.09 5.09 0 01-2.41.51H68.87a1.84 1.84 0 01-.34 0V46.8a8 8 0 01.84-.08h1.11a4.73 4.73 0 012.35.52 4.13 4.13 0 011.46 1.35 5.06 5.06 0 01.74 1.87 11.14 11.14 0 01.2 2.06m3.6 0a12.65 12.65 0 00-.48-3.58 7.21 7.21 0 00-1.5-2.8 6.71 6.71 0 00-2.67-1.82 11 11 0 00-4-.65h-2.5l-1.36.07c-.44 0-.84.08-1.19.13v17.55a8.05 8.05 0 001 .09l1.36.08 1.38.06h1.06a10.4 10.4 0 004.24-.77 7.56 7.56 0 002.76-2 7.77 7.77 0 001.5-2.93 12.94 12.94 0 00.45-3.42" />
            <path d="M80.81 61.4L91.31 61.4 91.31 58.35 84.24 58.35 84.24 54.07 90.56 54.07 90.56 51.02 84.24 51.02 84.24 46.95 91.19 46.95 91.19 43.9 80.81 43.9 80.81 61.4z" />
            <path d="M105.07 58.35L97.42 58.35 97.42 43.9 93.99 43.9 93.99 61.4 105.07 61.4 105.07 58.35z" />
            <path d="M113.1 58.26a4.42 4.42 0 01-2.34.51 7.48 7.48 0 01-1.26-.1 9.88 9.88 0 01-1.16-.25c-.37-.1-.7-.21-1-.32a7.17 7.17 0 01-.71-.33l-1.1 2.93a10.09 10.09 0 002.1.7 14.09 14.09 0 003.3.35 9.71 9.71 0 002.61-.33 5.44 5.44 0 002-1 4.59 4.59 0 001.3-1.68 5.77 5.77 0 00.46-2.42 4.31 4.31 0 00-.59-2.33 5.55 5.55 0 00-1.47-1.58 10.69 10.69 0 00-1.93-1.09q-1-.45-1.92-.87a6.49 6.49 0 01-1.48-.93 1.58 1.58 0 01-.59-1.25A1.51 1.51 0 01110 47a3 3 0 011.84-.48 9.38 9.38 0 012.37.29 5.59 5.59 0 011.65.64l1.08-2.88a7.54 7.54 0 00-2.23-.76 15.39 15.39 0 00-2.87-.26 9 9 0 00-2.4.3 5.23 5.23 0 00-1.86.92 4.2 4.2 0 00-1.22 1.57 5.35 5.35 0 00-.42 2.21 4.54 4.54 0 00.59 2.42 5.33 5.33 0 001.47 1.59 10.08 10.08 0 001.93 1.08c.69.29 1.33.57 1.92.85a6.13 6.13 0 011.48.91 1.63 1.63 0 01.59 1.3 1.72 1.72 0 01-.82 1.56" />
            <path d="M220.65 61.4L231.15 61.4 231.15 58.35 224.08 58.35 224.08 54.07 230.4 54.07 230.4 51.02 224.08 51.02 224.08 46.95 231.03 46.95 231.03 43.9 220.65 43.9 220.65 61.4z" />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      role="img"
      aria-hidden={true}
      aria-labelledby={`${titleId} ${descriptionId}`}
      width="125%"
      height="125%"
      viewBox="0 0 211.46 77.6"
      className={classes.svg}
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id=".kamari/Who-Are-We" transform="translate(-24.000000, -24.000000)" fill="#FBFCFE">
          <g id="Group" transform="translate(24.000000, 24.000000)">
            <text
              id={subtitle}
              fontFamily="HackNerdFontCompleteM-Regular, Hack Nerd Font Mono"
              fontSize="14"
              fontWeight="400"
              // eslint-disable-next-line react/no-unknown-property
              line-spacing="16"
              letterSpacing="0.333333338"
            >
              <tspan x="34" y="77.6">
                {subtitle.toLocaleUpperCase()}
              </tspan>
            </text>
            <defs>
              <style>{'fill:#fff'}</style>
            </defs>
            <path d="M96 53.68h3.71l-1.87-5.52zm4.45 2.73h-5.31l-1.31 3.84h-3.4l6.24-17.47h2.51l6.24 17.47h-3.57z" />
            <path d="M111.57 52.84L110.68 52.84 110.68 60.25 107.28 60.25 107.28 42.9 110.68 42.9 110.68 50.59 111.4 50.58 116.25 42.9 120.11 42.9 114.46 51.15 120.88 60.25 116.7 60.25 111.57 52.84z" />
            <path d="M126.85 53.68h3.71l-1.86-5.52zm4.46 2.73H126l-1.31 3.84h-3.39l6.24-17.47H130l6.24 17.47h-3.54zM162.85 53.68h3.72l-1.87-5.52zm4.46 2.73H162l-1.32 3.84h-3.39l6.24-17.47H166l6.24 17.47h-3.54z" />
            <path d="M27.59 60.29L31.77 60.29 25.35 51.19 31 42.95 27.14 42.95 22.29 50.62 21.57 50.63 21.57 42.95 18.17 42.95 18.17 60.29 21.57 60.29 21.57 52.88 22.46 52.88 27.59 60.29z" />
            <path d="M151.73 60.25L155.12 60.25 155.12 42.91 152.05 42.91 146.61 52.16 141.37 42.91 138.28 42.91 138.28 60.25 141.5 60.25 141.5 49.56 146.03 56.52 147.19 56.53 151.73 49.56 151.73 60.25z" />
            <path d="M181.8 46.32a2.24 2.24 0 01.69 1.74 2.52 2.52 0 01-.84 2.13 3.6 3.6 0 01-2.25.65H178v-5a4 4 0 01.78-.1h1a2.92 2.92 0 012 .64m-7.23 13.93H178v-7.17l1.79.32 3.94 6.79h3.91l-4.42-7.48a4.47 4.47 0 002.3-1.63 5.81 5.81 0 00.69-3.2 6.24 6.24 0 00-.67-2.79 4.17 4.17 0 00-1.48-1.5 6.38 6.38 0 00-2.11-.76 12.66 12.66 0 00-2.39-.23h-1.14c-.41 0-.84 0-1.27.07s-.88.08-1.32.14l-1.22.18z" />
            <path d="M190.57 42.91H193.95999999999998V60.25H190.57z" />
            <path d="M39.48 48.16l1.87 5.53h-3.72zm4 12.09H47l-6.2-17.46h-2.5l-6.24 17.46h3.39l1.31-3.84h5.33zM57.64 53.6a4.52 4.52 0 01-.64 2.8 2.73 2.73 0 01-2.26.86 4.83 4.83 0 01-1.39-.18 2.23 2.23 0 01-1-.62 2.67 2.67 0 01-.58-1.14 7 7 0 01-.18-1.72v-11h-3.4v11.67q0 6 6.59 6a8.25 8.25 0 002.5-.37 4.84 4.84 0 001.94-1.17 5.46 5.46 0 001.23-2 8.31 8.31 0 00.45-2.89V42.65h-3.26zM70.82 45.71a2.91 2.91 0 011.1.45 2.46 2.46 0 01.78.85 2.75 2.75 0 01.3 1.35 3.36 3.36 0 01-.31 1.56 2.35 2.35 0 01-.83.93 3.31 3.31 0 01-1.18.46 7.37 7.37 0 01-1.32.12h-1.17a1.29 1.29 0 01-.29-.05v-5.67l.71-.09h1a6.64 6.64 0 011.26.12M64.5 60.25h3.4v-6h1.99a9.77 9.77 0 002.49-.32 6.1 6.1 0 002.14-1A5.31 5.31 0 0076 51.17a6.06 6.06 0 00.57-2.76 5.79 5.79 0 00-.66-2.94 4.84 4.84 0 00-1.7-1.75 6.42 6.42 0 00-2.34-.85 16.25 16.25 0 00-2.59-.21c-.82 0-1.65 0-2.49.11s-1.59.18-2.29.31zM85.48 45.71a2.91 2.91 0 011.1.45 2.46 2.46 0 01.78.85 2.75 2.75 0 01.3 1.35 3.49 3.49 0 01-.31 1.56 2.43 2.43 0 01-.83.93 3.31 3.31 0 01-1.18.46 7.37 7.37 0 01-1.32.12h-1.17a1.54 1.54 0 01-.3-.05v-5.67l.72-.09h.94a6.75 6.75 0 011.27.12m-6.32 14.51h3.39v-6a4.11 4.11 0 00.41 0h1.58a9.77 9.77 0 002.46-.19 6.1 6.1 0 002.14-1 5.28 5.28 0 001.51-1.85 6.06 6.06 0 00.57-2.76 5.9 5.9 0 00-.65-2.94 4.92 4.92 0 00-1.7-1.75 6.42 6.42 0 00-2.34-.85 16.25 16.25 0 00-2.59-.21c-.83 0-1.66 0-2.49.11s-1.6.18-2.29.31z" />
            <path d="M77.52 34.52L87.17 34.52 87.17 32.67 79.59 32.67 79.59 26.44 86.42 26.44 86.42 24.59 79.59 24.59 79.59 18.87 87.04 18.87 87.04 17.02 77.52 17.02 77.52 34.52z" />
            <path d="M98 31.37a2.67 2.67 0 01-.83.91 3.63 3.63 0 01-1.25.53 7.68 7.68 0 01-1.52.19 9.38 9.38 0 01-2.53-.31 8.09 8.09 0 01-1.7-.62l-.7 1.85a8.16 8.16 0 001.9.64 14 14 0 003.1.29 8.29 8.29 0 002.5-.35 5.62 5.62 0 001.88-1 4.3 4.3 0 001.15-1.58 4.9 4.9 0 00.4-2 4.09 4.09 0 00-.62-2.3A6.4 6.4 0 0098.23 26a12.91 12.91 0 00-2-1.16c-.74-.33-1.42-.67-2-1a6.21 6.21 0 01-1.56-1.16A2.26 2.26 0 0192 21a2.29 2.29 0 01.83-1.74 3.61 3.61 0 012.47-.73 10.64 10.64 0 012.51.26 7.82 7.82 0 011.69.54l.63-1.85a7.24 7.24 0 00-1.95-.58 15.83 15.83 0 00-2.78-.22 6.12 6.12 0 00-4.05 1.2 4 4 0 00-1.42 3.25 4.25 4.25 0 00.62 2.38 5.62 5.62 0 001.57 1.58 11.25 11.25 0 002 1.09 20.41 20.41 0 012 1 6 6 0 011.56 1.18 2.37 2.37 0 01.63 1.71 2.58 2.58 0 01-.32 1.3M127.79 28.12a5.93 5.93 0 01-.88 3.66 3.47 3.47 0 01-2.9 1.11 5.91 5.91 0 01-1.83-.25 2.89 2.89 0 01-1.32-.81 3.5 3.5 0 01-.78-1.47 8 8 0 01-.27-2.24V17h-2.07v12.09a5.39 5.39 0 001.57 4.25 6.82 6.82 0 004.68 1.4 7.37 7.37 0 002.37-.35 4.65 4.65 0 001.84-1.08 5 5 0 001.18-1.89 8.26 8.26 0 00.41-2.73V17h-2zM141.18 31.37a2.78 2.78 0 01-.83.91 3.63 3.63 0 01-1.25.53 7.7 7.7 0 01-1.56.16 9.35 9.35 0 01-2.52-.31 8.09 8.09 0 01-1.7-.62l-.7 1.85a8.16 8.16 0 001.9.64 14 14 0 003.1.29 8.34 8.34 0 002.5-.35 5.72 5.72 0 001.88-1 4.42 4.42 0 001.17-1.55 4.9 4.9 0 00.4-2 4.09 4.09 0 00-.62-2.3 6.4 6.4 0 00-1.57-1.62 12.91 12.91 0 00-2-1.16c-.74-.33-1.42-.67-2-1a6.21 6.21 0 01-1.56-1.16 2.32 2.32 0 01.2-3.37 3.61 3.61 0 012.47-.73 10.7 10.7 0 012.51.26 8 8 0 011.69.54l.63-1.85a7.21 7.21 0 00-2-.58 15.63 15.63 0 00-2.77-.22 6.12 6.12 0 00-4.05 1.2 4 4 0 00-1.5 3.24 4.17 4.17 0 00.63 2.38 5.72 5.72 0 001.56 1.58 11.69 11.69 0 002 1.09 21.51 21.51 0 012 1 6 6 0 011.56 1.18 2.37 2.37 0 01.63 1.71 2.58 2.58 0 01-.32 1.3" />
            <path d="M144.71 24.39H150.76000000000002V26.240000000000002H144.71z" />
            <path d="M71.98 34.52L74.6 34.52 68.2 26.37 67.34 25.37 67.98 24.54 73.83 17.02 71.43 17.02 65.55 24.82 64.43 24.83 64.43 17.02 62.35 17.02 62.35 34.52 64.43 34.52 64.43 26.42 65.6 26.42 71.98 34.52z" />
            <path d="M113.11 34.52L115.73 34.52 109.33 26.37 108.47 25.37 109.11 24.54 114.96 17.02 112.56 17.02 106.68 24.82 105.56 24.83 105.56 17.02 103.48 17.02 103.48 34.52 105.56 34.52 105.56 26.42 106.73 26.42 113.11 34.52z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoDark;

import React, { FC, ReactElement, ReactNode } from 'react';
import { useStores } from '../../hooks';
import { AdminHeader } from '../Header';
import { AdminDrawer } from '../Drawer';
import Container from './Container';
import { Box } from '@material-ui/core';

interface Props {
  children?: ReactNode;
}

const AdminLayout: FC<Props> = ({ children }: Props): ReactElement => {
  const Body: FC = (): ReactElement => <Box>{children}</Box>;
  const { uiStore } = useStores();
  uiStore.setDrawerWidth('18rem');
  return <Container drawer={<AdminDrawer />} header={<AdminHeader />} body={<Body />} />;
};

export default AdminLayout;

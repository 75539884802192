import React, { FC, ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStores } from '../hooks';
import {
  LandingContainer,
  LoadingError,
  SubmittingError,
  LandingDisclaimer,
  LandingSubmit,
  LandingFinished,
  LandingLoading,
  LandingSuccess,
  LandingUserDetails,
} from '../components/Landing';
import { ExamContainer, ExamPages } from '../components/Exam';

const Exam: FC = observer(
  (): ReactElement => {
    const { uiStore, examStore } = useStores();
    const { t } = useTranslation();
    const LoadingTitle = t('pages.landing.loading.initialText') as string;
    const LoadingSuccessText = t('pages.landing.loading.successText') as string;
    const UserConfirmationTitle = t('pages.landing.userDetails.title') as string;
    const DisclaimerTitle = examStore.disclaimer.title;
    const LoadingErrorTitle = t('pages.landing.loadingError.title') as string;
    const ExamFinishTitle = t('pages.landing.finish.title') as string;
    const ExamSubmittingText = t('pages.landing.loading.submittingText') as string;
    const ExamSubmittingErrorTitle = t('pages.landing.submittingError.title') as string;
    const ExamSubmittingSuccessText = t('pages.landing.loading.examClosedText') as string;
    const ExamFinishedTitle = t('pages.landing.finished.title') as string;

    useEffect(() => {
      examStore.prepareExam();
    }, []);

    switch (uiStore.currentState) {
    case 'loading':
      return (
        <LandingContainer heading={LoadingTitle}>
          <LandingLoading />
        </LandingContainer>
      );
    default:
    case 'loading-error':
      return (
        <LandingContainer heading={LoadingErrorTitle}>
          <LoadingError onReload={() => examStore.loadExam()} />
        </LandingContainer>
      );
    case 'loading-success':
      return (
        <LandingContainer heading={LoadingSuccessText}>
          <LandingSuccess />
        </LandingContainer>
      );
    case 'user-info':
      return (
        <LandingContainer heading={UserConfirmationTitle}>
          <LandingUserDetails />
        </LandingContainer>
      );
    case 'exam-info':
      return (
        <LandingContainer heading={DisclaimerTitle}>
          <LandingDisclaimer onStart={() => examStore.startExam()} />
        </LandingContainer>
      );
    case 'started':
      return (
        <ExamContainer>
          <ExamPages />
        </ExamContainer>
      );
    case 'finish':
      return (
        <LandingContainer noBack={true} heading={ExamFinishTitle}>
          <LandingSubmit />
        </LandingContainer>
      );
    case 'submitting':
      return (
        <LandingContainer noBack={true} heading={ExamSubmittingText}>
          <LandingLoading />
        </LandingContainer>
      );
    case 'submitting-error':
      return (
        <LandingContainer heading={ExamSubmittingErrorTitle}>
          <SubmittingError />
        </LandingContainer>
      );
    case 'submitting-success':
      return (
        <LandingContainer noBack={true} heading={ExamSubmittingSuccessText}>
          <LandingSuccess />
        </LandingContainer>
      );
    case 'finished':
      return (
        <LandingContainer noBack={true} heading={ExamFinishedTitle}>
          <LandingFinished />
        </LandingContainer>
      );
    }
  }
);

export default Exam;

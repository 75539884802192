import { makeObservable, observable, action, computed } from 'mobx';
import RootStore from '../RootStore';
import { AdminServiceInstance } from '../../services/Admin';
import { IAmountOfAnswers, IExam, IExaminee, IExamineeUpdate } from '../../types';
import { getAsMilliseconds, isInThePast } from '../../utils/Time';
import { translateText } from '../../utils/i18n';
import { IIndividualScores } from '../../types/';

interface IAdminStore {
  exams: IExam[];
  chosenExamId: string;
  examinees: IExaminee[];
}

interface IError {
  notification?: string;
  message?: string;
}

export class AdminStore implements IAdminStore {
  private rootStore: RootStore;
  exams: IExam[] = [];
  chosenExamId = '';
  examinees: IExaminee[] = [];
  amountsOfAnswers: IAmountOfAnswers[] = [];
  individualScores: IIndividualScores = {name: '', scoredExaminee: null};
  isLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      exams: observable,
      chosenExamId: observable,
      examinees: observable,
      isLoading: observable,
      upcomingExams: computed,
      oldExams: computed,
      defaultExamId: computed,
      chosenExam: computed,
      amountOfExaminees: computed,
      chosenExamIsOld: computed,
      init: action.bound,
      fetchExams: action.bound,
      setChosenExam: action.bound,
      fetchExaminees: action.bound,
      fetchAmountsOfAnswers: action.bound,
      fetchIndividualScores: action.bound,
      individualScores: observable,
      updateExaminee: action.bound,
      clearAdminData: action.bound,
      activateAllExaminees: action.bound
    });
  }

  private compareExams = (a: IExam, b: IExam): number => {
    return getAsMilliseconds(a.examStart) - getAsMilliseconds(b.examStart);
  };

  private get sortedExams(): IExam[] {
    return this.exams.slice().sort(this.compareExams);
  }

  public get upcomingExams(): IExam[] {
    return this.sortedExams.filter((exam) => !isInThePast(exam.examEnd));
  }

  public get oldExams(): IExam[] {
    return this.sortedExams.filter((exam) => isInThePast(exam.examEnd)).reverse();
  }

  public get defaultExamId(): string {
    return this.upcomingExams[0] ? this.upcomingExams[0].id : this.oldExams[0] ? this.oldExams[0].id : '';
  }

  public get chosenExam(): IExam | undefined {
    return this.exams.find((exam) => exam.id === this.chosenExamId);
  }

  public get amountOfExaminees(): number {
    return this.examinees.length;
  }

  public get chosenExamIsOld(): boolean {
    return this.oldExams.map((exam) => exam.id).includes(this.chosenExamId);
  }

  private noExamChosen(): boolean {
    return this.chosenExamId === '';
  }

  private showError = (error: IError): void => {
    const { notification, message } = error;
    if (notification) {
      this.rootStore.notificationStore.addSnackbar('info', translateText(notification));
    }
    if (message) {
      console.error(message);
    }
  };
  private setLoading = (isLoading: boolean): void => {
    this.isLoading = isLoading;
  };
  public async init(): Promise<void> {
    await this.fetchExams();
    if (this.noExamChosen()) {
      this.chosenExamId = this.defaultExamId;
    }
    this.fetchExaminees();
    this.fetchAmountsOfAnswers();
  }

  public async fetchExams(): Promise<void> {
    try {
      const exams: IExam[] = await AdminServiceInstance.getExams();
      this.exams = exams;
    } catch (error) {
      this.showError(
        error.response
          ? { message: 'Failed to fetch events from backend.' }
          : { notification: 'pages.admin.errors.noConnection' }
      );
    }
  }

  public setChosenExam(examId: string): void {
    this.chosenExamId = examId;
    this.fetchExaminees();
    this.fetchAmountsOfAnswers();
  }

  public async fetchExaminees(): Promise<void> {
    if (this.noExamChosen()) {
      this.showError({
        notification: 'pages.admin.errors.noExam',
        message: 'No exam chosen.',
      });
      return;
    }
    try {
      const examinees: IExaminee[] = await AdminServiceInstance.getExaminees(this.chosenExamId);
      const sortedExaminees: IExaminee[] = examinees
        .filter((examinee) => !examinee.canceled)
        .sort((a, b) => {
          if (a.lastName === b.lastName) {
            // First name is only important when last names are the same
            return a.firstName.localeCompare(b.firstName);
          }
          return a.lastName.localeCompare(b.lastName);
        });
      this.examinees = sortedExaminees;
    } catch (error) {
      this.showError(
        error.response
          ? { message: 'Failed to fetch examinees from backend.' }
          : { notification: 'pages.admin.errors.noConnection' }
      );
    }
  }

  public async fetchAmountsOfAnswers(): Promise<void> {
    if (this.noExamChosen()) {
      this.showError({
        notification: 'pages.admin.errors.noExam',
        message: 'No exam chosen.',
      });
      return;
    }
    try {
      const amountsOfAnswers = await AdminServiceInstance.getAmountsOfAnswers(this.chosenExamId);
      this.amountsOfAnswers = amountsOfAnswers;
    } catch (error) {
      this.showError(
        error.response
          ? { message: 'Failed to fetch amounts of answers from backend.' }
          : { notification: 'pages.admin.errors.noConnection' }
      );
    }
  }

  public async updateExaminee(examineeId: string, field: string,
    value: string | boolean | number | null): Promise<void> {
    if (this.noExamChosen()) {
      this.showError({
        notification: 'pages.admin.errors.noExam',
        message: 'Exam could not be found.',
      });
      return;
    }

    const update: IExamineeUpdate = { [field]: value };
    try {
      await AdminServiceInstance.updateExaminee(this.chosenExamId, examineeId, update);

      const updatedExaminees: IExaminee[] = this.examinees.map((examinee) =>
        examinee.id === examineeId ? { ...examinee, [field]: value } : examinee
      );
      this.examinees = updatedExaminees;
    } catch (error) {
      this.showError(
        error.response
          ? {
            notification: 'pages.admin.errors.updateFailed',
            message: 'Failed to update examinee.',
          }
          : { notification: 'pages.admin.errors.noConnection' }
      );
    }
  }

  private getUrlParams = (): any => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return {
      'examineeId': urlParams.get('examineeId'),
      'examId': urlParams.get('examId'),
      'lang': urlParams.get('lang')
    };
  };

  public async fetchIndividualScores(): Promise<void>{

    const examineeId = this.getUrlParams().examineeId;
    const examId = this.getUrlParams().examId;
    const lang = this.getUrlParams().lang;

    try {
      const individualScores = await AdminServiceInstance.fetchIndividualScores(examId, examineeId, lang);
      this.individualScores = individualScores;
    } catch (error) {
      this.showError(
        error.response
          ? {
            notification: 'fetchFailed',
            message: 'Failed to fetch scores.',
          }
          : { notification: 'fetchFailed' }
      );
    }
  }

  private async activateExaminees(examId: string, examineesToActivate: IExaminee[]): Promise<void>
  {
    for (const examinee of examineesToActivate){
      if(!examinee.checkedIn && examinee.examStatus === 'not-started' && !examinee.examHasEnded)
      {
        const update: IExamineeUpdate = { checkedIn: true };
        await AdminServiceInstance.updateExaminee(examId, examinee.id, update);
      }
    }
  }

  public async activateAllExaminees(): Promise<void> {
    if (this.noExamChosen()) {
      this.showError({
        notification: 'pages.admin.errors.noExam',
        message: 'No exam chosen.',
      });
      return;
    }
    try {
      this.setLoading(true);
      //Update the examinees
      await this.activateExaminees(this.chosenExamId, this.examinees);
      //Fetch updated values
      await this.fetchExaminees();
      
      this.setLoading(false);
    } catch (error) {
      this.showError(
        error.response
          ? { message: 'Failed to activate all the examinees.' }
          : { notification: 'pages.admin.errors.noConnection' }
      );
      this.setLoading(false);
    }
  }

  public clearAdminData(): void {
    this.chosenExamId = '';
    this.exams = [];
    this.examinees = [];
    this.amountsOfAnswers = [];
  }
}

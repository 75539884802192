import axios from 'axios';
import {
  IExam,
  IExaminee,
  IExamineeUpdate,
  IPreviewQuery,
  IExamData,
  IResponseData,
  IPreviewResponseData,
  IAmountOfAnswers,
  IIndividualScores
} from '../types';
import { IPage } from '../stores/ExamStore/Model';
import { deserializePages, deserializeInstruction } from './Deserialize';

interface IAdminService {
  getExams: () => Promise<Array<IExam>>;
  getExaminees: (examId: string) => Promise<Array<IExaminee>>;
  getAmountsOfAnswers: (examId: string) => Promise<Array<IAmountOfAnswers>>;
  updateExaminee: (examId: string, examineeId: string, update: IExamineeUpdate) => void;
  fetchPreview: (query: IPreviewQuery) => Promise<IExamData>;
  fetchIndividualScores: (examId: string, examineeId: string, lang: string) => Promise<void>;
}

class AdminService implements IAdminService {
  async getExams(): Promise<Array<IExam>> {
    const response = await axios({ method: 'get', url: '/api/admin/exams' }) as any;
    return response.data;
  }

  async getExaminees(examId: string): Promise<Array<IExaminee>> {
    const response = await axios({
      method: 'get',
      url: `/api/admin/exams/${examId}/examinees`,
    }) as any;
    return response.data;
  }


  async getAmountsOfAnswers(examId: string): Promise<Array<IAmountOfAnswers>> {
    const response = await axios({
      method: 'get',
      url: `/api/admin/exams/${examId}/examinees/answered`,
    }) as any;
    return response.data;
  }

  async updateExaminee(examId: string, examineeId: string, update: IExamineeUpdate): Promise<void> {
    await axios({
      method: 'put',
      url: `/api/admin/exams/${examId}/examinees/${examineeId}`,
      headers: { contentType: 'application/json' },
      data: update,
    });
  }

  public async fetchPreview(query: IPreviewQuery): Promise<IPreviewResponseData> {
    const response = await axios.get('/api/admin/preview', {
      params: { lang: query.lang, surveyId: query.surveyId },
    });
    const { disclaimer, instructions, exam } = response.data;
    const _disclaimer = deserializeInstruction(disclaimer);
    const _instructions = deserializeInstruction(instructions);
    const { title, pages }: IResponseData = exam;
    const parsedPages: IPage[] = deserializePages(pages);
    return {
      title,
      pages: parsedPages,
      instructions: _instructions,
      disclaimer: _disclaimer,
    };
    
  }

  async fetchIndividualScores(examId: string, examineeId: string | null, lang: string): Promise<any> {
    const response = await axios({
      method: 'get',
      url: `/api/admin/exams/${examId}/scoring/${examineeId}`,
      params: {
        lang: lang
      }
    });
    return response.data;
  }
}

export const AdminServiceInstance = new AdminService();


import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';

import Paper from '@material-ui/core/Paper';

interface Props {
  children?: ReactNode;
}

const BannerBox = styled(Paper)`
	position: sticky;
	top: 0;
	padding: 1rem;
  z-index: 1;
`;

export const Banner: FC<Props> = ({ children }: Props): ReactElement => {
  return <BannerBox elevation={1} square={true}>
    {children}
  </BannerBox>;
};
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Typography } from '@material-ui/core';

import { useStores } from '../../hooks';

const ExamPagesWrapper = styled(Typography)`
  color: inherit;
  font-size: inherit;
`;

const ExamPageCount: FC = observer(
  (): ReactElement => {
    const { examStore } = useStores();
    const { t } = useTranslation();
    const instructionsPageTitle = t('common.instructions.label');
    const returnPageTitle = t('pages.exam.end.title');
    const ExamPageText = `${t('pages.exam.footer.pageText')} ${examStore.currentPageNumber}/${
      examStore.totalPagesCount
    }`;

    if (examStore.selectedPage === examStore.instructionsPageId) {
      return <ExamPagesWrapper>{instructionsPageTitle}</ExamPagesWrapper>;
    }
    if (examStore.selectedPage === examStore.returnPageId) {
      return <ExamPagesWrapper>{returnPageTitle}</ExamPagesWrapper>;
    }
    return <ExamPagesWrapper>{ExamPageText}</ExamPagesWrapper>;
  }
);

export default ExamPageCount;

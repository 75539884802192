import React, { FC, ReactElement, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../hooks';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { InputField, ButtonAdornment } from './components';

interface Props {
  password: string;
  setPassword: (value: string) => void;
}

const PasswordField: FC<Props> = observer(
  ({ password, setPassword }: Props): ReactElement => {
    const { uiStore } = useStores();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const type = showPassword ? 'text' : 'password';
    const error = uiStore.currentState === 'authenticating-error';
    const errorText = t('pages.landing.login.inputs.password.errorText') as string;
    const description = t('pages.landing.login.inputs.password.description') as string;
    const label = t('pages.landing.login.inputs.password.label') as string;
    const showPasswordLabel = t('pages.landing.login.inputs.password.showPassword') as string;
    const hidePasswordLabel = t('pages.landing.login.inputs.password.hidePassword') as string;

    const SecretAdornment: FC = (): ReactElement => (
      <ButtonAdornment
        description={showPassword ? hidePasswordLabel : showPasswordLabel}
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </ButtonAdornment>
    );
    return (
      <InputField
        onChange={setPassword}
        type={type}
        label={label}
        description={description}
        value={password}
        error={error}
        errorText={errorText}
        endAdornment={<SecretAdornment />}
      />
    );
  }
);

export default PasswordField;

import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import Divider from '@material-ui/core/Divider';
import { Box } from '@material-ui/core';

const IconDividerContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-align: initial;
  overflow-x: hidden;
  width: 100%;
`;

const IconWrapper = styled.div`
  margin-right: 0.5rem;
`;

const Line = styled(Divider)`
  ${({ theme }) => `
		background-color: ${theme.palette.light.main};
		height: 1px;
		width: 100%;
	`}
`;

const IconContainerItems = styled.div`
  ${({ theme }) => `
    padding-top: 1rem;
    border-bottom: 1px solid ${theme.palette.light.main};
    padding-bottom: 1.3rem;
  `}
`;

interface IconDividerProps {
  children?: ReactNode;
}

const IconDivider: FC<IconDividerProps> = ({ children }: IconDividerProps): ReactElement => {
  return (
    <IconDividerContainer tabIndex={-1}>
      <IconWrapper>{children}</IconWrapper>
      <Line orientation="vertical" />
    </IconDividerContainer>
  );
};

interface IconContainerProps {
  icon: ReactElement;
  children?: ReactNode;
}

export const IconContainer: FC<IconContainerProps> = ({ children, icon }: IconContainerProps): ReactElement => {
  return (
    <Box>
      <IconDivider>{icon}</IconDivider>
      <IconContainerItems>{children}</IconContainerItems>
    </Box>
  );
};

export default IconDivider;

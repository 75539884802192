import React, { FC, ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useStores } from '../../hooks';
import { observer } from 'mobx-react-lite';
import Examinee from './Examinee';
import { IAmountOfAnswers } from '../../types';
import { useTranslation } from 'react-i18next';

const ExamineeTable: FC = observer((): ReactElement => {
  const { adminStore } = useStores();
  const { t } = useTranslation();
  const { examinees, amountsOfAnswers } = adminStore;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('pages.admin.examineeTable.name')}</TableCell>
            <TableCell>{t('pages.admin.examineeTable.examineePassword')}</TableCell>
            <TableCell>{t('pages.admin.examineeTable.checkedIn')}</TableCell>
            <TableCell>{t('pages.admin.examineeTable.extraTime')}</TableCell>
            <TableCell>{t('pages.admin.examineeTable.questionsAnswered')}</TableCell>
            <TableCell>{t('pages.admin.examineeTable.lastSaved')}</TableCell>
            <TableCell>{t('pages.admin.examineeTable.examStatus')}</TableCell>
            <TableCell>{t('pages.admin.examineeTable.savePDF')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {examinees.map((examinee) => {
            const amountOfAnswers: IAmountOfAnswers | undefined = amountsOfAnswers.find(
              (amount) => amount.id === examinee.id
            );
            examinee.answeredQuestions = amountOfAnswers ? amountOfAnswers.answeredQuestions : '-';
            return <Examinee key={examinee.id} {...examinee} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default ExamineeTable;

import { UserStore } from './UserStore';
import { UIStore } from './UIStore';
import { ExamStore } from './ExamStore';
import { AdminStore } from './AdminStore';
import { NotificationStore } from './NotificationStore';

class RootStore {
  uiStore: UIStore;
  userStore: UserStore;
  examStore: ExamStore;
  adminStore: AdminStore;
  notificationStore: NotificationStore;

  constructor() {
    this.uiStore = new UIStore(this);
    this.userStore = new UserStore(this);
    this.examStore = new ExamStore(this);
    this.adminStore = new AdminStore(this);
    this.notificationStore = new NotificationStore(this);
  }

  public resetExam(): void {
    this.examStore = new ExamStore(this);
  }
}

export default RootStore;

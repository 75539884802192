import React, { ChangeEvent, ChangeEventHandler, FC, ReactElement, useState } from 'react';
import styled from 'styled-components/macro';

import FormControl from '@material-ui/core/FormControl';
import { InputLabel, OutlinedInput } from '@material-ui/core';
import { TextCount } from './components';
import uniqueId from 'lodash.uniqueid';

const Label = styled(InputLabel)`
  ${({ theme }) => `
    color: ${theme.palette.gray.main};
  `}
`;

const InputField = styled(OutlinedInput)`
  ${({ theme }) => `
    border-radius: 0;
    fieldset {
      border-color: ${theme.palette.gray.main};
    }
  `}
`;

interface Props {
  value?: string;
  label?: string;
  description?: string; //For accessability
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  id: string; //For accessability to identify the element's associated helpertext and labels
  rows?: number;
  maxLength?: number;
  setText: (value: string) => void;
  disabled?: boolean;
}

const TextInput: FC<Props> = ({
  value = '',
  label = '',
  description = '',
  id,
  setText,
  maxLength = 100,
  disabled = false,
}: Props): ReactElement => {
  const [valueLength, updateValueLength] = useState(value ? value.length : 0);
  const labelId = uniqueId('label_');
  const update = (value: string) => {
    updateValueLength(value ? value.length : 0);
    setText(value);
  };

  return (
    <FormControl fullWidth variant={'outlined'}>
      <Label htmlFor={labelId} shrink={false}>
        {valueLength === 0 ? label : ''}
      </Label>
      <InputField
        id={id}
        disabled={disabled}
        inputProps={{ 'aria-label': description, maxLength: maxLength }}
        type={'text'}
        defaultValue={value}
        onChange={(event) => update(event.target.value)}
      />
      {maxLength && <TextCount current={valueLength} max={maxLength} />}
    </FormControl>
  );
};

export default TextInput;

import React, { FC, ReactElement, ReactNode } from 'react';
import { useStores } from '../../hooks';
import { ExamHeader } from '../Header';
import { ExamDrawer } from '../Drawer';
import { ExamFooter } from '../Footer';
import Container from './Container';

interface Props {
  children?: ReactNode;
}

const ExamLayout: FC<Props> = ({ children }: Props): ReactElement => {
  const { uiStore } = useStores();
  uiStore.setDrawerWidth('18rem');
  const Body: FC = (): ReactElement => <>{children}</>;
  return <Container drawer={<ExamDrawer />} header={<ExamHeader />} body={<Body />} footer={<ExamFooter />} />;
};

export default ExamLayout;

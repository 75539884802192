import moment from 'moment';
const minute = 60000; //in milliseconds
const hour = 3600000; //in milliseconds

// Accepts time in format hh:mm
export const calculateMilliseconds = (time: string): number => {
  const timePart = time.split(':');
  const hours = timePart[0];
  const minutes = timePart[1] || 0;
  return +hours * hour + +minutes * minute;
};

// Accepts timestamp in ISO 8061 format
export const getDateString = (timestamp: string): string => {
  const date: Date = new Date(timestamp);
  const day: number = date.getDate();
  const month: number = date.getMonth() + 1;
  const year: number = date.getFullYear();
  return `${day}.${month}.${year}`;
};

// Accepts timestamp in ISO 8061 format
export const getTimeString = (timestamp: string): string => {
  const date = new Date(timestamp);
  const hours: number = date.getHours();
  const minutes: string = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
  return `${hours}:${minutes}`;
};

// Accepts timestamps in ISO 8061 format
export const getTimeSpan = (startTimestamp: string, endTimestamp: string): string => {
  const startTime: string = getTimeString(startTimestamp);
  const endTime: string = getTimeString(endTimestamp);
  return `${startTime} - ${endTime}`;
};

// Accepts timestamp in ISO 8061 format
export const getAsMilliseconds = (timestamp: string): number => {
  const date = new Date(timestamp);
  return date.getTime();
};

// Accepts timestamps in ISO 8061 format
export const getDuration = (startTimestamp: string, endTimestamp: string): string => {
  const startTime: number = getAsMilliseconds(startTimestamp);
  const endTime: number = getAsMilliseconds(endTimestamp);
  const difference: number = Math.abs(startTime - endTime);
  const hours: number = Math.floor((difference / hour) % 24);
  const minutes: number = Math.floor((difference / minute) % 60);
  const hourString: string = hours + 'h';
  const minuteString: string = minutes ? ` ${minutes}min` : '';
  return hourString + minuteString;
};

// Accepts timestamp in ISO 8061 format
export const isInThePast = (timestamp: string): boolean => {
  const time: number = getAsMilliseconds(timestamp);
  const startOfToday: number = moment().startOf('day').valueOf();
  return time < startOfToday;
};

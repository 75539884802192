import DOMPurify from 'dompurify';
import {
  IPage,
  ISingleChoice,
  IMultiChoice,
  IMultiText,
  IText,
  IChoice,
  IRow,
  TQuestionTypes,
  IMultiTextAnswer,
  IInstruction,
} from '../stores/ExamStore/Model';

export const deserializeInstruction = ({ title, text }: IInstruction): IInstruction => {
  const sanitizedTitle = DOMPurify.sanitize(title);
  const sanitizedText = DOMPurify.sanitize(text);
  return { title: sanitizedTitle, text: sanitizedText };
};

export const deserializePages = (pages: any[]): IPage[] => {
  const _pages = pages.reduce((totalPages, currentPage) => {
    const type: IPage['type'] = 'task';
    const title: IPage['title'] = DOMPurify.sanitize(currentPage.title);
    const description: IPage['description'] = DOMPurify.sanitize(currentPage.description);
    const questions: IPage['questions'] = deserializeQuestions(currentPage.questions);
    const page: IPage = generatePage(type, currentPage.id, title, description, questions);
    return [...totalPages, page];
  }, []);
  return _pages;
};

export const generatePage = (
  type: IPage['type'],
  id: IPage['id'],
  title: IPage['title'],
  description: IPage['description'],
  questions: IPage['questions']
): IPage => {
  return {
    type,
    id,
    title,
    description,
    questions,
  } as IPage;
};

const deserializeQuestions = (questions: any[]): (ISingleChoice | IMultiChoice | IMultiText | IText)[] => {
  return questions.reduce((totalQuestions, currentQuestion) => {
    const id: TQuestionTypes['id'] = currentQuestion.id;
    const type: TQuestionTypes['type'] = currentQuestion.type;
    const heading: TQuestionTypes['heading'] = DOMPurify.sanitize(currentQuestion.heading, { FORBID_TAGS: ['br'] });
    if (type === 'single-choice') {
      const choices = deserializeSingleChoice(currentQuestion.choices, currentQuestion.answer || '');
      const question = {
        id,
        type,
        heading,
        choices,
      } as ISingleChoice;
      return [...totalQuestions, question];
    } else if (type === 'multi-choice') {
      const choices = deserializeMultiChoice(currentQuestion.choices, currentQuestion.answer || []);
      const question = {
        id,
        type,
        heading,
        choices,
      } as IMultiChoice;
      return [...totalQuestions, question];
    } else if (type === 'multi-text') {
      const maxLength: IMultiText['maxLength'] = currentQuestion.maxLength;
      const rows = deserializeRows(currentQuestion.rows, currentQuestion.answer || []);
      const question = {
        id,
        type,
        heading,
        rows,
        maxLength,
      } as IMultiText;
      return [...totalQuestions, question];
    } else if (type === 'text') {
      const answer: IText['answer'] = currentQuestion.answer || '';
      const maxLength: IText['maxLength'] = currentQuestion.maxLength;
      const question = {
        id,
        type,
        heading,
        answer,
        maxLength,
      } as IText;
      return [...totalQuestions, question];
    } else {
      console.log('No such supported question type: ' + currentQuestion.type);
      return totalQuestions;
    }
  }, []);
};

const deserializeSingleChoice = (choices: any[], answer: string): IChoice[] => {
  return choices.reduce((totalChoices, currentChoice) => {
    const id: IChoice['id'] = currentChoice.id;
    const text: IChoice['text'] = currentChoice.text;
    const selected = answer === id;
    const choice = {
      id,
      text,
      selected,
    };
    return [...totalChoices, choice];
  }, []);
};

const deserializeMultiChoice = (choices: any[], answers: string[]): IChoice[] => {
  return choices.reduce((totalChoices, currentChoice) => {
    const id: IChoice['id'] = currentChoice.id;
    const text: IChoice['text'] = currentChoice.text;
    const selected = answers.includes(id);
    const choice = {
      id,
      text,
      selected,
    };
    return [...totalChoices, choice];
  }, []);
};

const deserializeRows = (rows: any[], answers: IMultiTextAnswer[]): IRow[] => {
  return rows.reduce((totalRows, currentRow, index) => {
    const id: IRow['id'] = currentRow.id;
    const text: IRow['text'] = currentRow.text;
    const answer: IRow['answer'] = answers[index] && answers[index].text ? answers[index].text : '';
    const row = {
      id,
      text,
      answer,
    };
    return [...totalRows, row];
  }, []);
};

import React, { FC, ReactElement, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../hooks';

import Grid from '@material-ui/core/Grid';

import ErrorContainer from './Container';
import Modal from '../../Modal';
import { FilledButton, OutlinedButton } from '../../Buttons';

const LoadingError: FC = observer((): ReactElement => {
  const { examStore } = useStores();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const tryAgainButtonDescription = t('pages.landing.submittingError.buttons.tryAgain.description') as string;
  const tryAgainButtonText = t('pages.landing.submittingError.buttons.tryAgain.label') as string;
  const helpButtonDescription = t('pages.landing.common.buttons.help.description') as string;
  const helpButtonText = t('pages.landing.common.buttons.help.label') as string;
  const modalTitleError = t('pages.landing.submittingError.modal.help.title') as string;
  const modalTextError = t('pages.landing.submittingError.modal.help.text') as string;
  const modalButtonTextError = t('pages.landing.submittingError.modal.help.button') as string;

  return <ErrorContainer>
    <Modal 
      title={modalTitleError} 
      text={modalTextError} 
      buttonText={modalButtonTextError} 
      open={modalOpen} onClose={() => setModalOpen(false)} 
    />
    <Grid container spacing={3} direction='column' alignItems='stretch'>
      <Grid item>
        <FilledButton 
          text={tryAgainButtonText} 
          description={tryAgainButtonDescription} 
          handleClick={examStore.submitExam} 
        />
      </Grid>
      <Grid item>
        <OutlinedButton 
          text={helpButtonText} 
          description={helpButtonDescription} 
          handleClick={() => setModalOpen(true)} 
        />
      </Grid>
    </Grid>
  </ErrorContainer>;
});

export default LoadingError;
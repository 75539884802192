import React, { FC, ReactElement, useState } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { useStores } from '../../hooks';
import { FilledButton } from '../Buttons';
import { HelpFilledSmallIcon } from '../Icons';
import { FadeTransition } from '../Transitions';
import { SwitchText } from '../Inputs';
import { IconContainer } from '../IconDivider';

const ActionsWrapper = styled(Grid)`
  width: 21.875rem;
  margin: 0 auto;
`;

const DisclaimerText = styled(Typography)`
  text-align: left;
`;

interface Props {
  onStart: () => void;
}

const LandingDisclaimer: FC<Props> = observer(
  ({ onStart }: Props): ReactElement => {
    const { examStore } = useStores();
    const { t } = useTranslation();
    const disclaimer = examStore.disclaimer.text;
    const accessibleDisclaimer: string = disclaimer.replace(/<[^>]+>/g, '').replace('&nbsp', '');
    const [switchOpen, setSwitchOpen] = useState<boolean>(false);
    const startExamText = t('pages.landing.examDetails.buttons.startExam.label') as string;
    const startExamDescription = t('pages.landing.examDetails.buttons.startExam.description') as string;
    const agreeText = t('pages.landing.examDetails.confirmDetailsText') as string;
    return (
      <FadeTransition>
        <Grid container direction="column" alignItems="stretch" spacing={3}>
          <Grid item>
            <IconContainer icon={<HelpFilledSmallIcon />}>
              <DisclaimerText
                dangerouslySetInnerHTML={{ __html: disclaimer }}
                aria-label={accessibleDisclaimer}
                tabIndex={0}
              />
            </IconContainer>
          </Grid>
          <ActionsWrapper item>
            <SwitchText onChange={setSwitchOpen} text={agreeText} checked={switchOpen} />
            <FilledButton
              elevation={true}
              text={startExamText}
              description={startExamDescription}
              disabled={!switchOpen}
              handleClick={onStart}
            />
          </ActionsWrapper>
        </Grid>
      </FadeTransition>
    );
  }
);

export default LandingDisclaimer;

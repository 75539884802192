import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { NavigationButton } from './Navigation';
import { ArrowBackIcon } from '../Icons';

const ArrowText = styled(Typography)`
  font-weight: bold;
`;

interface ButtonWrapperProps {
  color?: string;
  uppercase?: boolean;
}

const ButtonWrapper = styled(Grid)<ButtonWrapperProps>`
  ${({ theme, color, uppercase }) => `
    color: ${color ? color : theme.palette.light.main};
    ${uppercase ? 'text-transform: uppercase;' : ''}
  `}
`;

interface BackButtonProps {
  callback: () => void;
  text: string;
  description: string;
  lightBackground: boolean;
  color?: string;
  uppercase?: boolean;
}

export const BackButton: FC<BackButtonProps> = ({
  callback,
  text,
  description,
  lightBackground,
  color,
  uppercase = false,
}: BackButtonProps): ReactElement => {
  return (
    <NavigationButton description={description} lightBackground={lightBackground} handleClick={() => callback()}>
      <ButtonWrapper
        uppercase={uppercase}
        color={color}
        container
        direction="row"
        item
        justify="space-between"
        alignItems="center"
        xs={'auto'}
      >
        <Grid item xs={'auto'}>
          <ArrowBackIcon />
        </Grid>
        <Grid item xs={'auto'}>
          <ArrowText>{text}</ArrowText>
        </Grid>
      </ButtonWrapper>
    </NavigationButton>
  );
};

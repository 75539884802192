import { makeObservable, observable, action, reaction } from 'mobx';
import RootStore from '../RootStore';
import i18n from '../../utils/i18n';
import { locales, language } from '../../utils/i18n';
import { TLanguage } from '../../types';
import history from '../../utils/History';
import { ViewState } from './Model';

interface IUIStore {
  currentState: ViewState;
  isDrawerOpen: boolean;
  languages: TLanguage[];
  selectedLanguage: TLanguage;
  drawerWidth: string | number;
  pageFadeDuration: number;
}

export class UIStore implements IUIStore {
  private rootStore: RootStore;
  currentState: ViewState = 'initial';
  isDrawerOpen = true;
  languages = locales;
  selectedLanguage = language;
  drawerWidth: string | number = '16rem';
  pageFadeDuration = 500;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      drawerWidth: observable,
      currentState: observable,
      selectedLanguage: observable,
      isDrawerOpen: observable,
      setDrawerWidth: action.bound,
      changeLanguage: action.bound,
      toggleDrawer: action.bound,
      setViewState: action.bound,
    });

    reaction(
      () => this.selectedLanguage,
      async (locale) => {
        localStorage.setItem('language', locale);
        i18n.changeLanguage(locale);
        this.rootStore.examStore.reloadExam();
      }
    );
  }

  public setDrawerWidth(width: string | number): void {
    this.drawerWidth = width;
  }

  public changeLanguage(language: TLanguage): void {
    if (language !== this.selectedLanguage) {
      this.selectedLanguage = this.languages.indexOf(language) > -1 ? language : this.languages[0];
    }
  }

  public toggleDrawer(): void {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  public navigateToExam(): void {
    history.push('/');
  }

  private navigateToLogin(): void {
    history.push('/login');
  }

  public setViewState(state: ViewState): void {
    this.currentState = state;
    switch (state) {
    case 'authenticating-error':
    case 'initial':
      this.navigateToLogin();
      break;
    case 'loading-success':
      setTimeout(() => this.setViewState('started'), this.pageFadeDuration + 2000);
      break;
    case 'submitting-success':
      setTimeout(() => this.setViewState('finished'), this.pageFadeDuration + 2000);
      break;
    }
  }
}

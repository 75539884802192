import { makeObservable, observable, action, computed, reaction } from 'mobx';
import uniqueId from 'lodash.uniqueid';

import RootStore from '../RootStore';
import { ExamServiceInstance, AdminServiceInstance } from '../../services';
import { TimerStore } from '../TimerStore';
import { second } from '../TimerStore/types';
import {
  IPage,
  ICurrentPage,
  IText,
  ISingleChoice,
  IMultiChoice,
  IMultiText,
  TQuestionTypes,
  IChoice,
  IMultiTextAnswer,
  ITextAnswer,
  ISingleChoiceAnswer,
  IMultiChoiceAnswer,
  IMultirowChoiceAnswer,
  IExamAnswer,
  TSaveState,
  ITaskStatus,
  IInstruction,
} from './Model';
import { ViewState } from '../UIStore/Model';
import { translateText } from '../../utils/i18n';
import { IUser } from '../UserStore/types';
import moment from 'moment';
const emptyInstruction = { title: '', text: '' } as IInstruction;

interface IExamStore {
  title: string;
  pages: IPage[];
  selectedPage: string | undefined;
  disclaimer: IInstruction;
  instructions: IInstruction;
  saveState: TSaveState;
  stopped: boolean;
  submitted: boolean;
  setCurrentPage(id: string): void;
  setNextPage(): void;
  setPreviousPage(): void;
  setInstructionsPage(): void;
  setReturnPage(): void;
  setFirstPage(): void;
  setLastPage(): void;
  loadExam(): Promise<void>;
  prepareExam(): Promise<void>;
  startExam(): Promise<void>;
  reloadExam(): Promise<void>;
  stopExam(): Promise<void>;
  startPreview(surveyId: string): Promise<void>;
  setTextAnswer({ pageId, questionId, answer }: ITextAnswer): Promise<void>;
  setSingleChoiceAnswer({ pageId, questionId, choiceId }: ISingleChoiceAnswer): Promise<void>;
  setMultiChoiceAnswer({ pageId, questionId, choiceId }: IMultiChoiceAnswer): Promise<void>;
  setMultiRowAnswer({ pageId, questionId, rowId, answer }: IMultirowChoiceAnswer): Promise<void>;
  saveAnswer({ pageId, questionId, answer }: IExamAnswer): Promise<void>;
}

export class ExamStore implements IExamStore {
  private rootStore: RootStore;
  setViewState: (state: ViewState) => void;
  logout: () => void;
  title = '';
  pages: IPage[] = [];
  selectedPage: string | undefined = undefined;
  disclaimer = emptyInstruction;
  instructions = emptyInstruction;
  saveState: TSaveState = 'initial';
  private TimerStore = new TimerStore();
  minimumExamTime = second * 60 * 60; //hour in millieconds
  stopped = false;
  submitted = false;
  instructionsPageId: string = uniqueId('instructionsPage-');
  returnPageId: string = uniqueId('returnPage-');

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.setViewState = this.rootStore.uiStore.setViewState;
    this.logout = this.rootStore.userStore.logout;
    makeObservable(this, {
      title: observable,
      pages: observable,
      selectedPage: observable,
      disclaimer: observable,
      instructions: observable,
      saveState: observable,
      stopped: observable,
      submitted: observable,
      selectedPageIndex: computed,
      examTitle: computed,
      currentPageNumber: computed,
      examUnfinished: computed,
      taskStatus: computed,
      totalPagesCount: computed,
      currentPage: computed,
      timeRemaining: computed,
      tooEarlyToSubmit: computed,
      isInstructionsPage: computed,
      isReturnPage: computed,
      isFirstPage: computed,
      isLastPage: computed,
      setCurrentPage: action.bound,
      setNextPage: action.bound,
      setPreviousPage: action.bound,
      setInstructionsPage: action.bound,
      setReturnPage: action.bound,
      setFirstPage: action.bound,
      setLastPage: action.bound,
      loadInstructions: action.bound,
      loadExam: action.bound,
      prepareExam: action.bound,
      startExam: action.bound,
      reloadExam: action.bound,
      stopExam: action.bound,
      startPreview: action.bound,
      setTextAnswer: action.bound,
      setMultiChoiceAnswer: action.bound,
      setMultiRowAnswer: action.bound,
      saveAnswer: action.bound,
      endExam: action.bound,
      submitExam: action.bound,
    });

    reaction(
      () => this.TimerStore.getMinutesRemaining,
      (minutesRemaining: number) => {
        if (minutesRemaining === 15) {
          const text = translateText('pages.exam.common.snackbars.examEndsInMinutes.text', { minutes: '15' });
          this.rootStore.notificationStore.addSnackbar('info', text);
        }
        if (minutesRemaining === 5) {
          const text = translateText('pages.exam.common.banners.examEndsInMinutes.text');
          this.rootStore.notificationStore.addBanner('timeout', text);
        }
      }
    );

    reaction(
      () => this.TimerStore.percentage,
      (percentage: number) => {
        if (percentage > 100) {
          console.log('Stopping timer');
          this.stopExam();
        }
      }
    );
  }

  public get isInstructionsPage(): boolean {
    return this.selectedPage === this.instructionsPageId;
  }

  public get isReturnPage(): boolean {
    return this.selectedPage === this.returnPageId;
  }

  public get isFirstPage(): boolean {
    const firstPage = this.pages[0];
    if (!firstPage) {
      return false;
    }
    return this.selectedPage === firstPage.id;
  }

  public get isLastPage(): boolean {
    const lastPage = this.pages[this.pages.length - 1];
    if (!lastPage) {
      return false;
    }
    return this.selectedPage === lastPage.id;
  }

  public get tooEarlyToSubmit(): boolean {
    const user: IUser | undefined = this.rootStore.userStore.user;
    if (!user) {
      throw new Error('Was not able to get user data.');
    }
    const startTime: moment.Moment = moment(user.examStart);
    const endTime: moment.Moment = moment(user.examEnd);
    const examDuration = endTime.diff(startTime);
    if (examDuration < this.minimumExamTime) {
      // The exam is a test and can be submitted immediately
      return false;
    }
    const currentTime: moment.Moment = moment();
    const elapsedTime: number = currentTime.diff(startTime);
    return elapsedTime < this.minimumExamTime;
  }

  public get timeRemaining(): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    percentage: number;
    } {
    const days = this.TimerStore.timeRemaining.days;
    const hours = this.TimerStore.timeRemaining.hours;
    const minutes = this.TimerStore.timeRemaining.minutes;
    const seconds = this.TimerStore.timeRemaining.seconds;
    const percentage = this.TimerStore.percentage;
    return { days, hours, minutes, seconds, percentage };
  }

  public get selectedPageIndex(): number {
    return this.pages.findIndex((page) => page.id === this.selectedPage);
  }

  public get currentPageNumber(): number {
    const index = this.pages.findIndex((page) => page.id === this.selectedPage);
    return index > -1 ? index + 1 : 0;
  }

  public get examUnfinished(): boolean {
    for (const page of this.pages) {
      if (!this.calculateAnswerStatus(page.questions)) {
        return true;
      }
    }
    return false;
  }

  public get taskStatus(): ITaskStatus[] {
    return this.pages.reduce((pages: ITaskStatus[], page: IPage) => {
      const answered = this.calculateAnswerStatus(page.questions);
      const task = { title: page.title, id: page.id, answered };

      return [...pages, task];
    }, []);
  }

  private calculateAnswerStatus(questions: TQuestionTypes[]): boolean {
    let answerCount = 0;

    questions.forEach((question: TQuestionTypes) => {
      if (question.type === 'text') {
        if (question.answer.length > 0) {
          answerCount += 1;
        }
      } else if (question.type === 'single-choice') {
        const answeredChoices = question.choices.filter((choice) => choice.selected);
        if (answeredChoices.length > 0) {
          answerCount += 1;
        }
      } else if (question.type === 'multi-choice') {
        const answeredChoices = question.choices.filter((choice) => choice.selected);
        if (answeredChoices.length > 0) {
          answerCount += 1;
        }
      } else if (question.type === 'multi-text') {
        const answeredRows = question.rows.filter((row) => row.answer.length > 0);
        if (answeredRows.length === question.rows.length) {
          answerCount += 1;
        }
      } else {
        console.log('Invalid question type detected ');
      }
    });
    return answerCount === questions.length ? true : false;
  }

  public get totalPagesCount(): number {
    return this.pages ? this.pages.length : 0;
  }

  public get examTitle(): string {
    return this.title ? this.title : 'N/A';
  }

  public setFirstPage(): void {
    const firstPage = !this.pages.length ? this.instructionsPageId : this.pages[0].id;
    this.setCurrentPage(firstPage);
  }

  public setLastPage(): void {
    const lastPage = !this.pages.length ? this.instructionsPageId : this.pages[this.pages.length - 1].id;
    this.setCurrentPage(lastPage);
  }

  public setInstructionsPage(): void {
    this.setCurrentPage(this.instructionsPageId);
  }

  public setReturnPage(): void {
    this.setCurrentPage(this.returnPageId);
  }

  public get currentPage(): ICurrentPage | undefined {
    const index = this.pages.findIndex((page) => page.id === this.selectedPage);
    if (index > -1) {
      const page = this.pages[index];
      const answered = this.calculateAnswerStatus(page.questions);
      return { ...page, answered };
    }
    return undefined;
  }

  public setNextPage(): void {
    const index = this.pages.findIndex((page) => page.id === this.selectedPage);
    if (this.pages[index + 1] && this.pages[index + 1].id) {
      this.setCurrentPage(this.pages[index + 1].id);
    } else {
      this.setReturnPage();
    }
  }

  public setPreviousPage(): void {
    const index = this.pages.findIndex((page) => page.id === this.selectedPage);
    if (this.pages[index - 1] && this.pages[index - 1].id) {
      this.setCurrentPage(this.pages[index - 1].id);
    } else {
      this.setInstructionsPage();
    }
  }

  public setCurrentPage(id: string): void {
    if (id === this.instructionsPageId) {
      this.selectedPage = this.instructionsPageId;
    } else if (id === this.returnPageId) {
      this.selectedPage = this.returnPageId;
    } else {
      const page = this.pages.find((page) => id === page.id);
      this.selectedPage = page && page.id ? page.id : this.instructionsPageId;
    }
    document.getElementById('examHeader')?.scrollIntoView();
    document.getElementById('examTitle')?.focus();
  }

  public async prepareExam(): Promise<void> {
    try {
      this.setViewState('loading');
      await this.loadInstructions();
      if (this.rootStore.userStore.examStarted) {
        this.startExam();
      } else {
        setTimeout(() => {
          this.setViewState('user-info');
        }, 1000);
      }
    } catch (error) {
      console.log('Error when preparing the exam');
      this.handleExamError(error);
    }
  }

  public async startExam(): Promise<void> {
    try {
      this.setViewState('loading');
      await this.loadExam();
      await this.rootStore.userStore.getUser();
      console.log('Exam was loaded successfully');
      const user = this.rootStore.userStore.user;
      if (!user || !user.start || !user.deadline) {
        throw new Error('Was not able to get user data.');
      }
      setTimeout(() => {
        this.setViewState('loading-success');
      }, 1000);
      this.TimerStore.startTimer(user.start, user.deadline);
    } catch (error) {
      console.log('Error when trying to start the exam.', error);
      this.handleExamError(error);
    }
  }

  public async reloadExam(): Promise<void> {
    try {
      if (this.rootStore.userStore.isAdmin) {
        const surveyId = this.getSurveyId();
        if (surveyId) {
          this.loadPreview(surveyId);
        }
      } else {
        await this.loadInstructions();
        await this.loadExam();
      }
    } catch (error) {
      console.log('Error when reloading the exam');
      this.handleExamError(error);
    }
  }

  public async loadInstructions(): Promise<void> {
    const selectedLanguage = this.rootStore.uiStore.selectedLanguage;
    const { title, disclaimer, instructions } = await ExamServiceInstance.fetchInsturctions({ lang: selectedLanguage });
    this.title = title;
    this.disclaimer = disclaimer;
    this.instructions = instructions;
  }

  public async loadExam(): Promise<void> {
    const selectedLanguage = this.rootStore.uiStore.selectedLanguage;
    const { title, pages }: { title: string; pages: IPage[] } = await ExamServiceInstance.fetchExam({
      lang: selectedLanguage,
    });
    this.title = title;
    this.pages = pages;
    if (!this.selectedPage) {
      this.selectedPage = this.pages[0].id;
    }
  }

  public async stopExam(): Promise<void> {
    try {
      this.stopped = true;
      this.TimerStore.stopTimer();
      this.submitExam();
    } catch (error) {
      console.log('Exam could not be stopped');
      this.logout();
    }
  }

  private getSurveyId = (): string | null => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('survey');
  };

  private async loadPreview(surveyId: string) {
    const selectedLanguage = this.rootStore.uiStore.selectedLanguage;
    const {
      title,
      pages,
      instructions,
      disclaimer,
    }: {
      title: string;
      pages: IPage[];
      instructions: IInstruction;
      disclaimer: IInstruction;
    } = await AdminServiceInstance.fetchPreview({
      lang: selectedLanguage,
      surveyId,
    });

    this.title = title;
    this.pages = pages;
    this.instructions = instructions;
    this.disclaimer = disclaimer;
    if (!this.selectedPage) {
      this.selectedPage = this.pages[0].id;
    }
  }

  public async startPreview(): Promise<void> {
    try {
      this.rootStore.uiStore.setViewState('loading');
      const surveyId: string | null = this.getSurveyId();
      if (surveyId) {
        await this.loadPreview(surveyId);
        this.rootStore.uiStore.setViewState('exam-info');
      } else {
        throw new Error('exam surveyid was unavailable');
      }
    } catch (error) {
      console.log('Preview could not be started');
      this.handleExamError(error);
    }
  }

  public endExam(): void {
    if (!this.rootStore.userStore.isAdmin && this.tooEarlyToSubmit) {
      console.log('Too early to submit exam results');
    } else {
      this.setViewState('finish');
    }
  }

  public async submitExam(): Promise<void> {
    try {
      if (this.rootStore.userStore.isAdmin) {
        this.rootStore.userStore.previewLogout();
        return;
      }
      if (this.tooEarlyToSubmit) {
        console.log('Too early to submit answers');
        this.setViewState('submitting-error');
        return;
      }
      if (this.submitted) {
        return;
      }

      this.setViewState('submitting');
      await ExamServiceInstance.submit();
      this.submitted = true;
      console.log('Exam submitted successfully');
      setTimeout(() => {
        this.setViewState('submitting-success');
      }, 1000);
    } catch (error) {
      if (error.response) {
        setTimeout(() => {
          console.log('Error when trying to submit the exam.');
          this.handleExamError(error, true, 'submitting-error');
        }, 1000);
      } else {
        setTimeout(() => {
          console.log('No Internet connection. Trying to submit exam again.');
          this.submitExam();
        }, 1000);
      }
    }
  }

  public async setTextAnswer({ pageId, questionId, answer }: ITextAnswer): Promise<void> {
    const question = this.getQuestion(pageId, questionId) as IText;
    if (question.maxLength && answer.length > question.maxLength) {
      console.log(
        `Error: Tried to save ${question.type} which is longer than allowed length (${
          answer.length + '/' + question.maxLength
        }
        page: ${pageId}, question: ${questionId} )`
      );
      return;
    }
    this.saveAnswer({ pageId, questionId, answer });
    question.answer = answer;
  }

  public async setSingleChoiceAnswer({ pageId, questionId, choiceId }: ISingleChoiceAnswer): Promise<void> {
    const question = this.getQuestion(pageId, questionId) as ISingleChoice;
    const cloned = [...question.choices] as IChoice[];
    const result = cloned.map((choice) => {
      if (choice.id === choiceId) {
        choice.selected = true;
      } else {
        choice.selected = false;
      }
      return choice;
    }, []);
    try {
      this.saveAnswer({ pageId, questionId, answer: choiceId });
      question.choices = result;
    } catch {
      console.log('Error could not save singlechoice selection for question: ', questionId);
    }
  }

  public async setMultiChoiceAnswer({ pageId, questionId, choiceId }: IMultiChoiceAnswer): Promise<void> {
    const question = this.getQuestion(pageId, questionId) as IMultiChoice;
    const selected = [] as IChoice['text'][];
    const cloned = [...question.choices] as IChoice[];
    const result = cloned.map((choice) => {
      if (choice.id === choiceId) {
        choice.selected = !choice.selected;
      }
      if (choice.selected) {
        selected.push(choice.id);
      }
      return choice;
    }, []);
    try {
      this.saveAnswer({ pageId, questionId, answer: selected });
      question.choices = result;
    } catch {
      console.log('Error could not save multichoice selection for question: ', questionId);
    }
  }

  public async setMultiRowAnswer({ pageId, questionId, rowId, answer }: IMultirowChoiceAnswer): Promise<void> {
    const question = this.getQuestion(pageId, questionId) as IMultiText;
    if (question.maxLength && answer.length > question.maxLength) {
      console.log(
        `Error: Tried to save ${question.type} which is longer than allowed length (${
          answer.length + '/' + question.maxLength
        },
        page: ${pageId}, question: ${questionId} ) `
      );
      return;
    }

    const rowsArray = question.rows.reduce((rows: IMultiTextAnswer[], row) => {
      if (row.id === rowId) {
        row.answer = answer;
      }
      const answeredRow = { text: row.answer, id: row.id } as IMultiTextAnswer;
      return [...rows, answeredRow];
    }, []);

    this.saveAnswer({ pageId, questionId, answer: rowsArray });
  }

  public async saveAnswer({ pageId, questionId, answer }: IExamAnswer): Promise<void> {
    try {
      this.saveState = 'saving';
      await ExamServiceInstance.answer(pageId, questionId, answer);
      console.log('Task answer saved successfully');
      setTimeout(() => {
        this.saveState = 'success';
      }, 1000);
    } catch (error) {
      console.log('Task answer could not be saved');
      if (error.response) {
        this.saveState = 'error';
        this.handleExamError(error, false);
        throw error;
      } else {
        this.saveState = 'noConnection';
        setTimeout(() => {
          console.log('No Internet connection. Trying to send answers again.');
          this.saveAnswer({ pageId, questionId, answer });
        }, 2000);
      }
    }
  }

  private handleExamError(error: any, allowGenericErrorState = true, viewState?: ViewState): void {
    const errorStatus = error.response && error.response.status ? error.response.status : 400;
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : 'Unknown error';

    if (errorStatus === 401 && errorMessage === 'Unauthorized') {
      this.rootStore.userStore.logout();
    }
    if (errorStatus === 401 && errorMessage === 'Exam has not started yet') {
      const text = translateText('pages.landing.login.snackbars.examNotStartedYet.text');
      this.rootStore.notificationStore.addSnackbar('info', text);
      this.rootStore.userStore.logout();
      return;
    }
    if (errorStatus === 401 && errorMessage === 'Exam cannot be started anymore') {
      const text = translateText('pages.landing.login.snackbars.examCanNotBeStartedAnymore.text');
      this.rootStore.notificationStore.addSnackbar('info', text);
      this.rootStore.userStore.logout();
      return;
    }
    if (
      errorStatus === 401 &&
      (errorMessage === 'Exam has ended' || errorMessage === 'Answers have already been submitted')
    ) {
      this.setViewState('finished');
      return;
    }
    if (allowGenericErrorState) {
      const state = viewState || 'loading-error';
      this.setViewState(state);
    }
  }

  private getQuestion(pageId: IPage['id'], questionId: TQuestionTypes['id']): TQuestionTypes {
    const pageIndex = this.pages.findIndex((page) => page.id === pageId);
    const page = this.pages[pageIndex] as IPage;
    const questionIndex = page.questions.findIndex((question) => question.id === questionId);
    const question = page.questions[questionIndex] as TQuestionTypes;
    return question;
  }
}

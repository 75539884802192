import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import uniqueId from 'lodash.uniqueid';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledSwitch from './StyledSwitch';

const SwitchWrapper = styled(FormGroup)`
  ${({ theme }) => `
    margin-top: 2rem;
    margin-bottom: 1rem;
    &.focus {
      color: ${theme.palette.primary.dark};
      background: ${theme.palette.primary.dark};
    }
  `}
`;

const SwitchLabel = styled(FormControlLabel)`
  ${({ theme }) => `
      margin: 0 !important;
      justify-content: space-between;
      width: 100%;
      white-space: nowrap;
      &.MuiFormControlLabel-label {
        text-align: left;
      }
      &.Mui-disabled {
        color: inherit !important;
      }
      :focus-visible {
        color: ${theme.palette.primary.dark};
        background: ${theme.palette.primary.dark};
      }
  `}
`;

interface Props {
  onChange: (checked: boolean) => void;
  checked?: boolean;
  text?: string;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  darkMode?: boolean;
}

const SwitchText: FC<Props> = ({
  onChange,
  text = '',
  checked = false,
  labelPlacement = 'start',
}: Props): ReactElement => {
  const id = uniqueId('switch-text-');
  return (
    <SwitchWrapper row>
      <SwitchLabel
        control={
          <StyledSwitch
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
            name={id}
            role="switch"
            aria-checked={checked}
          />
        }
        label={text}
        labelPlacement={labelPlacement}
      />
    </SwitchWrapper>
  );
};

export default SwitchText;

import React, { FC, createContext, ReactNode, ReactElement } from 'react';
import RootStore from '../stores/RootStore';

export const StoreContext = createContext<RootStore>({} as RootStore);

interface Props {
  store: RootStore;
  children: ReactNode;
}

export const StoreProvider: FC<Props> = ({
  children,
  store,
}: Props): ReactElement => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { ReturnIcon } from './Icons';

const ReturnContainer = styled.div`
	color: ${({ theme }) => `${theme.palette.light.main};`}
    display: flex;
	flex-direction: row;
	align-items: center;
  white-space: nowrap;
	svg {
		margin-right: 0.3rem;
	}
`;

const ReturnButtton = styled.button`
  ${({ theme }) => `
    padding: 0;
    color: inherit;
    border: none;
    background-color: inherit;
    cursor: pointer;
    font-size: ${theme.typography.header.fontSize};
    &:hover {
      opacity: 0.8;
    }
  `}
`;

interface Props {
  onClick: () => void;
}
const Return: FC<Props> = ({ onClick = () => '' }: Props): ReactElement => {
  const { t } = useTranslation();
  const label = t('common.return.label');

  return (
    <ReturnContainer>
      <ReturnIcon />
      <ReturnButtton onClick={() => onClick()}>{label}</ReturnButtton>
    </ReturnContainer>
  );
};

export default Return;

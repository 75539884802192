import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { useStores } from '../../hooks';
import Language from '../Language';
import Instructions from '../Instructions';
import Return from '../Return';
import Container from './Container';

const Wrapper = styled(Grid)`
  ${({ theme }) => `
    font-size: ${theme.typography.header.fontSize};
    min-width: 21rem;
	`}
`;

const ExamHeader: FC = observer(
  (): ReactElement => {
    const { uiStore, examStore } = useStores();
    const selectedLanguage = uiStore.selectedLanguage;
    const languages = uiStore.languages;
    const selectedExam = examStore.examTitle;

    return (
      <Container drawer={true}>
        <Wrapper container justify="space-between" alignItems={'center'} wrap={'nowrap'}>
          <Grid container item justify="flex-start">
            <Hidden smDown>
              <Typography id="examHeader" noWrap tabIndex={0}>
                {selectedExam}
              </Typography>
            </Hidden>
          </Grid>
          <Grid container item spacing={1} justify="flex-end" alignItems={'center'} wrap={'nowrap'} xs={9} xl={6}>
            <Grid item>
              <Return onClick={examStore.setReturnPage} />
            </Grid>
            <Grid item>
              <Instructions onClick={examStore.setInstructionsPage} />
            </Grid>
            <Grid item>
              <Language languages={languages} selected={selectedLanguage} onChange={uiStore.changeLanguage} />
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    );
  }
);

export default ExamHeader;

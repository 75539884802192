import React, { FC, ReactElement, useState } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';

import { useStores } from '../../hooks';
import Modal from '../Modal';
import { FilledButton, OutlinedButton } from '../Buttons';
import { AccounFilledSmallIcon } from '../Icons';
import { FadeTransition } from '../Transitions';
import { IconContainer } from '../IconDivider';
import { IUser } from '../../stores/UserStore/types';

const UserDetailsGrid = styled(Grid)`
		padding: 0.8rem 0;
		padding 0 0 1rem 0;
`;

const UserDetailColumn = styled(Grid)`
  padding: 0 0.5rem !important;
`;

const UserDetailsItemLeft = styled(Grid)`
  padding: 0.3rem 0;
  justify-content: flex-end;
`;

const UserDetailsItemRight = styled(Grid)`
  padding: 0.3rem 0;
  justify-content: flex-start;
`;

const Details = styled(Typography)`
  ${({ theme }) => `
		color: ${theme.palette.light.darker};
		font-size: 10px;
		line-height: 1.5rem;
		font-weight: normal;
		vertical-align: middle;
		text-align: initial;
	`}
`;

const Info = styled(Typography)`
  font-weight: bold;
  text-align: initial;
`;

const LandingUserDetails: FC = observer(
  (): ReactElement => {
    const { examStore, userStore, uiStore } = useStores();
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const correctInformationText = t('pages.landing.userDetails.buttons.correctInformation.label') as string;
    const correctInformationDescription = t(
      'pages.landing.userDetails.buttons.correctInformation.description'
    ) as string;
    const helpButtonText = t('pages.landing.userDetails.buttons.help.label') as string;
    const helpButtonDescription = t('pages.landing.userDetails.buttons.help.description') as string;
    const modalTitleError = t('pages.landing.common.modal.help.title') as string;
    const modalTextError = t('pages.landing.common.modal.help.text') as string;
    const modalButtonTextError = t('pages.landing.common.modal.help.button') as string;
    const name = t('pages.landing.userDetails.name') as string;
    const email = t('pages.landing.userDetails.email') as string;
    const exam = t('pages.landing.userDetails.exam') as string;
    const user: IUser | undefined = userStore.user;
    const userName = user ? `${user.firstName} ${user.lastName}` : 'N/A';
    const userEmail = user ? user.email : 'N/A';
    const selectedExam = examStore.examTitle;

    const nameLabel = `${name}: ${userName}`;
    const emailLabel = `${email}: ${userEmail}`;
    const examLabel = `${exam}: ${selectedExam}`;

    return (
      <Box>
        <Modal
          title={modalTitleError}
          text={modalTextError}
          buttonText={modalButtonTextError}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
        <FadeTransition>
          <Grid container spacing={10} direction="column" alignItems="stretch">
            <Grid item>
              <IconContainer icon={<AccounFilledSmallIcon />}>
                <UserDetailsGrid container item spacing={3} direction="row" wrap="nowrap">
                  <UserDetailColumn item xs={3} container direction="column">
                    <UserDetailsItemLeft container direction="row" item xs={'auto'}>
                      <Details aria-hidden={true}>{name.toUpperCase()}</Details>
                    </UserDetailsItemLeft>
                    <UserDetailsItemLeft container direction="row" item xs={'auto'}>
                      <Details aria-hidden={true}>{email.toUpperCase()}</Details>
                    </UserDetailsItemLeft>
                    <UserDetailsItemLeft container direction="row" item xs={'auto'}>
                      <Details aria-hidden={true}>{exam.toUpperCase()}</Details>
                    </UserDetailsItemLeft>
                  </UserDetailColumn>
                  <UserDetailColumn item xs={9} container direction="column">
                    <UserDetailsItemRight container direction="row" item xs={'auto'}>
                      <Info aria-label={nameLabel} tabIndex={0}>
                        {userName}
                      </Info>
                    </UserDetailsItemRight>
                    <UserDetailsItemRight container direction="row" item xs={'auto'}>
                      <Info aria-label={emailLabel} tabIndex={0}>
                        {userEmail}
                      </Info>
                    </UserDetailsItemRight>
                    <UserDetailsItemRight container direction="row" item xs={'auto'}>
                      <Info aria-label={examLabel} tabIndex={0}>
                        {selectedExam}
                      </Info>
                    </UserDetailsItemRight>
                  </UserDetailColumn>
                </UserDetailsGrid>
              </IconContainer>
            </Grid>
            <Grid container item spacing={3} direction="column" alignItems="center">
              <Grid item>
                <FilledButton
                  width={'21.875rem'}
                  text={correctInformationText}
                  description={correctInformationDescription}
                  handleClick={() => uiStore.setViewState('exam-info')}
                />
              </Grid>
              <Grid item>
                <OutlinedButton
                  width={'21.875rem'}
                  text={helpButtonText}
                  description={helpButtonDescription}
                  handleClick={() => setModalOpen(true)}
                />
              </Grid>
            </Grid>
          </Grid>
        </FadeTransition>
      </Box>
    );
  }
);

export default LandingUserDetails;

import React, { FC, ReactElement, ReactNode } from 'react';

import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components/macro';

const VisibilityButton = styled(IconButton)`
  ${({ theme }) => `
  &.Mui-focusVisible {
      color: ${theme.palette.light.main};
      background: ${theme.palette.primary.dark};
    }
	`}
`;

interface Props {
  description: string;
  onClick: () => void;
  edge?: false | 'end' | 'start';
  children?: ReactNode; // add icon here
}

const ButtonAdornment: FC<Props> = ({ description = '', onClick, edge = 'end', children }: Props): ReactElement => (
  <VisibilityButton aria-label={description} onClick={onClick} edge={edge}>
    {children}
  </VisibilityButton>
);

export default ButtonAdornment;

import React, { FC, ReactElement, ReactNode } from 'react';
import Container from './Container';

interface Props {
  children?: ReactNode;
}

const LandingLayout: FC<Props> = ({ children }: Props): ReactElement => {
  const Body: FC = (): ReactElement => <>{children}</>;
  return <Container body={<Body />} />;
};

export default LandingLayout;

import { observable, computed, action, makeObservable } from 'mobx';
import moment from 'moment';
import { ITimes, second, minute } from './types';
import { ITimer, Timer } from './Timer';

interface ITimerStore {
  isRunning: boolean;
  timer: ITimer;
  startTime: moment.Moment | undefined;
  endTime: moment.Moment | undefined;
}

export class TimerStore implements ITimerStore {
  isRunning: boolean;
  timer: ITimer;
  startTime: moment.Moment | undefined;
  endTime: moment.Moment | undefined;

  constructor() {
    this.isRunning = false;
    this.timer = new Timer();
    this.startTime = undefined;
    this.endTime = undefined;
    makeObservable(this, {
      isRunning: observable,
      timer: observable,
      startTime: observable,
      endTime: observable,
      timeRemaining: computed,
      hasStarted: computed,
      tick: action.bound,
      startTimer: action.bound,
      stopTimer: action.bound,
      resetTimer: action.bound,
    });
  }

  get timeRemaining(): ITimes {
    const display = this.timer.display;
    return { days: display.days, hours: display.hours, minutes: display.minutes, seconds: display.seconds };
  }

  get getMinutesRemaining(): number {
    const inMinutes = this.timer.totalMilliSeconds / minute;
    return Math.round(inMinutes * 10) / 10;
  }

  get hasStarted(): boolean {
    return this.timer.totalMilliSeconds !== 0;
  }

  get percentage(): number {
    if (!this.endTime || !this.startTime) {
      return 0;
    }
    const remainingTime = this.timer.milliseconds;
    const percentage = 100 - Math.floor((remainingTime / this.totalTime) * 100);
    return percentage;
  }

  //returns total time in milliseconds
  get totalTime(): number {
    if (!this.startTime || !this.endTime) {
      return 0;
    }
    return this.endTime.diff(this.startTime);
  }

  //update values every second
  public tick(): void {
    if (!this.endTime) {
      throw new Error('Timer needs to have end time defined before startup');
    }
    if (!this.isRunning) {
      return;
    }
    const currentTime = moment();
    const remainingTime = this.endTime.diff(currentTime);
    this.timer.setMilliseconds(remainingTime);
    setTimeout(() => this.tick(), second);
  }

  public startTimer(startTime: string, endTime: string): void {
    if (this.isRunning) return;
    //moment.Moment
    this.startTime = moment(startTime);
    this.endTime = moment(endTime);
    if (!this.startTime || !this.endTime) {
      throw new Error('No start time or end time is defined for the timer');
    }
    console.log('Timer has been started');
    this.isRunning = true;
    this.tick();
  }

  public stopTimer(): void {
    console.log('Timer has been stopped');
    this.isRunning = false;
  }

  public resetTimer(): void {
    console.log('Timer has been reset');
    this.timer.reset();
    this.isRunning = false;
  }
}

import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  tableCell: {
    borderBottom: 'none',
    padding: '4px 8px',
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
});

interface IRowInfo {
  label: string;
  value: string;
}

const ExamInfoRow: FC<IRowInfo> = observer(
  (info: IRowInfo): ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Typography className={classes.label}>{t(info.label)}</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography>{info.value}</Typography>
        </TableCell>
      </TableRow>
    );
  }
);

export default ExamInfoRow;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { useStores } from '../../hooks';
import { NavigationButton } from '../Buttons';
import { ArrowBackSmallIcon, ArrowForwardIcon, ReturnAltSmallIcon } from '../Icons';

const NavigationWrapper = styled.div`
    display:flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 110px;
    & button {
      margin 0 0.3rem;
    }
`;

const ButtonText = styled(Typography)`
  ${({ theme }) => `
    font-size: ${theme.typography.footer.fontSize};
    color: ${theme.palette.light.main};
  `}
`;

export const ExamNavigation: FC = observer(
  (): ReactElement => {
    const { examStore } = useStores();
    const { t } = useTranslation();
    const pageTitle: string = examStore.currentPage ? examStore.currentPage.title : '';
    const backText = t('pages.exam.footer.navigation.buttons.back.label') as string;
    const backDescription = t('pages.exam.footer.navigation.buttons.back.description') as string;
    const nextText = t('pages.exam.footer.navigation.buttons.next.label') as string;
    const nextDescription = t('pages.exam.footer.navigation.buttons.next.description', { pageTitle }) as string;
    const previousText = t('pages.exam.footer.navigation.buttons.previous.label') as string;
    const previousDescription = t('pages.exam.footer.navigation.buttons.previous.description', { pageTitle }) as string;
    const returnText = t('pages.exam.footer.navigation.buttons.return.label') as string;
    const returnDescription = t('pages.exam.footer.navigation.buttons.return.description') as string;

    if (examStore.isInstructionsPage || examStore.isReturnPage) {
      return (
        <NavigationWrapper>
          <NavigationButton description={backDescription} handleClick={() => examStore.setFirstPage()}>
            <Grid container direction="column" item justify="space-between" alignItems="center" xs={'auto'}>
              <Grid item xs={'auto'}>
                <ArrowBackSmallIcon />
              </Grid>
              <Grid item xs={'auto'}>
                <ButtonText>{backText}</ButtonText>
              </Grid>
            </Grid>
          </NavigationButton>
        </NavigationWrapper>
      );
    }

    return (
      <NavigationWrapper>
        {!examStore.isFirstPage && (
          <NavigationButton description={previousDescription} handleClick={examStore.setPreviousPage}>
            <Grid container direction="column" item justify="space-between" alignItems="center" xs={'auto'}>
              <Grid item xs={'auto'}>
                <ArrowBackSmallIcon />
              </Grid>
              <Grid item xs={'auto'}>
                <ButtonText>{previousText}</ButtonText>
              </Grid>
            </Grid>
          </NavigationButton>
        )}
        {!examStore.isLastPage && (
          <NavigationButton description={nextDescription} handleClick={examStore.setNextPage}>
            <Grid container direction="column" item justify="space-between" alignItems="center" xs={'auto'}>
              <Grid item xs={'auto'}>
                <ArrowForwardIcon />
              </Grid>
              <Grid item xs={'auto'}>
                <ButtonText>{nextText}</ButtonText>
              </Grid>
            </Grid>
          </NavigationButton>
        )}
        {examStore.isLastPage && (
          <NavigationButton description={returnDescription} handleClick={examStore.setReturnPage}>
            <Grid container direction="column" item justify="space-between" alignItems="center" xs={'auto'}>
              <Grid item xs={'auto'}>
                <ReturnAltSmallIcon />
              </Grid>
              <Grid item xs={'auto'}>
                <ButtonText>{returnText}</ButtonText>
              </Grid>
            </Grid>
          </NavigationButton>
        )}
      </NavigationWrapper>
    );
  }
);

export const ExamNavigationSmall: FC = observer(
  (): ReactElement => {
    const { examStore } = useStores();
    const { t } = useTranslation();
    const pageTitle: string = examStore.currentPage ? examStore.currentPage.title : '';
    const nextDescription = t('pages.exam.footer.navigation.buttons.next.description', { pageTitle }) as string;
    const previousDescription = t('pages.exam.footer.navigation.buttons.previous.description', { pageTitle }) as string;
    const backDescription = t('pages.exam.footer.navigation.buttons.back.description') as string;
    const returnDescription = t('pages.exam.footer.navigation.buttons.return.description') as string;

    if (examStore.isInstructionsPage || examStore.isReturnPage) {
      return (
        <NavigationWrapper>
          <NavigationButton description={backDescription} handleClick={() => examStore.setFirstPage()}>
            <Grid container direction="column" item justify="space-between" alignItems="center" xs={'auto'}>
              <Grid item xs={'auto'}>
                <ArrowBackSmallIcon />
              </Grid>
            </Grid>
          </NavigationButton>
        </NavigationWrapper>
      );
    }

    return (
      <NavigationWrapper>
        {!examStore.isFirstPage && (
          <NavigationButton description={previousDescription} handleClick={examStore.setPreviousPage}>
            <Grid container direction="column" item justify="space-between" alignItems="center" xs={'auto'}>
              <Grid item xs={'auto'}>
                <ArrowBackSmallIcon />
              </Grid>
            </Grid>
          </NavigationButton>
        )}
        {!examStore.isLastPage && (
          <NavigationButton description={nextDescription} handleClick={examStore.setNextPage}>
            <Grid container direction="column" item justify="space-between" alignItems="center" xs={'auto'}>
              <Grid item xs={'auto'}>
                <ArrowForwardIcon />
              </Grid>
            </Grid>
          </NavigationButton>
        )}
        {examStore.isLastPage && (
          <NavigationButton description={returnDescription} handleClick={examStore.setReturnPage}>
            <Grid container direction="column" item justify="space-between" alignItems="center" xs={'auto'}>
              <Grid item xs={'auto'}>
                <ReturnAltSmallIcon />
              </Grid>
            </Grid>
          </NavigationButton>
        )}
      </NavigationWrapper>
    );
  }
);

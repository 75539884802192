import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';

import uniqueId from 'lodash.uniqueid';

import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import { FilledButton } from './Buttons';
import { Box } from '@material-ui/core';

const DialogWrapper = styled(Dialog)`
  color: rgba(0, 0, 0, 0.6);
  min-width: 16rem;
`;

const DialogTitle = styled(MuiDialogTitle)`
  ${({ theme }) => `
		background-color: ${theme.palette.primary.dark};
		color: ${theme.palette.light.dark};
		root {
			margin: 0;
			padding: ${theme.spacing(2)};
		}
	`}
`;

const DialogContent = styled(MuiDialogContent)`
  ${({ theme }) => `
		root {
			padding: ${theme.spacing(2)};
		}
	`}
`;

const DialogActions = styled(MuiDialogActions)`
  ${({ theme }) => `
		root {
			margin: 0;
			padding: ${theme.spacing(1)};
		}
	`}
`;

const DialogButton = styled(Button)`
  ${({ theme }) => `
		color: ${theme.palette.primary.main};
    &.Mui-focusVisible {
      outline: 2px solid ${theme.palette.primary.main};
    }
	`}
`;

interface Props {
  title?: string;
  text?: string;
  textCollection?: Array<string>;
  buttonText?: string;
  buttonDescription?: string;
  open?: boolean;
  buttonType?: 'text' | 'filled';
  disableClose?: boolean;
  onClose?: () => void;
}

const Modal: FC<Props> = ({
  title = '',
  text = '',
  textCollection = [],
  buttonText = '',
  buttonDescription = '',
  onClose = () => '',
  open = false,
  buttonType = 'text',
  disableClose = false,
}: Props): ReactElement => {
  const id = uniqueId('modal_');
  return (
    <DialogWrapper
      maxWidth="xs"
      onClose={onClose}
      aria-modal="true"
      aria-labelledby={id}
      open={open}
      disableEscapeKeyDown={disableClose}
      disableBackdropClick={disableClose}
    >
      <DialogTitle>
        <Typography component="h1" variant="h6" tabIndex={0}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {textCollection.length === 0 ? (
          <Typography gutterBottom tabIndex={0}>
            {text}
          </Typography>
        ) : (
          <Box>
            <Typography tabIndex={0}> {textCollection[0]}</Typography>
            <ol>
              <li tabIndex={0}>
                {textCollection[1]}
                <b>{textCollection[2]}</b>
                {textCollection[3]}
              </li>
              <br />
              <li tabIndex={0}>{textCollection[4]}</li>
              <br />
              <li tabIndex={0}>{textCollection[5]}</li>
              <br />
              <li tabIndex={0}>
                {textCollection[6]}
                <b>{textCollection[7]}</b>
              </li>
            </ol>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {buttonType === 'filled' ? (
          <FilledButton elevation={true} text={buttonText} description={buttonDescription} handleClick={onClose} />
        ) : (
          <DialogButton onClick={onClose}>{buttonText}</DialogButton>
        )}
      </DialogActions>
    </DialogWrapper>
  );
};

export default Modal;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import uniqueId from 'lodash.uniqueid';

import { Radio, RadioGroup, Typography } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { IChoice } from '../../../stores/ExamStore/Model';
import { useStores } from '../../../hooks';

interface HeadingProps {
  htmlFor: string;
}

const Heading = styled(Typography)<HeadingProps>`
  display: flex;
  && strong {
    display: contents;
  }
`;

interface FormControlProps {
  component: string;
}

const SingleChoiceFControl = styled(FormControl)<FormControlProps>`
  ${({ theme }) => `
    user-select: none;
    margin: 0 ${theme.width.textArea.padding.right} 0 ${theme.width.textArea.padding.left};
  `};
`;

const InputControl = styled(FormControlLabel)`
  padding: 0.5rem 0;
`;

interface QuestionLabelProps {
  component: string;
}

const QuestionLabel = styled(FormLabel)<QuestionLabelProps>`
  ${({ theme }) => `
		color: ${theme.palette.dark.dark};
    margin-bottom: 1rem;
	`}
`;

interface Props {
  choices: IChoice[];
  heading: string;
  question: string;
  page: string;
}

export const SingleChoice: FC<Props> = ({ heading, choices, page, question }: Props): ReactElement => {
  const { examStore, userStore } = useStores();
  const handleChange = (choice: IChoice) => {
    if (!userStore.isAdmin) {
      examStore.setSingleChoiceAnswer({ pageId: page, questionId: question, choiceId: choice.id });
    }
  };
  const labelid = uniqueId('choice-labelid-');
  const helperId = uniqueId('choice-helperid-');
  return (
    <SingleChoiceFControl error={false} component={'fieldset'}>
      <QuestionLabel component="h1">
        <Heading htmlFor={labelid} dangerouslySetInnerHTML={{ __html: heading }} tabIndex={0} />
      </QuestionLabel>
      <RadioGroup row={false}>
        {choices.map((choice) => {
          const key = uniqueId('choice-key-');
          return (
            <InputControl
              id={labelid}
              aria-describedby={helperId}
              key={key}
              value={choice.id}
              control={
                <Radio
                  disabled={userStore.isAdmin}
                  color="primary"
                  checked={choice.selected}
                  onChange={() => handleChange(choice)}
                />
              }
              label={choice.text}
            />
          );
        })}
      </RadioGroup>
    </SingleChoiceFControl>
  );
};

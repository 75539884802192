import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import { useStores } from '../../hooks';
import { PasswordField, ExamPasswordField } from '../Inputs';
import Modal from '../Modal';
import { FilledButton, OutlinedButton } from '../Buttons';
import { FadeTransition } from '../Transitions';

const FormFieldSet = styled.fieldset`
  border: 0;
`;

const FormLegend = styled.legend`
  text-align: center;
  font-size: 1.5rem;
`;

const LandingLogin: FC = (): ReactElement => {
  const { userStore } = useStores();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [examPassword, setExamPassword] = useState<string>('');

  const modalTitleInitial = t('pages.landing.login.modal.noCode.title') as string;
  const modalTexts = [];
  modalTexts.push(t('pages.landing.login.modal.noCode.text1') as string);
  modalTexts.push(t('pages.landing.login.modal.noCode.text2') as string);
  modalTexts.push(t('pages.landing.login.modal.noCode.text3') as string);
  modalTexts.push(t('pages.landing.login.modal.noCode.text4') as string);
  modalTexts.push(t('pages.landing.login.modal.noCode.text5') as string);
  modalTexts.push(t('pages.landing.login.modal.noCode.text6') as string);
  modalTexts.push(t('pages.landing.login.modal.noCode.text7') as string);
  modalTexts.push(t('pages.landing.login.modal.noCode.text8') as string);
  const modalButtonTextInitial = t('pages.landing.login.modal.noCode.button') as string;

  const continueButtonText = t('pages.landing.login.buttons.continue.label') as string;
  const continueButtonDescription = t('pages.landing.login.buttons.continue.description') as string;
  const guideButtonText = t('pages.landing.login.buttons.guide.label') as string;
  const guideButtonDescription = t('pages.landing.login.buttons.guide.description') as string;

  const formHeading = t('pages.landing.initial.subTitle') as string;
  const authenticate = async (): Promise<void> => {
    await userStore.login(password, examPassword);
  };

  return (
    <FadeTransition>
      <Modal
        title={modalTitleInitial}
        textCollection={modalTexts}
        buttonText={modalButtonTextInitial}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <Container maxWidth="sm">
        <form onSubmit={authenticate}>
          <FormFieldSet>
            <Grid container direction="column" spacing={2} alignItems="stretch">
              <Grid item>
                <FormLegend tabIndex={0}>{formHeading}</FormLegend>
              </Grid>
              <Grid container item direction="column" spacing={1}>
                <Grid item>
                  <PasswordField password={password} setPassword={setPassword} />
                </Grid>
                <Grid item>
                  <ExamPasswordField password={examPassword} setPassword={setExamPassword} />
                </Grid>
              </Grid>
              <Grid container item direction="column" alignItems="stretch" spacing={2}>
                <Grid item>
                  <FilledButton text={continueButtonText} description={continueButtonDescription} type="submit" />
                </Grid>
                <Grid item>
                  <OutlinedButton
                    text={guideButtonText}
                    aria-live="assertive"
                    description={guideButtonDescription}
                    handleClick={() => setModalOpen(true)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormFieldSet>
        </form>
      </Container>
    </FadeTransition>
  );
};

export default LandingLogin;

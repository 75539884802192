import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';

import Fade from '@material-ui/core/Fade';

import { useStores } from '../hooks';

const AnimationWrapper = styled.div`
  height: 100%;
`;

interface FadeProps {
  children?: ReactNode;
  transitionTime?: number; //milliseconds
  when?: boolean;
}

export const FadeTransition: FC<FadeProps> = ({ children, transitionTime, when = true }: FadeProps): ReactElement => {
  const { uiStore } = useStores();

  return (
    <Fade in={when} timeout={transitionTime || uiStore.pageFadeDuration} unmountOnExit={true}>
      <AnimationWrapper>{children}</AnimationWrapper>
    </Fade>
  );
};

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import uniqueId from 'lodash.uniqueid';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FilledInput from '@material-ui/core/FilledInput';

const StyledForm = styled(FormControl)`
  ${({ theme }) => `
    &:hover ${Label} {
        color: ${theme.palette.invalid.main};
    }
  `}
`;

interface LabelProps {
  isactive: boolean;
}
const Label = styled(InputLabel)<LabelProps>`
  ${({ theme, isactive }) => `
    color: ${isactive ? theme.palette.invalid.main : theme.palette.dark.dark};
    &.Mui-focused {
        color: ${theme.palette.invalid.main};
      }
    &:hover {
      color: ${theme.palette.invalid.main};
    }
    &.Mui-error {
      color: ${theme.palette.invalid.main};
    }
  `}
`;

const StyledInput = styled(FilledInput)`
  ${({ theme }) => `
    background-color: ${theme.palette.light.main};
    border-radius: 0;
    &:after {
      border-color: ${theme.palette.gray.lighter2};
    }
		&.Mui-focused {
      background-color: ${theme.palette.light.main};
    }
    &:hover {
      background-color: ${theme.palette.gray.lightest};
    }
    &:active {
      background-color: ${theme.palette.gray.lighter2};
    }
	`}
`;

interface ErrorTextProps {
  error: boolean;
  reserveSpace: boolean;
}

const ErrorText = styled(FormHelperText)<ErrorTextProps>`
  ${({ theme, error, reserveSpace }) => `
    color: ${theme.palette.light.main} !important;
    font-size: ${theme.typography.footer.fontSize};
    ${reserveSpace ? `visibility: ${!error ? 'hidden' : 'visible'};` : ''}
    ${!reserveSpace ? `display: ${!error ? 'none' : 'block'};` : ''}
  `}
`;

interface Props {
  type?: 'text' | 'password';
  description?: string;
  label?: string;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  secret?: boolean;
  error?: boolean;
  errorText?: string;
  value: string;
  endAdornment?: ReactElement;
  disableUnderline?: boolean;
  visibleError?: boolean;
  onChange: (value: string) => void;
}

const InputField: FC<Props> = ({
  type = 'text',
  variant = 'filled',
  description = '',
  label = '',
  value,
  disableUnderline = false,
  error = false,
  errorText = '',
  endAdornment,
  visibleError = true,
  onChange,
}: Props): ReactElement => {
  const labelId = uniqueId('password_label_');
  const helperId = uniqueId('password_helper_');

  return (
    <StyledForm error={error} fullWidth variant={variant}>
      <Label htmlFor={labelId} isactive={value !== null && value.length > 0}>
        {label}
      </Label>
      <StyledInput
        id={labelId}
        inputProps={{ 'aria-label': description }}
        type={type}
        value={value}
        disableUnderline={disableUnderline}
        onChange={(event) => onChange(event.target.value)}
        endAdornment={endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : ''}
        aria-describedby={helperId}
      />
      {error && (
        <ErrorText reserveSpace={visibleError} error={error} id={helperId} role="alert">
          {errorText}
        </ErrorText>
      )}
    </StyledForm>
  );
};

export default InputField;

import styled from 'styled-components/macro';
import Switch from '@material-ui/core/Switch';

interface StyledSwitchProps {
  checked: boolean;
  darkMode?: boolean;
}

const StyledSwitch = styled(Switch)<StyledSwitchProps>`
  ${({ theme, checked }) => {
    //Enabled
    let enabledThumb = theme.palette.secondary.main;
    let enabledTrack = theme.palette.secondary.light;
    //Disabled
    let disabledThumb = theme.palette.secondary.disabled2;
    let disabledTrack = theme.palette.secondary.disabled3;
    //Focused
    let focusedThumb = theme.palette.primary.dark;
    let focusedBackground = theme.palette.secondary.main;
    if (!checked) {
      enabledThumb = theme.palette.light.main;
      enabledTrack = theme.palette.gray.normal3;
      disabledThumb = theme.palette.light.main;
      disabledTrack = theme.palette.light.lighter3;
      focusedThumb = theme.palette.gray.normal3;
      focusedBackground = theme.palette.light.main;
    }
    return `
        && {
          .MuiSwitch-thumb {
            color: ${enabledThumb};
          }
          .MuiSwitch-track {
            opacity: 1 !important;
            background-color: ${enabledTrack};
          }
          .Mui-disabled && .MuiSwitch-thumb {
            color: ${disabledThumb} !important;
          }
          .Mui-disabled && .MuiSwitch-track {
            opacity: 1 !important;
            background-color: ${disabledTrack} !important;
          }
          .Mui-focusVisible {
            .MuiSwitch-thumb {
              color: ${focusedThumb} !important;
            }
            color: ${focusedThumb} !important;
            background-color: ${focusedBackground} !important;
          }
        }
      `;
  }}
`;

export default StyledSwitch;

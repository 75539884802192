import axios from 'axios';
import { IUser } from '../stores/UserStore/types';

interface IBackend {
  login: (password: string, examPassword: string) => Promise<void>;
  logout: () => Promise<void>;
  getUser: () => Promise<IUser>;
}

class Backend implements IBackend {
  async login(password: string, examPassword: string): Promise<void> {
    await axios({
      method: 'post',
      url: '/login',
      data: {
        password: password,
        examPassword: examPassword
      }
    });
  }

  async logout(): Promise<void> {
    await axios.post('/logout');
  }

  async getUser(): Promise<IUser> {
    const response = await axios({
      method: 'get',
      url: '/api/me'
    });
    const user: IUser = response.data as any;
    return user;
  }
}

export const BackendInstance = new Backend();

import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';

import { ExamErrorIcon } from '../../Icons';
import { FadeTransition } from '../../Transitions';

const IconContainer = styled(Grid)`
  text-align: center;
`;

interface Props {
  children: ReactNode;
}

const ErrorContainer: FC<Props> = ({ children }: Props): ReactElement => {
  return (
    <FadeTransition>
      <Grid container direction="column" alignItems="stretch" spacing={10}>
        <IconContainer item>
          <ExamErrorIcon />
        </IconContainer>
        <IconContainer item>{children}</IconContainer>
      </Grid>
    </FadeTransition>
  );
};

export default ErrorContainer;

import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';

import { FinishedStatusSmallIcon, UnfinishedStatusSmallIcon } from '../Icons';
import { Typography } from '@material-ui/core';

interface Props {
  status: 'answered' | 'unanswered' | 'unfinished';
}
export const ExamStatus: FC<Props> = observer(({ status }: Props): ReactElement => {
  switch (status) {
  case 'answered':
    return <FinishedStatusSmallIcon />;
  case 'unanswered':
    return <UnfinishedStatusSmallIcon />;
  case 'unfinished':
    return <Typography>N/A</Typography>;
  }
});

export default ExamStatus;
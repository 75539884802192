import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';

interface ButtonProps {
  width?: string;
  lightBackground?: boolean;
}

export const ContainedButton = styled(Button)<ButtonProps>`
  ${({ theme, width, lightBackground }) => `
		background-color:${theme.palette.secondary.main};
		color: ${theme.palette.primary.dark};
		width: ${width};
		&:hover {
			background-color:${theme.palette.secondary.hover};
		}
    &.Mui-focusVisible {
      background-color:${theme.palette.primary.dark};
      color: ${theme.palette.light.main};
      outline: 2px solid ${!lightBackground && theme.palette.light.main};
    }
		&:active {
			background-color:${theme.palette.secondary.active};
		}
		&:disabled {
			color: ${theme.palette.light.main} !important;
			background-color: ${theme.palette.gray.normal3};
		}
	`}
`;

interface Props {
  handleClick?: () => void;
  text: string;
  description?: string;
  lightBackground?: boolean;
  disabled?: boolean;
  elevation?: boolean;
  width?: string;
  type?: 'button' | 'submit' | 'reset';
}

export const FilledButton: FC<Props> = ({
  handleClick = () => '',
  text,
  description = '',
  lightBackground = false,
  disabled = false,
  elevation = false,
  width = '100%',
  type = 'button',
}: Props): ReactElement => (
  <ContainedButton
    size="large"
    aria-label={description}
    disabled={disabled}
    variant="contained"
    color="secondary"
    width={width}
    lightBackground={lightBackground}
    disableElevation={!elevation}
    onClick={() => handleClick()}
    type={type}
  >
    {text}
  </ContainedButton>
);

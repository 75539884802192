import { useCallback } from 'react';
import debounce from 'lodash.debounce';

interface IUseTimeout {
  callback: TCallback; // function to call. No args passed.
  // if you create a new callback each render, then previous callback will be cancelled on render.
  timeout: number; // delay, ms (default: immediately put into JS Event Queue)
}

type TCallback = (value: string) => void;
export const useDebounce = (
  callback: IUseTimeout['callback'],
  timeout: IUseTimeout['timeout']
): ((value: any) => void) => {
  return useCallback(
    debounce((value: any) => callback(value), timeout),
    [callback, timeout]
  );
};

import React from 'react';
import ReactDOM from 'react-dom';

import { StoreProvider } from './contexts';
import RootStore from './stores/RootStore';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

//Import and intialize the RootStore and use it in the whole app through React's context API
const rootStore = new RootStore();

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={rootStore}>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();
reportWebVitals();

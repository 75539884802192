import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

interface HeroImageProps {
  alt: string;
}

const HeroImage = styled.img<HeroImageProps>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
`;

const Hero: FC = (): ReactElement => {
  const { t } = useTranslation();
  const LandingAlt = t('pages.landing.images.start.altText') as string;
  return <HeroImage src={'./tutkintosovellus-hero.png'} alt={LandingAlt} aria-hidden={true} />;
};

export default Hero;

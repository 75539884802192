import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';

import { FadeTransition } from '../Transitions';
import { LoadingIcon } from '../../components/Icons';

const IconContainer = styled.div`
  text-align: center;
`;

const LandingLoading: FC = (): ReactElement => {
  return <FadeTransition>
    <IconContainer>
      <LoadingIcon />
    </IconContainer>
  </FadeTransition>;
};

export default LandingLoading;
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { useSnackbar } from 'notistack';
import { ISnackbar } from '../../../stores/NotificationStore/types';
import { useStores } from '../../../hooks';
import { Typography } from '@material-ui/core';

const TextButton = styled(Button)`
  ${({ theme }) => `
		color: ${theme.palette.primary.light};
	`}
`;
interface SnackbarProps {
  key: ISnackbar['key'];
  message: ISnackbar['text'];
}

const action: FC<SnackbarProps> = ({ key }: SnackbarProps): ReactElement => {
  const { closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const closeLabel = t('common.buttons.close.label');
  return (
    <TextButton onClick={() => closeSnackbar(key)}>
      <Typography aria-hidden={true}>{closeLabel}</Typography>
    </TextButton>
  );
};

export const Snackbars: FC = observer((): null => {
  const { notificationStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const snackbars = notificationStore.snackbars;
  snackbars.forEach((snackbar: ISnackbar) => {
    enqueueSnackbar(snackbar.text, {
      autoHideDuration: 5000,
      variant: snackbar.type,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      action,
    });
    // Dispatch action to remove snackbar from mobx store
    notificationStore.removeSnackbar(snackbar.key);
  });
  return null;
});

import React, { FC, ReactElement, useState } from 'react';
import styled from 'styled-components/macro';

import FormControl from '@material-ui/core/FormControl';
import { InputLabel, OutlinedInput } from '@material-ui/core';
import { TextCount } from './components';

const Label = styled(InputLabel)`
  ${({ theme }) => `
    color: ${theme.palette.gray.main};
  `}
`;

interface TextFieldAreaProps {
  remheight: number;
}
const TextFieldArea = styled(OutlinedInput)<TextFieldAreaProps>`
  ${({ theme, remheight }) => `
    border-radius: 0;
    textarea {
      min-height: ${remheight}rem;
      resize: vertical;
      overflow-y: scroll !important;
    }
    fieldset {
      border-color: ${theme.palette.gray.main};
    }
  `}
`;

interface Props {
  description?: string; //For accessability
  label?: string;
  value?: string;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  id?: string; //For accessability to identify the element's associated helpertext and labels
  rows?: number;
  maxLength?: number;
  disabled?: boolean;
  setText: (value: string) => void;
}

const TextArea: FC<Props> = ({
  rows = 5,
  description = '',
  value,
  label = '',
  variant = 'outlined',
  setText,
  id,
  maxLength = 3500,
  disabled = false,
}: Props): ReactElement => {
  const [valueLength, updateValueLength] = useState(value ? value.length : 0);
  const update = (value: string) => {
    updateValueLength(value ? value.length : 0);
    setText(value);
  };
  return (
    <FormControl fullWidth variant={variant}>
      <Label htmlFor={id} shrink={false}>
        {valueLength === 0 ? label : ''}
      </Label>
      <TextFieldArea
        id={id}
        disabled={disabled}
        defaultValue={value}
        inputProps={{ 'aria-label': description, maxLength: maxLength }}
        multiline={true}
        remheight={rows}
        onChange={(event) => update(event.target.value)}
        aria-describedby={id}
      />
      {maxLength && <TextCount current={valueLength} max={maxLength} />}
    </FormControl>
  );
};

export default TextArea;

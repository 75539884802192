import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { Typography } from '@material-ui/core';

import { useStores } from '../../hooks';

const Description = styled(Typography)`
  margin-bottom: 1rem;
`;

const ExamInstructions: FC = observer(
  (): ReactElement => {
    const { examStore } = useStores();
    const instructions = examStore.instructions.text;
    const accessibleDescription: string = instructions.replace(/<[^>]+>/g, '').replace('&nbsp', '');
    return (
      <Description dangerouslySetInnerHTML={{ __html: instructions }} aria-label={accessibleDescription} tabIndex={0} />
    );
  }
);

export default ExamInstructions;

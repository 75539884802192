import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import LinearProgress from '@material-ui/core/LinearProgress';

const LinearProgressIcon = styled(LinearProgress)`
  ${({ theme }) => `
		height:	0.4rem;
		border-radius: 0.2rem;
		background-color: ${theme.palette.light.main};
		.MuiLinearProgress-barColorPrimary {
			background: ${theme.palette.invalid.light};
		}
	`}
`;

interface ITimerLinear {
  value?: number;
}
const LinearTimer: FC<ITimerLinear> = ({ value = 0 }: ITimerLinear): ReactElement => {
  return <LinearProgressIcon variant="determinate" value={value} />;
};
export default LinearTimer;

import { createGlobalStyle } from 'styled-components/macro';
import { kauppakamariColors } from './Colors';

const {
  lightBlue,
  royalBlue,
  royalBlueLight,
  royalBlueGradient,
  vaaleanPunainen,
  vaaleanPunainenLight,
  vaaleanPunainenDark,
  vaaleanPunainenDisabled,
  vaaleanPunainenDisabled2,
  vaaleanPunainenDisabled3,
  vaaleanPunainenHover,
  vaaleanPunainenActive,
  okra,
  valkoinen,
  neoValkoinen,
  valkoinenDark,
  valkoinenDarker,
  beigeLight,
  beigePale,
  vihreä,
  liila,
  punainen,
  musta,
  neoMusta,
  mustaLight,
  mustaLighter,
  harmaa,
  harmaa2,
  harmaa3,
  harmaa4,
  harmaaLight,
  harmaaLighter,
  harmaaLighter2,
  harmaaLighter3,
  harmaaLightest,
  tummanHarmaa,
  harmaaBlue,
} = kauppakamariColors;

export const defaultTheme = {
  palette: {
    primary: {
      light: lightBlue,
      main: royalBlueLight,
      dark: royalBlue,
    },
    secondary: {
      light: vaaleanPunainenLight,
      main: vaaleanPunainen,
      dark: vaaleanPunainenDark,
      disabled: vaaleanPunainenDisabled,
      disabled2: vaaleanPunainenDisabled2,
      disabled3: vaaleanPunainenDisabled3,
      hover: vaaleanPunainenHover,
      active: vaaleanPunainenActive,
    },
    tertiary: {
      main: okra,
    },
    background: {
      primary: beigeLight,
      secondary: beigePale,
      royalBlue: royalBlueGradient,
    },
    valid: {
      main: vihreä,
    },
    invalid: {
      light: liila,
      main: punainen,
    },
    light: {
      main: valkoinen,
      neo: neoValkoinen,
      dark: valkoinenDark,
      darker: valkoinenDarker,
    },
    dark: {
      light: mustaLighter,
      main: mustaLight,
      neo: neoMusta,
      dark: musta,
    },
    gray: {
      lightest: harmaaLightest,
      lighter3: harmaaLighter3,
      lighter2: harmaaLighter2,
      lighter: harmaaLighter,
      light: harmaaLight,
      main: harmaa,
      normal1: harmaa2,
      normal2: harmaa3,
      normal3: harmaa4,
      dark: tummanHarmaa,
      blue: harmaaBlue,
    },
    info: {
      main: neoMusta,
    },
  },
  typography: {
    fontFamily: [
      'Work Sans',
      'Century Gothic',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    header: {
      fontSize: '1.06rem',
    },
    footer: {
      fontSize: '0.814rem',
    },
  },
  width: {
    textArea: {
      padding: {
        left: '14px',
        right: '28px',
      },
    },
    textField: {
      padding: '14px',
    },
  },
} as { [key: string]: any };

export const GlobalStyle = createGlobalStyle`
    html, body, #root {
    min-height: -webkit-fill-available;
		min-height: 100vh;
		margin: 0 !important;
		padding: 0 !important;
  }
	* {
		box-sizing: border-box;
	}
    body {
        font-family: 'Work Sans', 'Century Gothic', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
`;

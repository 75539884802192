import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { LandingLayout } from '../Layout';
import Language from '../Language';
import { useStores } from '../../hooks';
import { BackButton } from '../Buttons';
import { Hero, LogoDark } from '../Images';

interface LandingWrapperProps {
  isSmall?: boolean;
}
const LandingWrapper = styled.div<LandingWrapperProps>`
  ${({ isSmall }) => `
    height  100%;
    overflow: hidden;
    min-height: inherit;
    display: grid;
    ${!isSmall ? 'grid-template-columns: 40% 60%;' : ''}
	`}
`;

const PageContainer = styled(Container)`
  height: 100%;
`;

const PageGrid = styled(Grid)`
  height: 100%;
`;

interface LoginContainerProps {
  isExtraSmall?: boolean;
}

const LoginContainer = styled(Paper)<LoginContainerProps>`
  ${({ theme, isExtraSmall }) => `${
    !isExtraSmall
      ? `
      background: url("./tutkintosovellus-vastuullisuus-partial.svg"), ${theme.palette.background.royalBlue};
      background-repeat: no-repeat;
      background-size: 9rem, 100%;
      background-position: bottom 0 right 0, 0;`
      : `
        background: ${theme.palette.background.royalBlue};
      `
  }
		padding: 1rem;
		color: ${theme.palette.light.main};
		border-radius: 0;
    overflow: hidden;
    position: relative;
	`}
`;

interface HeaderProps {
  displayBack: boolean;
}

const Header = styled.div<HeaderProps>`
  ${({ displayBack }) => `
		display: flex;
		justify-content: ${displayBack ? 'space-between' : 'flex-end'};
	`}
`;

interface LandingTextProps {
  component: string;
}

const Heading = styled(Typography)<LandingTextProps>`
  text-align: center;
`;

const ComponentGrid = styled(Grid)`
  width: 100%;
  margin: 0;
`;

const LogoGrid = styled(Grid)`
  height: 5rem;
  width: 100%;
`;

const LogoLeft = styled.div`
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  width: 12rem;
  height: auto;
`;

interface Props {
  heading?: string;
  children?: ReactNode;
  noBack?: boolean;
}

const LandingContainer: FC<Props> = observer(
  ({ children, heading = '', noBack = false }: Props): ReactElement => {
    const { t } = useTranslation();
    const { uiStore, userStore } = useStores();
    const selectedLanguage = uiStore.selectedLanguage;
    const languages = uiStore.languages;
    const toTheBeginningText = t('pages.landing.common.buttons.beginning.label') as string;
    const toTheBeginningDesription = t('pages.landing.common.buttons.beginning.description') as string;
    const onLanguageChange = uiStore.changeLanguage;
    const matchExtraSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
    const matchesSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const displayBack = userStore.isLoggedIn && !noBack;
    const onBackClick = () => {
      if (userStore.isAdmin) {
        userStore.previewLogout();
      } else {
        userStore.logout();
      }
    };

    const LoginPage = () => (
      <LoginContainer elevation={24} square={true} isExtraSmall={matchExtraSmall}>
        <Header displayBack={displayBack}>
          {displayBack && (
            <BackButton
              callback={onBackClick}
              text={toTheBeginningText}
              description={toTheBeginningDesription}
              lightBackground={false}
            />
          )}
          <Language languages={languages} selected={selectedLanguage} onChange={onLanguageChange} />
        </Header>
        <Grid container direction="column" justify="space-between">
          <PageGrid container item direction="column" justify="space-between" spacing={1} wrap="nowrap">
            <Grid item>
              <PageContainer>
                <ComponentGrid container spacing={10} direction="column">
                  <Grid item>
                    <Heading variant="h4" component="h1" tabIndex={0}>
                      {heading}
                    </Heading>
                  </Grid>
                  <Grid item>{children}</Grid>
                </ComponentGrid>
              </PageContainer>
            </Grid>
          </PageGrid>
          <LogoGrid item container></LogoGrid>
        </Grid>
        <LogoLeft>
          <LogoDark />
        </LogoLeft>
      </LoginContainer>
    );

    return (
      <LandingLayout>
        {matchesSmall && (
          <LandingWrapper isSmall={matchesSmall}>
            <LoginPage />
          </LandingWrapper>
        )}
        {!matchesSmall && (
          <LandingWrapper>
            <Hero />
            <LoginPage />
          </LandingWrapper>
        )}
      </LandingLayout>
    );
  }
);

export default LandingContainer;

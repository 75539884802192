import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { useStores } from '../../hooks';
import { FilledButton } from '../Buttons';
import { FadeTransition } from '../Transitions';

const DetailsWrapper = styled(Grid)`
		padding 0 0 1rem 0;
    text-align: center;
`;

const ButtonsWrapper = styled(Grid)`
  align-items: center;
`;

const LandingFinished: FC = observer(
  (): ReactElement => {
    const { userStore } = useStores();
    const { t } = useTranslation();
    const instructions1 = t('pages.landing.finished.instructions1Text') as string;
    const instructions2 = t('pages.landing.finished.instructions2Text') as string;
    const homeButtonText = t('pages.landing.finished.buttons.home.label') as string;
    const homeButtonDescription = t('pages.landing.finished.buttons.home.description') as string;

    return (
      <FadeTransition>
        <DetailsWrapper item>
          <Typography tabIndex={0}>{instructions1}</Typography>
          <br />
          <Typography tabIndex={0}>{instructions2}</Typography>
          <br />
          <br />
        </DetailsWrapper>
        <ButtonsWrapper container item spacing={3} direction="column" alignItems="stretch">
          <FilledButton
            width="21.875rem"
            elevation={true}
            text={homeButtonText}
            description={homeButtonDescription}
            handleClick={() => userStore.logout()}
          />
        </ButtonsWrapper>
      </FadeTransition>
    );
  }
);

export default LandingFinished;

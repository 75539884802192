import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { Box, Typography } from '@material-ui/core';
import uniqueId from 'lodash.uniqueid';

import { useDebounce, useStores } from '../../../hooks';
import { IRow } from '../../../stores/ExamStore/Model';
import { TextInput } from '../../Inputs';
import { useTranslation } from 'react-i18next';

const Heading = styled(Typography)`
  ${({ theme }) => `
    user-select: none;
    display: flex;
    margin:  0 ${theme.width.textField.padding} 2rem ${theme.width.textField.padding};
    && strong {
      display: contents;
    }
  `}
`;

const Question = styled(Typography)`
  user-select: none;
`;

interface MultiTextProps {
  heading: string;
  page: string;
  question: string;
  rows: IRow[];
  position: number | null;
  maxLength: number | null;
}

export const MultiText: FC<MultiTextProps> = ({
  heading,
  page,
  question,
  rows,
  position,
  maxLength,
}: MultiTextProps): ReactElement => {
  const { examStore, userStore } = useStores();
  const { t } = useTranslation();
  const placeholderText: string = t('pages.exam.common.placeholder');
  return (
    <Box>
      <Heading dangerouslySetInnerHTML={{ __html: heading }} tabIndex={0} />
      {rows.map((row, index) => {
        const TextAreaDescription = 'placeholder';
        const callback = async (answer: IRow['answer']) => {
          await examStore.setMultiRowAnswer({ pageId: page, questionId: question, rowId: row.id, answer });
          document.getElementById(row.id)?.focus();
        };
        const debounce = useDebounce(callback, 1000);
        const saveText = (answer: IRow['answer']) => {
          if (!userStore.isAdmin) {
            debounce(answer);
          }
        };
        const key = uniqueId('row-key-');
        const subPosition = position ? `${position}.${index + 1}` : index + 1;
        return (
          <Box key={key}>
            <Question tabIndex={0}>{`${subPosition}. ${row.text}`}</Question>
            <TextInput
              aria-label={placeholderText}
              disabled={userStore.isAdmin}
              label={placeholderText}
              value={row.answer}
              description={TextAreaDescription}
              id={row.id}
              setText={saveText}
              maxLength={maxLength || 100}
            />
          </Box>
        );
      })}
    </Box>
  );
};

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { Box, Typography } from '@material-ui/core';
import uniqueId from 'lodash.uniqueid';

import { TextArea } from '../../Inputs';
import { useDebounce, useStores } from '../../../hooks';
import { IText } from '../../../stores/ExamStore/Model';
import { useTranslation } from 'react-i18next';

interface HeadingProps {
  htmlFor: string;
}
const Heading = styled(Typography)<HeadingProps>`
  ${({ theme }) => `
    user-select: none;
    margin:  0 ${theme.width.textArea.padding.right} 2rem ${theme.width.textArea.padding.left};
    && strong {
      display: contents;
    }
  `}
`;

interface Props {
  heading: string;
  page: string;
  question: string;
  answer: string;
  maxLength: number | null;
}

export const Text: FC<Props> = ({ heading, answer, page, question, maxLength }: Props): ReactElement => {
  const { examStore, userStore } = useStores();
  const { t } = useTranslation();
  const placeholderText: string = t('pages.exam.common.placeholder');
  const labelId = uniqueId('text-labelId');
  const callback = (answer: IText['answer']) => {
    examStore.setTextAnswer({ pageId: page, questionId: question, answer });
  };
  const debounce = useDebounce(callback, 2000);
  const saveText = (answer: IText['answer']) => {
    if (!userStore.isAdmin) {
      debounce(answer);
    }
  };

  return (
    <Box>
      <Heading htmlFor={labelId} dangerouslySetInnerHTML={{ __html: heading }} tabIndex={0} />
      <TextArea
        aria-label={placeholderText}
        disabled={userStore.isAdmin}
        label={placeholderText}
        maxLength={maxLength || 3500}
        value={answer}
        rows={20}
        id={labelId}
        setText={saveText}
      />
    </Box>
  );
};
